<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__content">
        <CustomSelect
          :options="presetsOptions"
          label="Свойство"
          :required="true"
          optionValueKey="prop_id"
          optionLabelKey="prop_name"
          :fullWidth="true"
          :value="this.formData.prop_id"
          @change="(value) => onChange('prop_id', value)"
        />
      </div>
      <div class="form__content">
        <CustomSelect
          :options="propertyDictionaryOptions"
          label="Свойство"
          optionValueKey="prop_dict_id"
          optionLabelKey="prop_value"
          :required="true"
          :fullWidth="true"
          :value="this.formData.prop_dict_id"
          @change="(value) => onChange('prop_dict_id', value)"
        />
      </div>

      <CustomModalFooter :btn-loading="this.btnLoading" />
    </q-form>
  </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import CustomInput from "@/components/form/CustomInput";
import CustomUploader from "@/components/form/CustomUploader";

import {
  getPropertyValue,
  getPropertiesByCategory,
  readPropertyDictionary,
} from "@/views/Materials/MaterialsList/MaterialEdit/services";

export default {
  name: "materials-constructor-upd-prop",
  components: { CustomModalFooter, CustomSelect, CustomInput, CustomUploader },
  props: ["data"],
  data() {
    return {
      loading: false,
      btnLoading: false,
      formData: {
        material_id: this.data?.body.material_id || "",
        material_prop_value_id: this.data?.body.material_prop_value_id || "",
        prop_id: this.data?.body.prop_id || "",
        prop_dict_id: this.data?.body.prop_dict_id || "",
      },
      presetsOptions: [],
      propertyDictionaryOptions: [],
    };
  },
  watch: {
    "formData.prop_id"(newValue) {
      if (newValue) {
        this.fetchPropertyDictionary();
      }
    },
  },
  methods: {
    async getData() {
      if (this.data?.body.material_prop_value_id) {
        const res = await getPropertyValue(
          this.data?.body.material_prop_value_id
        );
        this.formData = res[0];
      }
    },
    onChange(key, value) {
      this.formData[key] = value;
      if (key === "prop_id") {
        this.formData.prop_dict_id = "";
      }
    },
    async onSubmit() {
      this.btnLoading = true;
      await this.data?.onSubmit({
        material_id: this.formData.material_id,
        material_prop_value_id: this.formData.material_prop_value_id,
        prop_dict_id: this.formData.prop_dict_id,
      });
      this.btnLoading = false;
    },
    async fetchProperties() {
      this.presetsOptions = await getPropertiesByCategory(this.formData.material_id);
    },
    async fetchPropertyDictionary() {
      if (!this.formData.prop_id) return;
      const body = {
        material_id: this.formData.material_id,
        prop_id: this.formData.prop_id,
      };
      this.propertyDictionaryOptions = await readPropertyDictionary(body);
    },
  },
  async mounted() {
    this.getData();
    this.fetchProperties();
    this.fetchPropertyDictionary();
  },
};
</script>

<style scoped lang="scss">
.form {
  display: grid;
  min-width: 650px;
  grid-template-columns: 12fr;
  gap: 20px;

  @media (max-width: 700px) {
    min-width: 50%;
    max-width: 100%;
    grid-template-columns: 12fr;
  }

  &__content {
    padding: 0 15px;
    margin-top: 10px;

    &_item {
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}
.uploader-header {
  margin-bottom: 12px;
}
</style>
