<template>
  <span class="wrapper">
    <span class="records-bnts">
      <q-btn color="primary" @click="toggleUserRecords"> {{ showUserRecords ? 'Назад к бронированию' : 'Мои записи'
      }}</q-btn>
      <q-btn color="warning" @click="toggleRecords"> {{ showRecords ? 'Назад к бронированию' : 'Все записи' }} &nbsp;<i
          class="fa-solid fa-book"></i></q-btn>
    </span>

    <div v-if="bookingSuccess">
      <div class="success-message">
        <h3>Бронирование успешно!</h3>
        <p><strong>Дата:</strong> {{ bookingDetails.remarket_book_date }}</p>
        <p><strong>Кабинет:</strong> {{ bookingDetails.room_name }}</p>
        <p><strong>Время:</strong> {{ bookingDetails.time_gap }}</p>
        <q-btn color="positive" @click="resetBooking">Забронировать еще</q-btn>
      </div>
    </div>

    <span v-if="!bookingSuccess">
      <div v-if="!showRecords && !showUserRecords">
        <div class="title">
          <span>Забронировать кабинет</span>
        </div>
        <DateSelector placeholder="Выберите дату" :selectedDate="selectedDate" @filter_change="handleDateChange" />
        <div v-if="selectedDate" class="button-group">
          <q-btn label="Выбрать по кабинету" :color="mode === 'room' ? 'primary' : 'grey'" @click="setMode('room')"
            :loading="roomsLoading" :disable="roomsLoading || timesLoading" />
          <q-btn label="Выбрать по времени" :color="mode === 'time' ? 'primary' : 'grey'" @click="setMode('time')"
            :loading="timesLoading" :disable="roomsLoading || timesLoading" />
        </div>

        <span v-if="!!mode" class="selector-group">
          <template v-if="mode === 'room'">
            <RoomSelector :rooms="rooms" @room-selected="setRoom" :timesLoadingInner="timesLoadingInner" />
            <TimeGrid v-if="selectedRoom && !noData && !!times.length" :times="times" @time-selected="setTime"
              :selectedTime="selectedTime" />
            <p v-if="noData && !!selectedRoom" class="no-available-time">В данном кабинете нет доступного времени на
              данную дату </p>
          </template>

          <template v-if="mode === 'time'">
            <TimeGrid v-if="!!times" :times="times" @time-selected="setTime" :roomsLoadingInner="roomsLoadingInner" />
            <RoomSelector v-if="selectedTime" :rooms="rooms" @room-selected="setRoom" />
            <p v-if="noData && !!selectedTime" class="no-available-time">Нет доступного кабинета на выбранное время</p>
          </template>

          <div class="book-btn">
            <q-btn v-if="selectedRoom && selectedTime" label="Забронировать" @click="book" class="button-group"
              color="positive" size="lg" />
          </div>
        </span>
      </div>
    </span>

    <div v-if="showRecords" class="records">
      <h2>Все записи</h2>
      <div v-if="records.length">
        <div v-for="(group, date) in groupedRecords" :key="date" class="record-group">
          <div class="toggle-btn" @click="toggleDate(date)">
            <span>{{ new Date(date).toLocaleDateString("ru") }}</span>
            <i class="fa-solid fa-angle-down" :class="{ rotated: !collapsedDates[date] }"></i>
          </div>
          <table v-if="!collapsedDates[date]" class="record-table">
            <thead>
              <tr>
                <th>Кабинет</th>
                <th>Время</th>
                <th>Кто забронировал</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="record in group" :key="record.remarket_book_id">
                <td>{{ record.room_name }}</td>
                <td>{{ record.time_gap }}</td>
                <td>{{ record.fio }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p v-else>{{ noDataText }}</p>
    </div>

    <div v-if="showUserRecords" class="records">
      <h2>Мои записи</h2>
      <div v-if="userRecords.length">
        <div v-for="(group, date) in groupedUserRecords" :key="date" class="record-group">
          <div class="toggle-btn" @click="toggleUserRecordsDate(date)">
            <span>{{ new Date(date).toLocaleDateString("ru") }}</span>
            <i class="fa-solid fa-angle-down" :class="{ rotated: !collapsedUserDates[date] }"></i>
          </div>
          <table v-if="!collapsedUserDates[date]" class="record-table">
            <thead>
              <tr>
                <th>Кабинет</th>
                <th>Время</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="record in group" :key="record.remarket_book_id">
                <td>{{ record.room_name }}</td>
                <td>{{ record.time_gap }}</td>
                <td>
                  <CustomIcon icon="trash" :approve-mode="true"
                    :loading="this.deleteBtnLoading[record.remarket_book_id]"
                    :approve="() => this.onDeleteBooking(record.remarket_book_id)" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p v-else>{{ noDataText }}</p>
    </div>
  </span>
</template>

<script>
import DateSelector from "./DateSelector.vue";
import RoomSelector from "./RoomSelector.vue";
import TimeGrid from "./TimeGrid.vue";
import CustomDate from "@/components/form/CustomDate";
import CustomIcon from "@/components/common/CustomIcon";
import { getRooms, getAvailableTimes, bookRoom, getBookingRecords, getUserBookingRecords, deleteUserBooking } from "./services";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "RoomBooking",
  components: {
    DateSelector,
    RoomSelector,
    TimeGrid,
    CustomDate,
    CustomIcon,
  },
  data() {
    return {
      mode: null,
      selectedDate: new Date().toLocaleDateString("ru"),
      selectedRoom: null,
      selectedTime: null,
      rooms: [],
      times: [],
      noData: false,
      bookingSuccess: false,
      bookingDetails: {},
      showRecords: false,
      showUserRecords: false,
      records: [],
      userRecords: [],
      collapsedDates: {},
      collapsedUserDates: {},
      roomsLoading: false,
      roomsLoadingInner: null,
      timesLoading: false,
      timesLoadingInner: null,
      deleteBtnLoading: false,
      noDataText: "Загрузка...",
    };
  },
  computed: {
    groupedRecords() {
      return this.records.reduce((acc, record) => {
        if (!acc[record.remarket_book_date]) {
          acc[record.remarket_book_date] = [];
        }
        acc[record.remarket_book_date].push(record);
        return acc;
      }, {});
    },
    groupedUserRecords() {
      return this.userRecords.reduce((acc, record) => {
        if (!acc[record.remarket_book_date]) {
          acc[record.remarket_book_date] = [];
        }
        acc[record.remarket_book_date].push(record);
        return acc;
      }, {});
    },
  },
  methods: {
    async handleDateChange(type, date) {
      this.mode = null
      if (type === "date_selected") {
        this.selectedDate = date;
      }
      this.selectedDate = date;
      this.rooms = [];
      this.times = [];
      this.selectedRoom = null;
      this.selectedTime = null;
    },
    async setMode(newMode) {
      this.mode = newMode;
      this.selectedRoom = null;
      this.selectedTime = null;
      this.times = [];
      this.rooms = [];
      if (newMode === 'room') {
        this.roomsLoading = true
        const roomsResponse = await getRooms(this.selectedDate);
        if (!roomsResponse) {
          this.showErrorSnackbar()
          return
        }
        this.roomsLoading = false
        this.rooms = roomsResponse;
      } if (newMode === 'time') {
        this.timesLoading = true
        const timesResponse = await getAvailableTimes(this.selectedDate, 0);
        if (!timesResponse) {
          this.showErrorSnackbar()
          return
        }
        this.timesLoading = false
        this.times = timesResponse;
      }
    },
    async setRoom(roomId) {
      this.selectedRoom = roomId;
      if (this.mode === 'room') {
        this.selectedTime = null
        this.timesLoadingInner = roomId
        const res = await getAvailableTimes(this.selectedDate, roomId);
        if (!res) {
          this.showErrorSnackbar()
          return
        }
        this.timesLoadingInner = null
        this.times = res
      }
      this.noData = this.times.length === 0;
    },
    async setTime(time) {
      this.selectedTime = time.remarket_time_id;
      if (this.mode === 'time') {
        this.roomsLoadingInner = time.remarket_time_id
        const res = await getRooms(this.selectedDate, time.remarket_time_id);
        if (!res) {
          this.showErrorSnackbar()
          return
        }
        this.roomsLoadingInner = null
        this.rooms = res
      }
      this.noData = this.rooms.length === 0;
    },
    async book() {
      const response = await bookRoom({
        remarket_book_date: this.selectedDate,
        remarket_room_id: this.selectedRoom,
        remarket_time_id: this.selectedTime,
      });
      if (response) {
        this.bookingSuccess = true;
        this.bookingDetails = {
          remarket_book_date: this.selectedDate,
          room_name: this.rooms.find(r => r.remarket_room_id === this.selectedRoom)?.room_name,
          time_gap: this.times.find(t => t.remarket_time_id === this.selectedTime)?.time_gap,
        };
      }
    },
    resetBooking() {
      this.bookingSuccess = false;
      this.selectedDate = new Date().toLocaleDateString("ru");
      this.selectedRoom = null;
      this.selectedTime = null;
      this.rooms = [];
      this.times = [];
      this.mode = null;
    },
    async toggleRecords() {
      this.showRecords = !this.showRecords;
      this.showUserRecords = false;
      if (this.showRecords) {
        this.records = await getBookingRecords();
        if (this.records.length === 0) {
          this.noDataText = "Нет данных";
        } else {
          this.noDataText = "Загрузка ...";
        }
      }
    },
    toggleDate(date) {
      this.collapsedDates = { ...this.collapsedDates, [date]: !this.collapsedDates[date] };
    },
    async toggleUserRecords() {
      this.showUserRecords = !this.showUserRecords;
      this.showRecords = false;
      if (this.showUserRecords) {
        this.userRecords = await getUserBookingRecords();
        if (this.userRecords.length === 0) {
          this.noDataText = "Нет данных";
        } else {
          this.noDataText = "Загрузка ...";
        }
      }
    },
    toggleUserRecordsDate(date) {
      this.collapsedUserDates = { ...this.collapsedUserDates, [date]: !this.collapsedUserDates[date] };
    },
    async onDeleteBooking(remarket_book_id) {
      this.deleteBtnLoading = { ...this.deleteBtnLoading, [remarket_book_id]: true };
      const res = await deleteUserBooking(remarket_book_id);
      this.deleteBtnLoading = { ...this.deleteBtnLoading, [remarket_book_id]: false };
      if (!res) return;
      this.showSucceedSnackbar();
      this.userRecords = this.userRecords.filter(record => record.remarket_book_id !== remarket_book_id);
      if (this.userRecords.length === 0) {
        this.noDataText = "Нет данных";
      } else {
        this.noDataText = "Загрузка ...";
      }
    },
  },
  setup() {
    const { showSucceedSnackbar, showErrorSnackbar } = useCustomSnackbar()
    return { showSucceedSnackbar, showErrorSnackbar }
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  margin: 0 auto;
}

.title {
  text-align: center;
  color: #272C39;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 70px 0 20px 0;
}

.button-group {
  display: flex;
  gap: 10px;
  margin: 20px 0;
}

.success-message {
  text-align: center;
  padding: 20px;
  color: #3c763d;
  border-radius: 5px;
  max-width: 400px;
  margin: 20px auto;
}

.book-btn {
  display: flex;
  justify-content: center;
}

.selector-group {
  display: block;
  width: 100%;
  max-width: 500px;
}

.records-bnts {
  display: flex;
  position: absolute;
  gap: 10px;
  top: 20px;
  right: 20px;
}

.records {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.record-group {
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.toggle-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
  font-weight: bold;
}

.toggle-btn i.rotated {
  transform: rotate(180deg);
}

.record-table {
  width: 100%;
  border-collapse: collapse;
}

.record-table th,
.record-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.record-table th {
  background-color: #f0f0f0;
}

.no-available-time {
  padding: 10px;
  color: red;
  font-weight: bold;
}
</style>
