import { materialProviderAPI } from "@/views/MaterialProviderList/api";

export const getMatrialProvList = async (filters) => {
  try {
    const res = await materialProviderAPI.getList(filters);
    return res?.data;
  } catch (e) {}
};
export const getPriceHistoryByMaterialProviderId = async (
  material_provider_id
) => {
  try {
    const res = await materialProviderAPI.getPriceHistory(material_provider_id);
    return res?.data;
  } catch (e) {}
};
export const getAgreementsList = async (
  provider_id
) => {
  try {
    const res = await materialProviderAPI.getAgreementsList(provider_id);
    return res?.data;
  } catch (e) {}
};

export const changeRequirementFlag = async (payload) => {
  try {
    const res = await materialProviderAPI.changeRequirementFlag(payload);
    return res?.data;
  } catch (e) {}
};
export const changeMainPriceFlag = async (payload) => {
  try {
    const res = await materialProviderAPI.changeMainPriceFlag(payload);
    return res?.data;
  } catch (e) {}
};
export const changeAvailability = async (payload) => {
  try {
    const res = await materialProviderAPI.changeAvailability(payload);
    return res?.data;
  } catch (e) {}
};
export const changeAdditionalStockRequestFlag = async (payload) => {
  try {
    const res = await materialProviderAPI.changeAdditionalStockRequestFlag(payload);
    return res?.data;
  } catch (e) {}
};
export const attachAgreement = async (payload) => {
  try {
    const res = await materialProviderAPI.attachAgreement(payload);
    return res?.data;
  } catch (e) {}
};
export const onAddNewPrices = async (payload) => {
  try {
    const res = await materialProviderAPI.onAddNewPrices(payload);
    return res?.data;
  } catch (e) {}
};
export const updateMaterialAddChange = async (payload) => {
  try {
    const res = await materialProviderAPI.updateMaterialAddChange(payload);
    return res?.data;
  } catch (e) {}
};
export const updateMaterialNameOfProvider = async (payload) => {
  try {
    const res = await materialProviderAPI.updateMaterialNameOfProvider(payload);
    return res?.data;
  } catch (e) {}
};
export const updateMaterialNettoPrice = async (payload) => {
  try {
    const res = await materialProviderAPI.updateMaterialNettoPrice(payload);
    return res?.data;
  } catch (e) {}
};
export const onDelete = async (material_provider_id) => {
  try {
    const res = await materialProviderAPI.onDeleteMaterialProvider(material_provider_id);
    return res?.data;
  } catch (e) {}
};

export const MaterialProviderColumns = [
  {
    name: "material_provider_id",
    label: "ID",
    field: "material_provider_id",
    sortable: true,
    headerStyle: "width: 80px",
    style: "width: 100px",
    align: "center",
  },
  {
    name: "material_id",
    label: "ID материала",
    field: "material_id",
    sortable: true,
    headerStyle: "width: 80px",
    style: "width: 100px",
    align: "center",
  },
  {
    name: "material_type_name",
    label: "Тип материала",
    field: "material_type_name",
    sortable: true,
    align: "center",
  },
  {
    name: "material_name",
    label: "Название материала",
    field: "material_name",
    sortable: true,
    align: "center",
  },
  {
    name: "price",
    label: "Цена (розничная)",
    field: "price",
    sortable: true,
    align: "center",
  },
  {
    name: "price_whole",
    label: "Цена (оптовая)",
    field: "price_whole",
    sortable: true,
    align: "center",
  },
  {
    name: "date_price",
    label: "Период действия",
    field: "date_price",
    sortable: true,
    align: "center",
  },
  {
    name: "temp_netto_price",
    label: "Цена для НеНДСника",
    field: "temp_netto_price",
    sortable: true,
    align: "center",
  },
  // Цена SR (Оптовая)
  // price_whole_sr
  {
    name: "provider_material_name",
    label: "Название материала у поставщика",
    field: "provider_material_name",
    align: "center",
  },
  {
    name: "provider_name",
    label: "Название поставщика",
    field: "provider_name",
    sortable: true,
    align: "center",
  },
  {
    name: "fulfillment_name",
    label: "Кому",
    field: "fulfillment_name",
    align: "center",
  },
  {
    name: "vendor_code",
    label: "Артикул",
    field: "vendor_code",
    sortable: true,
    align: "center",
  },
  {
    name: "is_avail_text",
    label: "Наличие",
    field: "is_avail_text",
    sortable: true,
    align: "center",
  },
  {
    name: "warehouse_name",
    label: "С собственного склада",
    field: "warehouse_name",
    align: "center",
  },
  {
    name: "is_main",
    label: "Основная цена",
    field: "is_main",
    sortable: true,
    align: "center",
  },
  {
    name: "is_top_10",
    label: "Топ 10",
    field: "is_top_10",
    sortable: true,
    align: "center",
  },
  {
    name: "is_owned_material",
    label: "Наш материал",
    field: "is_owned_material",
    sortable: true,
    align: "center",
  },
  {
    name: "photo_url_orig",
    label: "Фото",
    field: "photo_url_orig",
    align: "center",
  },
  {
    name: "dltBtn",
    label: "",
    field: "dltBtn",
    align: "center",
  },
];

export const MaterialProviderPriceHistoryColumns = [
  {
    name: "material_provider_price_id",
    label: "ID",
    field: "material_provider_price_id",
    align: "center",
  },
  {
    name: "price",
    label: "Цена (розничная)",
    field: "price",
    align: "center",
  },
  {
    name: "price_whole",
    label: "Цена (оптовая)",
    field: "price_whole",
    align: "center",
  },
  {
    name: "invoice_price",
    label: "Цена (накладная)",
    field: "invoice_price",
    align: "center",
  },
  {
    name: "buh_price",
    label: "Цена (1C)",
    field: "buh_price",
    align: "center",
  },
  {
    name: "increase",
    label: "Надбавка (конструктор)",
    field: "increase",
    align: "center",
  },
  {
    name: "date_price",
    label: "Период действия",
    field: "date_price",
    align: "center",
  },
  {
    name: "price_comment",
    label: "Комментарий",
    field: "price_comment",
    align: "center",
  },
  {
    name: "fio",
    label: "Работник",
    field: "fio",
    align: "center",
  },
  {
    name: "currency_price",
    label: "Цена в валюте (розничная)",
    field: "currency_price",
    align: "center",
  },
  {
    name: "currency_price_whole",
    label: "Цена в валюте (оптовая)",
    field: "currency_price_whole",
    align: "center",
  },
  {
    name: "currency_invoice_price",
    label: "Цена в валюте (накладная)",
    field: "currency_invoice_price",
    align: "center",
  },
];

export const groupActions = [
  { label: "Проставить признак создание потребности", value: 0 },
  { label: "Убрать признак создание потребности", value: 1, textColor: "red" },
  { label: "Проставить признак основной цены", value: 2 },
  { label: "Убрать признак основной цены", value: 3, textColor: "red" },
  { label: "Сделать в наличии", value: 4 },
  { label: "Убрать из наличия", value: 5, textColor: "red" },
  { label: "Добавить новые цены", value: 6, textColor: "green" },
  { label: "Проставить признак доп. заявки со склада", value: 7 },
  { label: "Убрать признак доп. заявки со склада", value: 8, textColor: "red" },
  { label: "Прикрепить договор", value: 9, textColor: "green" },
];

export const availabilityOptions = [
  { label: "Есть в наличии", value: 1 },
  { label: "Нет в наличии", value: 0 },
];