import instance from "@/services/api";

export const priceChangesAPI = {
  async getList(params) {
    return await instance()
      .get("/handbook/material_provider/buffer_read/", {params})
      .then((res) => res?.data);
  },
  async priceChangeApproval(body) {
    return await instance()
      .post("/handbook/material_provider/buffer_accept/", body)
      .then((res) => res?.data);
  },
  async onDeletePriceChange(mp_buffer_price_id) {
    return await instance()
      .delete(`/handbook/material_provider/${mp_buffer_price_id}/buffer_del/`)
      .then((res) => res?.data);
  },
}