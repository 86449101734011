import { providerRequestsFiltersAPI } from "@/views/ProviderRequests/ProviderRequestsFilters/api";
import { handbookContractorsAPI } from "@/views/handbook/contractors/api";


export const getFiltersMetaData = async () => {
  return await Promise.all([
    providerRequestsFiltersAPI.getMaterialTypes(),
    providerRequestsFiltersAPI.getProviders(),
    handbookContractorsAPI.getCity(), 
    providerRequestsFiltersAPI.getMaterials(),
  ]).catch(() => {
    console.error('filtersMetadata error');    
    return null;
  });
};

export const availabilityOptions = [
  { label: "Все", value: -1 },
  { label: "Есть в наличии", value: 1 },
  { label: "Нет в наличии", value: 0 },
];
export const materialActivity = [
  { label: "Все", value: 2 },
  { label: "Активный", value: 1 },
  { label: "Неактивный", value: 0 },
];
export const shippedFromWarehouse = [
  { label: "Все", value: -1 },
  { label: "Да", value: 1 },
  { label: "Нет", value: 0 },
];