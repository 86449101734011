import { materialsAPI } from "@/views/Materials/api";

export const getImages = async (material_id) => {
  try {
    const res = await materialsAPI.getImages(material_id);
    return res?.data;
  } catch (e) {}
};
export const getKitchenSpecs = async (material_id) => {
  try {
    const res = await materialsAPI.getKitchenSpecs(material_id);
    return res?.data;
  } catch (e) {}
};
export const uploadKitchenPhoto = async (body) => {
  try {
    const res = await materialsAPI.uploadKitchenSpecPhoto(body);
    return res?.data;
  } catch (e) {}
};
export const getKitchenDesc = async (material_kitchen_desc_upd) => {
  try {
    const res = await materialsAPI.getKitchenDesc(material_kitchen_desc_upd);
    return res?.data;
  } catch (e) {}
};
export const updKitchenDesc = async (body) => {
  try {
    const res = await materialsAPI.updKitchenDesc(body);
    return res?.data;
  } catch (e) {}
};
export const onDeleteKitchenDesc = async (material_kitchen_desc_id) => {
  try {
    const res = await materialsAPI.onDeleteKitchenDesc(material_kitchen_desc_id);
    return res?.data;
  } catch (e) {}
};
export const getAdditionalImgs = async (material_id) => {
  try {
    const res = await materialsAPI.getAdditionalImgs(material_id);
    return res?.data;
  } catch (e) {}
};
export const getAdditionalPhoto = async (material_photo_id) => {
  try {
    const res = await materialsAPI.getAdditionalPhoto(material_photo_id);
    return res?.data;
  } catch (e) {}
};
export const updAddImage = async (body) => {
  try {
    const res = await materialsAPI.updAddImage(body);
    return res?.data;
  } catch (e) {}
};
export const onDeleteAdditionalPhoto = async (material_photo_id) => {
  try {
    const res = await materialsAPI.onDeleteAdditionalPhoto(material_photo_id);
    return res?.data;
  } catch (e) {}
};
export const getPresetsOptions = async () => {
  try {
    const res = await materialsAPI.getPresetsOptions();
    return res?.data;
  } catch (e) {}
};
export const getRoomPictures = async (room_picture_id) => {
  try {
    const res = await materialsAPI.getRoomPictures(room_picture_id);
    return res?.data;
  } catch (e) {}
};
export const getRoomsOptions = async () => {
  try {
    const res = await materialsAPI.getRoomsOptions();
    return res?.data;
  } catch (e) {}
};
export const getRakursOptions = async (body) => {
  try {
    const res = await materialsAPI.getRakursOptions(body);
    return res?.data;
  } catch (e) {}
};
export const getMaterialSets = async (material_id) => {
  try {
    const res = await materialsAPI.getMaterialSets(material_id);
    return res?.data;
  } catch (e) {}
};
export const getWorkSetsForPic = async (room_picture_id) => {
  try {
    const res = await materialsAPI.getWorkSetsForPic(room_picture_id);
    return res?.data;
  } catch (e) {}
};
export const getParentMaterial = async (body) => {
  try {
    const res = await materialsAPI.getParentMaterial(body);
    return res?.data;  
  } catch (e) {}
};
export const getParentMaterialSets = async (body) => {
  try {
    const res = await materialsAPI.getParentMaterialSets(body);
    return res?.data;  
  } catch (e) {}
};
export const updRoomPicture = async (body) => {
  try {
    const res = await materialsAPI.updRoomPicture(body);
    return res?.data;  
  } catch (e) {}
};
export const udpateIndex = async (body) => {
  try {
    const res = await materialsAPI.udpateIndex(body);
    return res?.data;  
  } catch (e) {}
};
export const onDeleteRoomPicture = async (room_picture_id) => {
  try {
    const res = await materialsAPI.onDeleteRoomPicture(room_picture_id);
    return res?.data;  
  } catch (e) {}
};

// Material Properties Services
export const getMaterialProperties = async (material_id) => {
  try {
    const res = await materialsAPI.getMaterialProperties(material_id);
    return res?.data;
  } catch (e) {}
};

export const getMaterialCategories = async () => {
  try {
    const res = await materialsAPI.getMaterialCategories();
    return res?.data;
  } catch (e) {}
};

export const updateMaterialCategory = async (body) => {
  try {
    const res = await materialsAPI.updateMaterialCategory(body);
    return res?.data;
  } catch (e) {}
};

export const getPropertyValue = async (material_prop_value_id) => {
  try {
    const res = await materialsAPI.getPropertyValue(material_prop_value_id);
    return res?.data;
  } catch (e) {}
};

export const getPropertiesByCategory = async (material_id) => {
  try {
    const res = await materialsAPI.getPropertiesByCategory(material_id);
    return res?.data;
  } catch (e) {}
};

export const readPropertyDictionary = async (body) => {
  try {
    const res = await materialsAPI.readPropertyDictionary(body);
    return res?.data;
  } catch (e) {}
};

export const updatePropertyValue = async (body) => {
  try {
    const res = await materialsAPI.updatePropertyValue(body);
    return res?.data;
  } catch (e) {}
};

export const deletePropertyValue = async (material_prop_value_id) => {
  try {
    const res = await materialsAPI.deletePropertyValue(material_prop_value_id);
    return res?.data;
  } catch (e) {}
};

export const constructorImagesColumns = [
  {
    name: "room_name",
    field: "room_name",
    label: "Комната",
    align: "center",
    sortable: true,
  },
  {
    name: "set_name",
    field: "set_name",
    label: "Набор",
    align: "center",
    sortable: true,
  },
  {
    name: "image_url",
    field: "image_url",
    label: "Фотография",
    alignTable: "center",
    align: "center",
  },
  {
    name: "preset_name",
    field: "preset_name",
    label: "Пресет",
    align: "center",
    sortable: true,
  },
  {
    name: "city_name",
    field: "city_name",
    label: "Город",
    align: "center",
    sortable: true,
  },
  {
    name: "rakurs_name",
    field: "rakurs_name",
    label: "Ракурс",
    align: "center",
    sortable: true,
  },
  {
    name: "order_index",
    field: "order_index",
    label: "Индекс",
    alignTable: "right",
    align: "center",
    sortable: true,
  },
  {
    name: "work_set_name",
    field: "work_set_name",
    label: "Ворксет",
    alignTable: "center",
    align: "center",
    sortable: true,
  },
  {
    name: "material_name",
    field: "material_name",
    label: "Материал",
    alignTable: "center",
    align: "center",
    sortable: true,
  },
  {
    name: "material_set_name",
    field: "material_set_name",
    label: "Набор",
    alignTable: "center",
    align: "center",
    sortable: true,
  },
  {
    name: "dlt",
    field: "dlt",
    label: "",
    alignTable: "center",
    align: "center",
  },
];

export const constructorKitchenColumns = [
  {
    name: "material_kitchen_desc_id",
    field: "material_kitchen_desc_id",
    label: "ID",
    alignTable: "center",
    align: "center",
    sortable: true,
  },
  {
    name: "name",
    field: "name",
    label: "Наименование",
    align: "center",
    sortable: true,
  },
  {
    name: "description",
    field: "description",
    label: "Описание",
    align: "center",
    sortable: true,
  },
  {
    name: "edit",
    field: "edit",
    label: "",
    alignTable: "center",
    align: "center",
    headerStyle: "width: 50px",
    style: "width: 50px",
  },
  {
    name: "dlt",
    field: "dlt",
    label: "",
    alignTable: "center",
    align: "center",
    headerStyle: "width: 50px",
    style: "width: 50px",
  },
];
export const addImgsColumns = [
  {
    name: "material_photo_id",
    field: "material_photo_id",
    label: "ID",
    align: "center",
    sortable: true,
  },
  {
    name: "photo_url",
    field: "photo_url",
    label: "Картинка",
    align: "center",
  },
  {
    name: "order_num",
    field: "order_num",
    label: "Порядок",
    alignTable: "right",
    align: "center",
    headerStyle: "width: 50px",
    style: "width: 50px",
  },
  {
    name: "rowversion",
    field: "rowversion",
    label: "Дата записи",
    align: "center",
  },
  {
    name: "edit",
    field: "edit",
    label: "",
    align: "center",
    headerStyle: "width: 50px",
    style: "width: 50px",
  },
  {
    name: "dlt",
    field: "dlt",
    label: "",
    align: "center",
    headerStyle: "width: 50px",
    style: "width: 50px",
  },
];
