import { roomBookingAPI } from "@/views/RoomBooking/api";

export const getRooms = async (date, timeId = 0) => {
  try {
    const res = await roomBookingAPI.getRooms(date, timeId);
    return res?.data;
  } catch (e) {
    return [];
  }
};
export const getAvailableTimes = async (date, roomId) => {
  try {
    const res = await roomBookingAPI.getAvailableTimes(date, roomId);
    return res?.data;
  } catch (e) {
    return [];
  }
};
export const bookRoom = async (body) => {
  try {
    const res = await roomBookingAPI.bookRoom(body);
    return res?.data;
  } catch (e) {
    return null;
  }
};
export const getBookingRecords = async () => {
  try {
    const res = await roomBookingAPI.getBookingRecords();
    return res?.data;
  } catch (e) {
    return null;
  }
};
export const getUserBookingRecords = async () => {
  try {
    const res = await roomBookingAPI.getUserBookingRecords();
    return res?.data;
  } catch (e) {
    return null;
  }
};
export const deleteUserBooking = async (remarket_book_id) => {
  try {
    const res = await roomBookingAPI.deleteUserBooking(remarket_book_id);
    return res?.data;
  } catch (e) {
    return null;
  }
};
