<template>
  <q-form @submit="this.onSubmit" class="form">
    <h4 class="title">
      {{
        this.initialData?.material_id
          ? "Редактирование материала поставщика"
          : "Добавление материала поставщика"
      }}
    </h4>
    <span class="hr"></span>
    <div class="form__item">
      <CustomSelect
        :options="this.materialsOptions"
        label="Материал"
        optionValueKey="material_id"
        optionLabelKey="material_name"
        :value="formData.material_id"
        @change="(value) => onChange('material_id', value)"
        :required="true"
      />
    </div>

    <div class="form__item">
      <CustomSelect
        :options="this.providersOptions"
        label="Поставщик"
        optionValueKey="provider_id"
        optionLabelKey="provider_name"
        :value="formData.provider_id"
        @change="(value) => onChange('provider_id', value)"
        :required="true"
      />
    </div>

    <!-- TODO <?if($this->fulfillment_code == 'FULFILLMENT'){?> или если есть row_fulfillment при редактировании -->
    <!-- <div class="form__item">
      <CustomSelect
        :options="fulfillmentOptions"
        label="Fulfillment"
        optionValueKey="fulfillment_id"
        optionLabelKey="fulfillment_name"
        :value="formData.fulfillment_id"
        @change="(value) => onChange('fulfillment_id', value)"
      />
    </div> -->
    <!--  -->

    <div class="form__item">
      <CustomInput
        label="Артикул"
        :value="formData.vendor_code"
        @change_input="(value) => onChange('vendor_code', value)"
      />
    </div>

    <div class="form__item">
      <CustomSelect
        label="Наличие материала"
        :value="formData.is_avail"
        :options="availabilityOptions"
        optionValueKey="value"
        optionLabelKey="label"
        @change="(value) => onChange('is_avail', value)"
      />
    </div>

    <div class="form__item">
      <CustomCheckbox
        label="Создавать потребность"
        :binary="true"
        :value="formData.is_required_create"
        @change="(value) => onChange('is_required_create', value)"
      />
    </div>

    <span v-if="formData.material_id === ''">
      <div class="form__item">
        <CustomInput
          label="Цена (розничная)"
          :value="formData.price"
          type="number"
          @change_input="(value) => onChange('price', value)"
          :required="true"
        />
      </div>
      <br />
      <div class="form__item">
        <CustomInput
          label="Цена (оптовая)"
          :value="formData.price_whole"
          type="number"
          @change_input="(value) => onChange('price_whole', value)"
          :required="true"
        />
      </div>
      <br />
      <div class="form__item">
        <div>Дата с:</div>
        <CustomDate
          label="Дата с"
          :value="formData.date_begin"
          @change_date="(value) => onChange('date_begin', value)"
        />
      </div>
      <br />
      <div class="form__item">
        <div>Дата по:</div>
        <CustomDate
          label="Дата по"
          :value="formData.date_end"
          @change_date="(value) => onChange('date_end', value)"
        />
      </div>

      <!-- <?if($this->fulfillment_code == 'FULFILLMENT'){?>
                <div class="form-group hidden">
                    <div class="checkbox">
                        <label class="block fit-content-width">
                            <input name="is_insert_sr" type="hidden" value="0">
                            <input name="is_insert_sr" type="checkbox" class="ace input-lg">
                            <span class="lbl bigger-120 user-select-none"> Обновить цену для SR</span>
                        </label>
                    </div>
                </div>
                <? -->
    </span>
    <div class="form__item">
      <CustomInput
        label="Наименование материала у поставщика"
        :value="formData.provider_material_name"
        @change_input="(value) => onChange('provider_material_name', value)"
      />
    </div>

    <div class="form__item">
      <CustomInput
        label="Количество дней на выполнение услуги"
        :value="formData.overdue_day_cnt"
        type="number"
        @change_input="(value) => onChange('overdue_day_cnt', value)"
      />
    </div>

    <div class="form__item">
      <CustomCheckbox
        label="Требуется вызов на второй этап"
        :binary="true"
        :value="formData.is_invite_second"
        @change="(value) => onChange('is_invite_second', value)"
      />
    </div>

    <div class="footer">
      <q-btn
        class="footer__btn footer__btn_cancel"
        label="Назад"
        color="red"
        icon="close"
        @click="onClose"
        :disable="submitLoading"
        size="sm"
      >
      </q-btn>
      <!-- <q-btn
        class="footer__btn footer__btn_submit"
        label="Сохранить"
        color="secondary"
        icon="check"
        type="submit"
        :disable="submitLoading"
        :loading="submitLoading"
        @click="onSubmit"
      > 
      </q-btn> -->
      <CustomModalFooter
        :btn-loading="this.loading"
        :hideCancelBtn="true"
        class="mt-0"
      />
    </div>
  </q-form>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import CustomDate from "@/components/form/CustomDate";
import CustomInput from "@/components/form/CustomInput";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import {
  availabilityOptions,
  updateMaterialAddChange,
} from "@/views/MaterialProviderList/services";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "MaterialAddChange",
  components: {
    CustomSelect,
    CustomDate,
    CustomInput,
    CustomCheckbox,
    CustomModalFooter,
  },
  props: {
    providersOptions: { type: Array, default: () => [] },
    materialsOptions: { type: Array, default: () => [] },
    availabilityOptions: { type: Array, default: () => availabilityOptions },
    initialData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      submitLoading: false,
      formData: {
        material_id: this.initialData?.material_id || "",
        provider_id: this.initialData?.provider_id || "",
        fulfillment_id: this.initialData?.fulfillment_id || "",
        vendor_code: this.initialData?.vendor_code || "",
        is_avail: 1,
        is_required_create: this.initialData?.is_required_create || 0,
        price: this.initialData?.price || "",
        price_whole: this.initialData?.price_whole || "",
        date_begin: this.initialData?.date_begin || "",
        date_end: this.initialData?.date_end || "",
        provider_material_name: this.initialData?.provider_material_name || "",
        overdue_day_cnt: this.initialData?.overdue_day_cnt || "",
        is_invite_second: this.initialData?.is_invite_second || 0,
      },
      fulfillmentOptions: [],
      availabilityOptions,
    };
  },
  methods: {
    onClose() {
      this.submitLoading = false;
      this.$emit("close");
    },
    async onSubmit() {
      this.submitLoading = true;
      const res = await updateMaterialAddChange(this.formData);
      this.submitLoading = false;
      if (!res) return;
      this.$emit("get_data");
      this.$emit("close");
      this.showSucceedSnackbar();
    },
    onChange(field, value) {
      this.formData[field] = value;
    },
    initializeIsAvail() {
      this.formData.is_avail = this.initialData?.is_avail;
    },
  },
  mounted() {
    if (!!this.initialData?.material_id) {
      this.initializeIsAvail();
    }
  },
  setup() {
    const { showSucceedSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar };
  },
};
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 1020px;
  gap: 15px;
  margin-bottom: 35px;

  &__item {
    max-width: 320px;
  }

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
.title {
  padding: 0;
  margin: 0;
}
.hr {
  display: block;
  height: 0;
  overflow: hidden;
  font-size: 0;
  border-width: 1px 0 0;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.11);
}
.footer {
  display: flex;
  justify-content: flex-start;
  gap: 10px;

  &__btn {
    margin-top: 0 !important;
    &.footer__btn_cancel {
      background-color: #f5f5f5;
      color: #e74c3c;
    }

    &.footer__btn_submit {
      background-color: #3498db;
      color: #fff;
    }

    &.footer__btn_disabled {
      opacity: 0.5;
    }
  }
}

.mt-0 {
  margin-top: 0 !important;
}
</style>
