<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__content">
        <CustomInput
          :value="this.formData.name"
          label="Наименование"
          @change_input="(value) => onChange('name', value)"
          :fullWidth="true"
        />
      </div>  
      <div class="form__content">
        <CustomInput
          :value="this.formData.description"
          type="textarea"
          label="Описание"
          @change_input="(value) => onChange('description', value)"
          :fullWidth="true"
        />
      </div>
      <CustomModalFooter :btn-loading="this.btnLoading" />
    </q-form>
  </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomInput from "@/components/form/CustomInput";

import { getKitchenDesc } from "@/views/Materials/MaterialsList/MaterialEdit/services";

export default {
  name: "materials-constructor-upd-kitchen-desc",
  components: { CustomModalFooter, CustomInput },
  props: ["data"],
  data() {
    return {
      btnLoading: false,
      formData: {
        material_kitchen_desc_id: this.data?.body.material_kitchen_desc_id || "",
        name: this.data?.body.name || "",
        description: this.data?.body.description || "",
        material_id: this.data?.body.material_id || "",
      },
    };
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value;
    },
    async getData() {
      this.btnLoading = true;
      const res = await getKitchenDesc(this.formData.material_kitchen_desc_id);
      this.btnLoading = false;
      if (!res) return;
      this.formData = res;
    },
    async onSubmit() {
      this.btnLoading = true;
      await this.data?.onSubmit(this.formData);
      this.btnLoading = false;
    },    
  },
  mounted() {
    if (this.data?.body.material_kitchen_desc_id) {
      this.getData();
    }
  },
};
</script>

<style scoped lang="scss">
.form {
  display: grid;
  min-width: 650px;
  grid-template-columns: 12fr;
  gap: 20px;

  @media (max-width: 700px) {
    min-width: 50%;
    max-width: 100%;
    grid-template-columns: 12fr;
  }

  &__content {
    padding: 0 15px;
    margin-top: 10px;

    &_item {
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}
</style>
