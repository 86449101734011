<template>
  <form @submit.prevent="onSearch" style="margin-bottom: 20px">
    <div class="filters">
      <div class="filters__input">
        <CustomInput
          label="Материал"
          :value="values.material_id"
          @change="(val) => onChange('material_id', val)"
          @clear_input="onChange('material_id', '')"
        />
      </div>

      <div class="filters__select">
        <CustomSelect
          :options="materialTypes"
          label="Тип материала"
          optionValueKey="material_type_id"
          optionLabelKey="material_type_name"
          :value="this.values.material_type_id"
          @change="(value) => onChange('material_type_id', value)"
        />
      </div>

      <div class="filters__select">
        <CustomSelect
          :options="providersOptions"
          label="Поставщик"
          optionValueKey="provider_id"
          optionLabelKey="provider_name"
          :value="values.provider_id"
          @change="(val) => onChange('provider_id', val)"
        />
      </div>

      <q-btn
        type="submit"
        :loading="isFetching"
        size="md"
        no-wrap
        color="primary"
        class="q-btn"
        label="Поиск"
        icon="search"
      />
    </div>
  </form>
</template>

<script>
import CustomInput from "@/components/form/CustomInput";
import CustomSelect from "@/components/form/CustomSelect";

export default {
  name: "MaterialsFilters",
  components: { CustomInput, CustomSelect },
  props: {
    values: { type: Object, default: () => ({}) },
    isFetching: { type: Boolean, default: false },
    getData: { type: Function, default: null },
    providersOptions: { type: Array, default: () => [] },
    materialTypes: { type: Array, default: () => [] },
  },
  methods: {
    onChange(type, value) {
      this.$emit("filter_change", type, value);
    },
    onSearch() {
      if (
        this.getData &&
        Object.values(this.values).some((val) => val !== "")
      ) {
        this.getData();
      } else {
        this.$q.notify({
          message: "Заполните хотя бы одно поле в фильтрах поиска",
          color: "negative",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  gap: 15px;

  &__input {
    min-width: 170px;
  }
  &__select {
    min-width: 200px;
  }
}

.q-btn {
  margin-top: 10px;
  align-self: flex-start;
}
</style>
