<template>
  <i
    :class="[
      `material-icons icon icon__${computedValue ? 'check' : 'close'}`,
      { icon__noPadding: noPadding, icon__small: small },
    ]"
  >
    {{ computedValue ? "check" : onlyTrue ? "" : "close" }}
  </i>
</template>

<script>
export default {
  name: "CheckOrClose",
  props: {
    value: {
      type: [Boolean, Number],
      default: false,
    },
    onlyTrue: Boolean,
    noPadding: Boolean,
    small: Boolean,
  },
  computed: {
    computedValue() {
      return this.value === true || this.value === 1;
    },
  },
};
</script>

<style scoped lang="scss">
.icon {
  font-size: 20px;
  padding: 5px;
  transition: all 0.3s;

  &__close {
    color: #9d1010;
  }

  &__small {
    font-size: 16px;
  }

  &__check {
    color: #55c558;
  }
  &__noPadding {
    padding: 0;
  }

  &__edit {
    border-radius: 50%;
    color: #cc6d33;

    &:hover {
      background: #ebebeb;
    }
  }
}
</style>
