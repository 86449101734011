<template>
  <span v-if="isMaterialAddChange">
    <MaterialAddChange
      @close="this.closeMaterialAddChange"
      @get_data="this.getData"
      :providersOptions="this.providersOptions"
      :materialsOptions="this.materialsOptions"
      :initialData="selectedMaterial"
    />
  </span>

  <span v-else>
    <div class="button-container">
      <q-btn
        @click="this.onAddMaterial"
        size="sm"
        no-wrap
        color="primary"
        class="addMaterialBtn"
        label="Добавить материал поставщика"
        icon="add"
      />
    </div>
    <FiltersWrapper>
      <div ref="header">
        <MaterialProviderListFilters
          :values="this.currentFilters"
          :getData="this.getData"
          @filter_change="this.onFiltersChange"
          :materialTypes="this.materialTypes"
          :providersOptions="this.providersOptions"
          :materialsOptions="this.materialsOptions"
          :citiesOptions="this.citiesOptions"
          :isFetching="this.isFetchingFilters"
          :noOptionText="noOptionText"
        />
      </div>
    </FiltersWrapper>

    <div>
      <CustomTable
        :title="`Список материалов поставщиков для SmartRemont${
          !!getSelectedLength() ? ` (Выбрано: ${getSelectedLength()})` : ''
        }`"
        :rowsData="this.data"
        :columns="columns"
        :stickyHeader="false"
        :loading="loading"
        :custom-body="true"
        :small="true"
        checkbox="true"
        :checkbox-value="this.allSelected"
        :on-all-check-click="onAllCheckClick"
      >
        <template #top-right>
          <CustomSelect
            :options="this.groupActions"
            :label="computedLabel"
            optionValueKey="value"
            optionLabelKey="label"
            value="Действия"
            :clearable="false"
            :fullWidth="true"
            @change="this.onGroupActionChange"
            style="width: 220px"
            textColor="textColor"
          />
        </template>

        <template #customBody="props">
          <q-tr>
            <custom-table-cell v-if="!this.client_request_id">
              <div class="check__wrapper">
                <CustomCheckbox
                  :value="!!this.selectedItems[props.row.material_provider_id]"
                  @change="
                    (value) =>
                      onCheckboxChange(props.row.material_provider_id, value)
                  "
                />
              </div>
            </custom-table-cell>
            <custom-table-cell align="center">
              {{ props.row.material_provider_id }}
            </custom-table-cell>
            <custom-table-cell align="right">
              {{ props.row.material_id }}
            </custom-table-cell>
            <custom-table-cell align="left">
              <span v-html="`${props.row.material_type_name}`"></span>
            </custom-table-cell>
            <custom-table-cell align="left">
              <span
                v-html="`${props.row.material_name}`"
                class="clickable-text"
                @click="onAddMaterial(props.row)"
                title="Редактирование материала поставщика"
              ></span>
            </custom-table-cell>
            <custom-table-cell align="right">
              <!-- TODO showHist таблица истории цены Доделать, внутри модалка еще -->
              <span
                @click="onShowPricePopover($event, props.row)"
                class="cursor-pointer text-blue"
              >
                {{ props.row.price }}
                за {{ props.row.sell_unit_name }}
              </span>
              <br />
              <a @click="showNewPriceAddModal(props.row)">
                <i
                  class="fa fa-edit hover"
                  title="Добавить новую цену на материал"
                ></i>
              </a>

              <q-menu
                v-if="popoverOpened"
                :anchor-element="anchorEl"
                anchor="center middle"
                self="center middle"
              >
                <div
                  style="
                    min-height: 146px;
                    max-width: 1080px;
                    max-height: 300px;
                  "
                >
                  <CustomTable
                    :rowsData="this.priceHistoryData"
                    :columns="priceHistColumns"
                    :custom-body="true"
                    :small="true"
                  >
                    <template #customBody="priceProps">
                      <q-tr>
                        <custom-table-cell align="center">
                          {{ priceProps.row.material_provider_price_id }}
                        </custom-table-cell>
                        <custom-table-cell align="right">
                          {{ priceProps.row.price }}
                        </custom-table-cell>
                        <custom-table-cell align="right">
                          {{ priceProps.row.price_whole }}
                        </custom-table-cell>
                        <custom-table-cell align="right">
                          {{ priceProps.row.invoice_price }}
                        </custom-table-cell>
                        <custom-table-cell align="right">
                          {{ priceProps.row.buh_price }}
                        </custom-table-cell>
                        <custom-table-cell align="right">
                          {{ priceProps.row.increase }}
                        </custom-table-cell>
                        <custom-table-cell align="center">
                          {{ priceProps.row.date_price }}
                        </custom-table-cell>
                        <custom-table-cell align="center">
                          {{ priceProps.row.price_comment }}
                        </custom-table-cell>
                        <custom-table-cell align="center">
                          {{ priceProps.row.fio }}
                        </custom-table-cell>
                        <custom-table-cell align="right">
                          {{ priceProps.row.currency_price }}
                        </custom-table-cell>
                        <custom-table-cell align="right">
                          {{ priceProps.row.currency_price_whole }}
                        </custom-table-cell>
                        <custom-table-cell align="right">
                          {{ priceProps.row.currency_invoice_price }}
                        </custom-table-cell>
                      </q-tr>
                    </template>
                  </CustomTable>
                </div>
              </q-menu>
            </custom-table-cell>
            <custom-table-cell align="right">
              {{ props.row.price_whole }}
              за {{ props.row.sell_unit_name }}
            </custom-table-cell>
            <custom-table-cell align="center">
              {{ props.row.date_price }}
            </custom-table-cell>
            <custom-table-cell align="right">
              <div>
                <CustomInput
                  :controls="true"
                  :value="
                    localChanges[props.row.material_provider_id]
                      ?.temp_netto_price || props.row.temp_netto_price
                  "
                  :style="`background-color: ${
                    hasAnyChanges(
                      props.row.material_provider_id,
                      'temp_netto_price'
                    )
                      ? '#d0ffc3'
                      : 'unset'
                  };`"
                  @change_input="
                    (value) =>
                      handleChange(
                        'temp_netto_price',
                        props.row.material_provider_id,
                        props.row.material_id,
                        value
                      )
                  "
                  @keydown.enter.prevent="
                    saveChanges(props.row, 'temp_netto_price', value)
                  "
                  @blur="resetChanges()"
                  :showClearIcon="false"
                  type="number"
                />
                <q-tooltip
                  >Нажмите <kbd>Enter</kbd> для сохранения изменений</q-tooltip
                >
              </div>
            </custom-table-cell>
            <!--             <?
        if($this->fulfillment_code == 'FULFILLMENT'){?>
        <th>Цена SR (Оптовая)</th>
        <?
        }?> ЭТО ВЕРХ ТАБЛИЦЫ, НАЗВАНИЕ КОЛОНКИ
        -->
            <!-- <? 
        if($this->fulfillment_code == 'FULFILLMENT'){?>
        <td class="bg-lightseagreen text-right"><?=tenge_text($value['price_whole_sr'])?> тг.</td>
        <?
        }?> -->
            <custom-table-cell align="left">
              <div>
                <CustomInput
                  :value="
                    localChanges[props.row.material_provider_id]
                      ?.provider_material_name ||
                    props.row.provider_material_name
                  "
                  :style="`background-color: ${
                    hasAnyChanges(
                      props.row.material_provider_id,
                      'provider_material_name'
                    )
                      ? '#d0ffc3'
                      : 'unset'
                  };`"
                  @change_input="
                    (value) =>
                      handleChange(
                        'provider_material_name',
                        props.row.material_provider_id,
                        props.row.material_id,
                        value
                      )
                  "
                  @keydown.enter.prevent="
                    saveChanges(props.row, 'provider_material_name', value)
                  "
                  @blur="resetChanges()"
                  :showClearIcon="false"
                />
                <q-tooltip
                  >Нажмите <kbd>Enter</kbd> для сохранения изменений</q-tooltip
                >
              </div>
              <span class="hr" v-if="props.row.agreement_name"></span>
              {{ props.row.agreement_name }}
            </custom-table-cell>
            <custom-table-cell align="left">
              {{ props.row.provider_name }}
              {{ props.row.city_name }}
            </custom-table-cell>
            <custom-table-cell
              :style="{
                backgroundColor: getFulfillmentColor(props.row.fulfillment_id),
              }"
              align="left"
            >
              {{ props.row.fulfillment_name }}
            </custom-table-cell>
            <custom-table-cell align="center">
              {{ props.row.vendor_code }}
            </custom-table-cell>
            <custom-table-cell align="left">
              {{ props.row.is_avail_text }}
              <!-- TODO Покрасить в зеленый/красный и увеличивать при наведении (hover) -->
              <!-- кнопка для изменения статуса, либо Сделать в наличии(+), либо Убрать из наличия(х) -->
              <span class="cursor-pointer">
                <a
                  v-if="props.row.is_avail == 0"
                  @click="onSetAvailability(props.row)"
                >
                  <i class="fa fa-plus hover" title="Сделать в наличии"></i>
                </a>
                <a
                  v-if="props.row.is_avail == 1"
                  @click="onRemoveAvailability(props.row)"
                >
                  <i
                    class="fa fa-ban hover"
                    title="Убрать из наличия"
                    color="red"
                  ></i>
                </a>
              </span>
            </custom-table-cell>
            <custom-table-cell align="center">
              <q-checkbox
                v-if="props.row.is_required_create == 1"
                color="black"
                v-model="this.trueCheckboxModel"
                disable
              />
              {{ props.row.warehouse_name }}
            </custom-table-cell>
            <custom-table-cell align="center">
              <q-checkbox
                v-if="props.row.is_main === 1"
                color="black"
                v-model="this.trueCheckboxModel"
                disable
              />
            </custom-table-cell>
            <custom-table-cell align="center">
              <q-checkbox
                v-if="props.row.is_top_10"
                color="black"
                v-model="this.trueCheckboxModel"
                disable
              />
              {{ props.row.top_10 }}
            </custom-table-cell>
            <custom-table-cell align="center">
              <q-checkbox
                v-if="props.row.is_owned_material"
                color="black"
                v-model="this.trueCheckboxModel"
                disable
              />
              {{ props.row.our_material }}
            </custom-table-cell>
            <custom-table-cell>
              <span v-if="props.row.photo_url" class="cursor-pointer text-blue">
                <img :src="props.row.photo_url" alt="Material Photo" />
                <a :href="props.row.photo_url_orig" download style="color: blue"
                  >Скачать</a
                >
              </span>
            </custom-table-cell>
            <custom-table-cell>
              <CustomIcon
                icon="trash"
                :approve-mode="true"
                :loading="this.delBtnLoading[props.row?.material_provider_id]"
                :approve="() => this.onDelete(props.row.material_provider_id)"
              />
            </custom-table-cell>
          </q-tr>
        </template>
      </CustomTable>
    </div>
  </span>
</template>

<script>
import MaterialProviderListFilters from "@/views/MaterialProviderList/MaterialProviderListFilters";
import MaterialAddChange from "@/views/MaterialProviderList/materialAddChange";

import FiltersWrapper from "@/components/common/FiltersWrapper";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomSelect from "@/components/form/CustomSelect";
import CustomInput from "@/components/form/CustomInput";
import CustomIcon from "@/components/common/CustomIcon";

import { appActions } from "@/services/store/modules/app_module/app_actions";
import { MODAL_NAMES } from "@/constants";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";
import {
  MaterialProviderColumns,
  getMatrialProvList,
  getPriceHistoryByMaterialProviderId,
  MaterialProviderPriceHistoryColumns,
  groupActions,
  changeRequirementFlag,
  changeMainPriceFlag,
  changeAvailability,
  changeAdditionalStockRequestFlag,
  attachAgreement,
  onAddNewPrices,
  updateMaterialNameOfProvider,
  updateMaterialNettoPrice,
  onDelete,
} from "@/views/MaterialProviderList/services";
import { getFiltersMetaData } from "@/views/MaterialProviderList/MaterialProviderListFilters/services";

export default {
  name: "MaterialProviderList",
  components: {
    MaterialProviderListFilters,
    MaterialAddChange,
    FiltersWrapper,
    CustomTable,
    CustomTableCell,
    CustomCheckbox,
    CustomSelect,
    CustomInput,
    CustomIcon,
  },
  data() {
    return {
      data: [],
      localChanges: {},
      loading: false,
      isFetching: false,
      allSelected: false,
      selectedItems: {},
      columns: MaterialProviderColumns,
      priceHistColumns: MaterialProviderPriceHistoryColumns,
      isMaterialAddChange: false,
      selectedMaterial: null,
      currentFilters: {
        fulfillment_id: 2,
        material_provider_search: "",
        material_type_id: "",
        provider_id: "",
        material_active: 1,
        is_avail: -1,
        city_id: 1,
        from_wh: "",
        material_id: "",
      },
      popoverOpened: false,
      anchorEl: null,
      priceHistoryData: null,
      trueCheckboxModel: true,
      groupActions,
      providersOptions: [],
      materialTypes: [],
      materialsOptions: [],
      citiesOptions: [],
      daysCnt: [],
      isFetchingFilters: false,
      temp_material_id: "",
      temp_material_provider_id: "",
      delBtnLoading: {},
    };
  },
  async mounted() {
    await this.fetchFilterData();
  },
  watch: {
    selectedItems(newValue) {
      const checkedLength = this.getSelectedLength(newValue);
      const isAllChecked = this?.data.length === checkedLength;
      if (isAllChecked && !!checkedLength) return (this.allSelected = true);
      if (checkedLength > 0) return (this.allSelected = "");
      this.allSelected = false;
    },
  },
  methods: {
    async fetchFilterData() {
      this.isFetchingFilters = true;
      const res = await getFiltersMetaData();
      if (!res) {
        this.isFetchingFilters = false;
        return;
      }

      const [
        materialTypesResponse,
        providersResponse,
        citiesResponse,
        materialsResponse,
      ] = res;

      this.materialTypes = materialTypesResponse.data;
      this.providersOptions = providersResponse.data.map((item) => ({
        provider_id: item.id,
        provider_name: item.provider_name,
        provider_label: `${item.id} / ${item.provider_name}`,
      }));
      this.citiesOptions = citiesResponse.data;
      this.materialsOptions = materialsResponse.data;
      this.isFetchingFilters = false;
    },
    async getData() {
      this.loading = true;
      this.isFetching = true;
      const cleanedFilters = {};
      for (const key in this.currentFilters) {
        if (
          this.currentFilters[key] !== "" &&
          this.currentFilters[key] != null
        ) {
          cleanedFilters[key] = this.currentFilters[key];
        }
      }
      const res = await getMatrialProvList(cleanedFilters);
      this.loading = false;
      this.isFetching = false;
      if (!res) {
        this.showErrorSnackbar();
        return;
      }
      this.data = res;
      this.resetSelectedItems();
    },
    async onShowPricePopover(evt, row) {
      console.log(evt.currentTarget);
      
      if (!!evt.currentTarget.querySelector("i")) return;
      this.anchorEl = evt.currentTarget.querySelector("span"); // элемент, на который кликаем
      const res = await getPriceHistoryByMaterialProviderId(
        row.material_provider_id
      );
      if (!res) return;
      this.priceHistoryData = res;
      this.popoverOpened = true;
    },
    onAddMaterial(row) {
      if (row) {
        this.selectedMaterial = row;
      }
      this.isMaterialAddChange = true;
    },
    closeMaterialAddChange() {
      this.isMaterialAddChange = false;
      this.selectedMaterial = null;
    },
    onFiltersChange(key, value) {
      if (this.currentFilters[key] === value) return;
      this.currentFilters[key] = value;
    },
    onAllCheckClick(value) {
      this.allSelected = value;
      this.selectedItems = this.data.reduce((acc, item) => {
        acc[item.material_provider_id] = value;
        return acc;
      }, {});
    },
    getSelectedLength(selectedItems) {
      if (selectedItems)
        return Object.keys(selectedItems).filter(
          (item) => !!selectedItems[item]
        ).length;
      return Object.keys(this.selectedItems).filter(
        (item) => !!this.selectedItems[item]
      ).length;
    },
    onCheckboxChange(material_provider_id, value) {
      this.selectedItems = {
        ...this.selectedItems,
        [material_provider_id]: value,
      };
    },
    getSelectedRows() {
      return this.data.filter(
        (row) => this.selectedItems[row.material_provider_id]
      );
    },
    resetSelectedItems() {
      this.selectedItems = {};
    },
    getFulfillmentColor(fulfillmentId) {
      if (fulfillmentId === 1) return "darkturquoise";
      if (fulfillmentId === 2) return "#d19300";
      if (fulfillmentId === 3) return "#bacd1b";
      return "transparent"; // Default color if no match
    },
    showPopupConfirm(title, approveAction) {
      if (window.confirm(title)) {
        approveAction(); // Выполняем действие только если пользователь выбрал "ОК"
      }
    },
    async onGroupActionChange(value) {
      // Получаем выбранные строки
      const selectedRows = this.getSelectedRows();
      if (!selectedRows.length) {
        this.$q.notify({
          color: "warning",
          message: "Выберите хотя бы одну строку для выполнения действия",
        });
        return;
      }
      const body = { data: selectedRows };

      switch (value) {
        case 0: // "Проставить признак создание потребности"
          await this.showRequirementFlagModal(body);
          break;

        case 1: // "Убрать признак создание потребности"
          this.showPopupConfirm(
            "Вы действительно хотите убрать признак создания потребности?",
            () => this.onRemoveRequirementFlag(body)
          );
          break;

        case 2: // "Проставить признак основной цены"
          this.showPopupConfirm(
            "Вы действительно хотите проставить признак основной цены?",
            () => this.onSetMainPriceFlag(body)
          );
          break;

        case 3: // "Убрать признак основной цены"
          // TODO Удалить? Не используется
          this.showPopupConfirm(
            "Вы действительно хотите убрать признак основной цены?",
            () => this.onRemoveMainPriceFlag(body)
          );
          break;

        case 4: // "Сделать в наличии"
          this.onSetAvailability(body);
          break;

        case 5: // "Убрать из наличия"
          this.onRemoveAvailability(body);
          break;

        case 6: // "Добавить новые цены"
          this.showPopupConfirm(
            // Вы действительно хотите добавить новые цены?
            "Функция временно недоступна. Добавление цены доступно в одиночном порядке"
            // () => this.showNewPriceAddModal(body)
          );
          break;

        case 7: // "Проставить признак доп. заявки со склада"
          this.showPopupConfirm(
            "Вы действительно хотите проставить признак доп. заявки со склада?",
            () => this.onSetAdditionalStockRequestFlag(body)
          );
          break;

        case 8: // "Убрать признак доп. заявки со склада"
          this.showPopupConfirm(
            "Вы действительно хотите убрать признак доп. заявки со склада?",
            () => this.onRemoveAdditionalStockRequestFlag(body)
          );
          break;

        case 9: // "Прикрепить договор"
          this.showAttachAgreementModal(body);
          break;

        default:
          console.error("Неизвестное действие: ", value);
      }
    },
    showRequirementFlagModal(body) {
      const payload = {
        material_provider_id_arr: body.data.map(
          (row) => row.material_provider_id
        ),
        is_required_create: 1,
      };
      this.$store.commit(
        appActions.showModal(MODAL_NAMES.MATERIAL_LIST_SET_REQUIREMENT, {
          onSubmit: (body) => this.onSetRequirementFlag(body),
          title: "Прикрепление договора поставщика",
          body: payload,
        })
      );
    },
    async onSetRequirementFlag(body) {
      const res = await changeRequirementFlag(body);
      if (!res) return;
      this.getData();
      this.showSucceedSnackbar();
      this.$store.commit(appActions.closeModal());
    },
    async onRemoveRequirementFlag(body) {
      const payload = {
        material_provider_id_arr: body.data.map(
          (row) => row.material_provider_id
        ),
        is_required_create: 0, // Убрать признак создание потребности
      };
      const res = await changeRequirementFlag(payload);
      if (!res) return;
      this.getData();
      this.showSucceedSnackbar();
    },
    async onSetMainPriceFlag(body) {
      const payload = {
        material_provider_id_arr: body.data.map(
          (row) => row.material_provider_id
        ),
        is_main: 1, // Проставить признак основной цены
      };
      const res = await changeMainPriceFlag(payload);
      if (!res) return;
      this.getData();
      this.showSucceedSnackbar();
    },

    async onSetAvailability(body) {
      this.showPopupConfirm(
        "Вы действительно хотите сделать материал в наличии?",
        async () => {
          const materialProviderIds = Array.isArray(body.data)
            ? body.data.map((row) => row.material_provider_id)
            : [body.material_provider_id]; // Wrap single ID in an array

          const payload = {
            material_provider_id_arr: materialProviderIds,
            is_avail: 1, // Сделать в наличии
          };

          const res = await changeAvailability(payload);
          if (!res) return;
          this.getData();
          this.showSucceedSnackbar();
        }
      );
    },

    async onRemoveAvailability(body) {
      this.showPopupConfirm(
        "Вы действительно хотите убрать материал из наличия?",
        async () => {
          const materialProviderIds = Array.isArray(body.data)
            ? body.data.map((row) => row.material_provider_id)
            : [body.material_provider_id]; // Wrap single ID in an array

          const payload = {
            material_provider_id_arr: materialProviderIds,
            is_avail: 0, // Убрать из наличия
          };

          const res = await changeAvailability(payload);
          if (!res) return;
          this.getData();
          this.showSucceedSnackbar();
        }
      );
    },

    async onSetAdditionalStockRequestFlag(body) {
      const payload = {
        material_provider_id_arr: body.data.map(
          (row) => row.material_provider_id
        ),
        is_required_create: 1, // Проставить признак доп. заявки со склада
      };
      const res = await changeAdditionalStockRequestFlag(payload);
      if (!res) return;
      this.getData();
      this.showSucceedSnackbar();
    },

    async onRemoveAdditionalStockRequestFlag(body) {
      const payload = {
        material_provider_id_arr: body.data.map(
          (row) => row.material_provider_id
        ),
        is_required_create: 0, // Убрать признак доп. заявки со склада
      };
      const res = await changeAdditionalStockRequestFlag(payload);
      if (!res) return;
      this.getData();
      this.showSucceedSnackbar();
    },
    showAttachAgreementModal(body) {
      if (body.data.length > 1) {
        this.$q.notify({
          color: "warning",
          message: "Выберите не более одной строки/позиции",
        });
        return; // Stop function execution
      }

      const providerId = body.data[0]?.provider_id || null;
      const payload = {
        material_provider_id_arr: body.data.map(
          (row) => row.material_provider_id
        ),
        provider_id: providerId,
      };
      this.$store.commit(
        appActions.showModal(MODAL_NAMES.MATERIAL_LIST_ATTACH_AGREEMENT, {
          onSubmit: (body) => this.onAttachAgreement(body),
          title: "Признак создания потребности",
          body: payload,
        })
      );
    },
    async onAttachAgreement(body) {
      const res = await attachAgreement(body);
      if (!res) return;
      this.getData();
      this.showSucceedSnackbar();
      this.$store.commit(appActions.closeModal());
    },
    showNewPriceAddModal(row) {
      this.popoverOpened = false;
      // const payload = {
      //   material_provider_id_arr: body.data.map(
      //     (row) => row.material_provider_id
      //   ),
      //   is_required_create: 1,
      // };
      this.$store.commit(
        appActions.showModal(MODAL_NAMES.MATERIAL_LIST_ADD_PRICE, {
          onSubmit: (body) => this.onAddNewPrices(body),
          title: "Добавление цены",
          body: row,
        })
      );
    },
    async onAddNewPrices(body) {
      const res = await onAddNewPrices(body);
      if (!res) return;
      this.getData();
      this.showSucceedSnackbar();
      this.$store.commit(appActions.closeModal());
    },
    async onDelete(material_provider_id) {
      this.delBtnLoading = {
        ...this.delBtnLoading,
        [material_provider_id]: true,
      };
      const res = await onDelete(material_provider_id);
      this.btnLoading = {
        ...this.delBtnLoading,
        [material_provider_id]: false,
      };

      if (!res) return;
      this.getData();
      this.showSucceedSnackbar();
    },
    hasAnyChanges(materialProviderId, type) {
      const changes = this.localChanges[materialProviderId];
      if (!changes) return false;
      if (type === "temp_netto_price") {
        const originalCnt = this.data[materialProviderId]?.temp_netto_price;
        return (
          changes.temp_netto_price !== undefined &&
          +changes.temp_netto_price !== originalCnt
        );
      } else if (type === "provider_material_name") {
        const originalCnt =
          this.data[materialProviderId]?.provider_material_name;
        return (
          changes.provider_material_name !== undefined &&
          +changes.provider_material_name !== originalCnt
        );
      }
    },
    handleChange(type, materialProviderId, material_id, newValue) {
      if (!materialProviderId) {
        console.warn("Invalid materialProviderId:", materialProviderId);
        return;
      }

      this.temp_material_id = material_id;
      this.temp_material_provider_id = materialProviderId;

      this.localChanges = {
        ...this.localChanges,
        [materialProviderId]: {
          ...(this.localChanges[materialProviderId] || {}), // Preserve existing data
          [type]: newValue, // Update specific field
        },
      };
    },
    async saveChanges(row, type) {
      const changes = this.localChanges[this.temp_material_provider_id];

      if (!changes) return;

      const payload = {
        material_provider_id: row.material_provider_id,
        [type]: this.localChanges[this.temp_material_provider_id][type],
      };

      let res;
      if (type === "provider_material_name") {
        res = await updateMaterialNameOfProvider(payload);
      } else if (type === "temp_netto_price") {
        res = await updateMaterialNettoPrice(payload);
      }
      if (!res || (Array.isArray(res) && res.length === 0)) return;
      this.getData();
      this.resetChanges();
      this.showSucceedSnackbar("Изменения успешно сохранены!");
    },
    resetChanges() {
      this.localChanges = {};
      this.temp_material_id = "";
      this.temp_material_provider_id = "";
    },
  },
  setup() {
    const { showSucceedSnackbar, showErrorSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar, showErrorSnackbar };
  },
  computed: {
    computedLabel() {
      const hasSelected = Object.values(this.selectedItems).some(
        (value) => value === true
      );
      return hasSelected ? "Нажмите для выбора действия" : "Выберите позицию";
    },
  },
};
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.addMaterialBtn {
  margin-bottom: 30px;
}

.hover:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.hr {
  display: block;
  height: 0;
  overflow: hidden;
  font-size: 0;
  border-width: 1px 0 0;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.11);
  margin: 12px 0;
}
.clickable-text {
  cursor: pointer;
  color: #3498db;
  text-decoration: underline;
}
.clickable-text:hover {
  color: #217dbb;
}
.fa-ban {
  color: red;
}
.fa-plus {
  color: green;
}
</style>
