<template>
  <FiltersWrapper>
    <div ref="header">
      <PriceChangesFilters
        :values="this.currentFilters"
        :getData="this.getData"
        @filter_change="this.onFiltersChange"
      />
    </div>
  </FiltersWrapper>

  <div>
    <CustomTable
      title="Запросы на изменение цен"
      :rowsData="this.data"
      :columns="columns"
      :stickyHeader="false"
      :loading="loading"
      :custom-body="true"
      :small="true"
      checkbox="true"
      :checkbox-value="this.allSelected"
      :on-all-check-click="onAllCheckClick"
    >
      <template #top-right>
        <span v-if="Object.keys(this.selectedItems).length > 0">
          <q-btn size="sm" no-wrap color="secondary" style="margin-right: 10px"
            ><i class="material-icons">check</i>&nbsp;Утвердить выбранные записи
            {{ approvalButtonLabel }}
            <PopupConfirm
              :loading="this.approveBtnLoading"
              :approve="() => this.onApproval('approve')"
            />
          </q-btn>
          <q-btn size="sm" no-wrap color="red"
            ><i :class="`material-icons`">close</i>&nbsp;Отклонить выбранные
            записи {{ approvalButtonLabel }}
            <PopupConfirm
              :loading="this.rejectBtnLoading"
              :approve="() => this.onApproval('reject')"
            />
          </q-btn>
        </span>
      </template>

      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="center">
            <CustomCheckbox
              :value="!!this.selectedItems[props.row.mp_buffer_price_id]"
              @change="
                (value) => onCheckboxChange(props.row.mp_buffer_price_id, value)
              "
            />
          </custom-table-cell>

          <custom-table-cell align="right">{{
            props.row.mp_buffer_price_id
          }}</custom-table-cell>
          <custom-table-cell align="right">{{
            props.row.material_id
          }}</custom-table-cell>
          <custom-table-cell align="left">{{
            props.row.material_type_name
          }}</custom-table-cell>
          <custom-table-cell align="left">{{
            props.row.material_name
          }}</custom-table-cell>

          <custom-table-cell align="right" class="bg-success">
            {{ props.row.price }} за {{ props.row.sell_unit_name }}
          </custom-table-cell>
          <custom-table-cell align="right" class="bg-success">
            {{ props.row.price_whole }} за {{ props.row.sell_unit_name }}
          </custom-table-cell>
          <custom-table-cell align="right" class="bg-success">
            {{ props.row.invoice_price }} за {{ props.row.sell_unit_name }}
          </custom-table-cell>
          <custom-table-cell align="center" class="bg-success">{{
            props.row.date_price
          }}</custom-table-cell>

          <custom-table-cell align="left"
            >{{ props.row.provider_name }} ({{
              props.row.city_name
            }})</custom-table-cell
          >
          <custom-table-cell align="left">
            {{ props.row.fio }}<br />
            {{ props.row.rowversion }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.accept_fio }}<br />
            {{ props.row.accept_date }}
          </custom-table-cell>

          <custom-table-cell
            :style="{ backgroundColor: getStatusColor(props.row.is_accept) }"
            align="center"
          >
            {{ props.row.status_text }}
          </custom-table-cell>

          <custom-table-cell align="right" class="bg-yellow">
            {{ props.row.price_old }} за {{ props.row.sell_unit_name }}
          </custom-table-cell>
          <custom-table-cell align="right" class="bg-yellow">
            {{ props.row.price_whole_old }} за {{ props.row.sell_unit_name }}
          </custom-table-cell>
          <custom-table-cell align="right" class="bg-yellow">
            {{ props.row.invoice_price_old }} за {{ props.row.sell_unit_name }}
          </custom-table-cell>
          <custom-table-cell align="center" class="bg-yellow">{{
            props.row.date_price_old
          }}</custom-table-cell>

          <custom-table-cell align="center">
            <CustomIcon
              icon="trash"
              :approve-mode="true"
              :loading="this.delBtnLoading[props.row?.mp_buffer_price_id]"
              :approve="() => this.onDelete(props.row.mp_buffer_price_id)"
            />
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import PriceChangesFilters from "@/views/MaterialProvPriceChanges/PriceChangesFilters";

import FiltersWrapper from "@/components/common/FiltersWrapper";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomIcon from "@/components/common/CustomIcon";
import PopupConfirm from "@/components/common/PopupConfirm";

import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";

import {
  PriceChangesColumns,
  getPriceChangesList,
  priceChangeApproval,
  onDelete,
} from "@/views/MaterialProvPriceChanges/services";

export default {
  name: "MaterialProvPriceChanges",
  components: {
    PriceChangesFilters,
    FiltersWrapper,
    CustomTable,
    CustomTableCell,
    CustomCheckbox,
    CustomIcon,
    PopupConfirm,
  },
  data() {
    return {
      data: [],
      loading: false,
      isFetching: false,
      allSelected: false,
      selectedItems: {},
      columns: PriceChangesColumns,
      currentFilters: {
        date_begin: "",
        date_end: "",
        is_accept: -1,
      },
      delBtnLoading: {},
      approveBtnLoading: false,
      rejectBtnLoading: false,
    };
  },
  watch: {
    selectedItems(newValue) {
      const checkedLength = this.getSelectedLength(newValue);
      const isAllChecked = this?.data.length === checkedLength;
      if (isAllChecked && !!checkedLength) return (this.allSelected = true);
      if (checkedLength > 0) return (this.allSelected = "");
      this.allSelected = false;
    },
  },
  computed: {
    approvalButtonLabel() {
      const count = this.getSelectedLength();
      return `(${count})`;
    },
  },
  methods: {
    async getData() {
      this.loading = true;
      this.isFetching = true;
      const res = await getPriceChangesList(this.currentFilters);
      this.loading = false;
      this.isFetching = false;
      if (!res) {
        this.showErrorSnackbar();
        return;
      }
      this.filters = { ...this.currentFilters };
      this.data = res;
      this.resetSelectedItems();
    },
    onFiltersChange(key, value) {
      if (this.currentFilters[key] === value) return;
      this.currentFilters[key] = value;
    },
    onAllCheckClick(value) {
      this.allSelected = value;
      this.selectedItems = this.data.reduce((acc, item) => {
        acc[item.mp_buffer_price_id] = value;
        return acc;
      }, {});
    },
    getSelectedLength(selectedItems) {
      if (selectedItems)
        return Object.keys(selectedItems).filter(
          (item) => !!selectedItems[item]
        ).length;
      return Object.keys(this.selectedItems).filter(
        (item) => !!this.selectedItems[item]
      ).length;
    },
    onCheckboxChange(mp_buffer_price_id, value) {
      this.selectedItems = {
        ...this.selectedItems,
        [mp_buffer_price_id]: value,
      };
    },
    getSelectedRows() {
      return this.data.filter(
        (row) => this.selectedItems[row.mp_buffer_price_id]
      );
    },
    resetSelectedItems() {
      this.selectedItems = {};
    },
    getStatusColor(isAccept) {
      if (isAccept === 1) return "#c2f0b2";
      if (isAccept === 2) return "red";
      return "transparent"; 
    },
    async onApproval(type) {
      const payload = {
        mp_buffer_price_id_arr: Object.keys(this.selectedItems).filter((key) =>
          this.selectedItems[key].map(Number)
        ),
        is_accept: type === "approve" ? 1 : 2,
      };

      const res = await priceChangeApproval(payload);
      if (!res) return;
      this.getData();
      this.showSucceedSnackbar();
    },
    async onDelete(mp_buffer_price_id) {
      this.delBtnLoading = {
        ...this.delBtnLoading,
        [mp_buffer_price_id]: true,
      };
      const res = await onDelete(mp_buffer_price_id);
      this.btnLoading = {
        ...this.delBtnLoading,
        [mp_buffer_price_id]: false,
      };

      if (!res) return;
      this.getData();
      this.showSucceedSnackbar();
    },
  },
  setup() {
    const { showSucceedSnackbar, showErrorSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar, showErrorSnackbar };
  },
};
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.hover:hover {
  transform: scale(1.05);
}
.hr {
  display: block;
  height: 0;
  overflow: hidden;
  font-size: 0;
  border-width: 1px 0 0;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.11);
  margin: 12px 0;
}
.bg-success {
  background-color: #c2f0b2 !important;
}
.bg-yellow {
  background-color: #f3eec3 !important;
}
</style>
