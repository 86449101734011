import { materialsAPI } from "@/views/Materials/api";

export const getMaterials = async (filters) => {
  try {
    const res = await materialsAPI.getMaterialsList(filters);
    return res?.data;
  } catch (e) {}
};
export const getMaterialData = async (material_id, whichTab) => {
  try {
    if (whichTab === "zakup") {
      const [listResponse, dataResponse] = await Promise.all([
        materialsAPI.getMaterialZakupList(material_id), // Получает list
        materialsAPI.getMaterialZakupData(material_id), // Получает data
      ]);
      return {
        list: listResponse?.data || [], // Данные списка
        data: dataResponse?.data || [], // Данные формы
      };
    } else if (whichTab === "smetniy") {
      const res = await materialsAPI.getMaterialSmetniyData(material_id);
      return res?.data;
    } else if (whichTab === "constructor") {
      const res = await materialsAPI.getMaterialConstructorData(material_id);
      return res?.data;
    }
  } catch (e) {}
};
export const updateZakupMaterial = async (body) => {
  try {
    const res = await materialsAPI.updateZakupMaterial(body);
    return res?.data;
  } catch (e) {}
};
export const updateSmetniyMaterial = async (body) => {
  try {
    const res = await materialsAPI.updateSmetniyMaterial(body);
    return res?.data;
  } catch (e) {}
};
export const updateMaterialThreshold = async (body) => {
  try {
    const res = await materialsAPI.updateMaterialThreshold(body);
    return res?.data;
  } catch (e) {}
};
export const updateConstructorMaterial = async (body) => {
  try {
    const res = await materialsAPI.updateConstructorMaterial(body);
    return res?.data;
  } catch (e) {}
};

export const getDoorHeights = async () => {
  try {
    const res = await materialsAPI.getMaterialDoorHeights();
    return res?.data;
  } catch (e) {}
};
export const getMaterialCollections = async (material_type_id) => {
  try {
    const res = await materialsAPI.getMaterialCollections(material_type_id);
    return res?.data;
  } catch (e) {}
};
export const getMaterialUnits = async () => {
  try {
    const res = await materialsAPI.getMaterialUnits();
    return res?.data;
  } catch (e) {}
};
export const getMaterialInfo = async (material_id) => {
  try {
    const res = await materialsAPI.getMaterialInfo(material_id);
    return res;
  } catch (e) {}
};


export const materialsCommonColumns = [
  {
    name: "material_id",
    field: "material_id",
    label: "ID",
    align: "center",
    alignTable: "center",
    sortable: true,
  },
  {
    name: "material_name",
    field: "material_name",
    label: "Наименование",
    align: "center",
    sortable: true,
    headerStyle: "width: 140px",
    style: "width: 140px",
  },
  {
    name: "material_type_name",
    field: "material_type_name",
    label: "Тип",
    align: "center",
    sortable: true,
  },
  {
    name: "sell_unit_name",
    field: "sell_unit_name",
    label: "Ед. продажи",
    align: "center",
    sortable: true,
  },
  {
    name: "consume_unit_name",
    field: "consume_unit_name",
    label: "Ед. расхода",
    align: "center",
    sortable: true,
  },
  {
    name: "delivery_unit_name",
    field: "delivery_unit_name",
    label: "Ед. отпуска",
    align: "center",
    sortable: true,
  },
  {
    name: "atom_unit_name",
    field: "atom_unit_name",
    label: "Мин. ед.",
    align: "center",
  },
  {
    name: "is_koef_round",
    field: "is_koef_round",
    label: "Округление",
    alignTable: "center",
    align: "center",
    sortable: true,
  },
  {
    name: "is_active",
    field: "is_active",
    label: "Активный",
    alignTable: "center",
    align: "center",
    sortable: true,
  },
  {
    name: "infbl",
    field: "infbl",
    label: "",
    alignTable: "center",
    align: "center",
  },
  {
    name: "info",
    field: "info",
    label: "",
    alignTable: "center",
    align: "center",
  },
];
export const currencyOptions = [
  { label: "KZT - Тенге", value: "KZT" },
  { label: "RUB - Российский рубль", value: "RUB" },
  { label: "USD - Доллар США", value: "USD" },
  { label: "EUR - Евро", value: "EUR" },
  { label: "BYN - Белорусский рубль", value: "BYN" },
];
