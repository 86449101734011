import {BalancesAPI} from "@/views/Balances/api";

export const getAllBalances = async () => {
  try {
    const res = await BalancesAPI.getList()
    return res?.data
  } catch (e) {
  }
}
export const getBalance = async (balance_id) => {
  try {
    const res = await BalancesAPI.getBalance(balance_id)
    return res?.data
  } catch (e) {
  }
}
export const updateBalance = async (body) => {
  try {
    const res = await BalancesAPI.updateBalance(body)
    return res?.data
  } catch (e) {
  }
}
export const deleteBalance = async (balance_id) => {
  try {
    const res = await BalancesAPI.deleteBalance(balance_id)
    return res?.data
  } catch (e) {
  }
}

export const balancesColumns = [
  {
    name: "balance_id",
    field: "balance_id",
    label: "ID",
    align: "center",
  },
  {
    name: "balance_date",
    field: "balance_date",
    label: "Дата",
    align: "left",
  },
  {
    name: "contractor_agreement_list_id",
    field: "contractor_agreement_list_id",
    label: "contractor_agreement_list_id",
    align: "left",
  },
  {
    name: "balance_sum",
    field: "balance_sum",
    label: "Сумма баланса",
    align: "left",
  },
  {
    name: "fio",
    field: "fio",
    label: "Кто создал",
    align: "left",
  },
  {
    name: "rowversion",
    field: "rowversion",
    label: "Дата создания",
    align: "left",
  },
]