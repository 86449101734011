import instance from "@/services/api";

export const roomBookingAPI = {
  async getRooms(date, timeId = 0) {
    return await instance()
    .get(`/common/remarket/room/${date}/${timeId}/`)
    .then((res) => res?.data);
  },
  async getAvailableTimes(date, roomId = 0) {
    return await instance()
    .get(`/common/remarket/time/${date}/${roomId}/`)
    .then((res) => res?.data);
  },
  async bookRoom(body) {
    return await instance()
    .post("/common/remarket/book_upd/", body)
    .then((res) => res?.data);
  },
  async getBookingRecords() {
    return await instance()
    .get("/common/remarket/book_read/")
    .then((res) => res?.data);
  },
  async getUserBookingRecords() {
    return await instance()
    .get("/common/remarket/book_read_user/")
    .then((res) => res?.data);
  },
  async deleteUserBooking(remarket_book_id) {
    return await instance()
    .delete(`/common/remarket/${remarket_book_id}/book_del/`)
    .then((res) => res?.data);
  },
}