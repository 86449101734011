<template>
  <div v-show="!hidden">
    <q-tabs
        v-model="model"
        align="left"
        class="bg-white text-dark"
        dense
    >
      <q-tab v-for="item in this.data" :name="item[this.nameOption || 'name']" :label="item[this.labelOption || 'label']"/>
    </q-tabs>
    <q-tab-panels v-model="model" animated>
      <CustomTabPanel v-for="item in this.data" :name="item[this.nameOption || 'name']" >
        <slot :data="item"></slot>
      </CustomTabPanel>
    </q-tab-panels>
  </div>
</template>

<script>
import CustomTabPanel from "@/components/common/CustomTabs/CustomTabPanel";
export default {
  name: "CustomTabs",
  components: {CustomTabPanel},
  props: {
    labelOption: String,
    nameOption: String,
    tab: String,
    data: Array,
    hidden: Boolean,
  },
  computed: {
    model: {
      get() {
        return this.tab;
      },
      set(newValue) {
        this.$emit("change_tab", newValue);
      },
    },
  },
}
</script>

<style scoped>

</style>