<template>
  <FiltersWrapper v-if="!isMaterialEdit">
    <div ref="header">
      <MaterialsFilters
        :values="this.currentFilters"
        :getData="this.getData"
        :providersOptions="this.providersOptions"
        :materialTypes="this.materialTypes"
        @filter_change="this.onFiltersChange"
        :isFetching="this.isFetching"
      />
    </div>
  </FiltersWrapper>
  <MaterialsList
    :data="this.data"
    :materialTypes="this.materialTypes"
    @toggle-update="isMaterialEdit = $event"
    :whichTab="whichTab"
    :tabLabel="tabLabel"
    :getTableData="getData"
  />
</template>

<script>
import MaterialsList from "@/views/Materials/MaterialsList";
import MaterialsFilters from "@/views/Materials/MaterialsFilters";

import FiltersWrapper from "@/components/common/FiltersWrapper";

import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";
import { getMaterials } from "@/views/Materials/services";
import { getFiltersMetaData } from "@/views/Materials/MaterialsFilters/services";

export default {
  name: "Materials",
  components: {
    MaterialsList,
    MaterialsFilters,
    FiltersWrapper,
  },
  data() {
    return {
      data: [],
      loading: false,
      isFetching: false,
      whichTab: "zakup",
      tabLabel: "Отдел закупа",
      currentFilters: {
        material_type_id: "",
        provider_id: "",
        material_id: "",
      },
      providersOptions: [],
      materialTypes: [],
      isFetchingFilters: false,
      isMaterialEdit: false,
    };
  },
  async mounted() {
    await this.fetchFilterData();
  },
  methods: {
    async fetchFilterData() {
      this.isFetchingFilters = true;
      const res = await getFiltersMetaData();
      if (!res) {
        this.isFetchingFilters = false;
        return;
      }
      const [materialTypesResponse, providersResponse] = res;
      this.materialTypes = materialTypesResponse.data;
      this.providersOptions = providersResponse.data.map((item) => ({
        provider_id: item.id,
        provider_name: item.provider_name,
        provider_label: `${item.id} / ${item.provider_name}`,
      }));
      this.isFetchingFilters = false;
    },
    async getData() {
      this.loading = true;
      this.isFetching = true;
      const res = await getMaterials(this.currentFilters);
      this.loading = false;
      this.isFetching = false;
      if (!res) return;
      this.data = res;
    },
    onFiltersChange(key, value) {
      if (this.currentFilters[key] === value) return;
      this.currentFilters[key] = value;
    },
  },
  setup() {
    const { showSucceedSnackbar, showErrorSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar, showErrorSnackbar };
  },
};
</script>
