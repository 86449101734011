<template>
  <div v-if="loading" class="wrapper text-h6 text-center">Идет загрузка...</div>
  <div v-else class="wrapper">
    <!-- Text Info Section -->
    <div class="q-mb-lg">
      <div class="text-h6">Информация о материале</div>
      <div v-if="materialData?.data_8?.length">
        <div>Материал: <b>{{ materialData.data_8[0].material_name }}</b></div>
        <div>Тип: <b>{{ materialData.data_8[0].material_type_name }}</b></div>
        <div>
          У поставщика:
          <b><span v-html="materialData.data_8[0].material_name_extra"></span></b>
        </div>
      </div>
      <div v-else class="text-grey">Информация о материале отсутствует</div>
    </div>

    <!-- Repair Requests Section -->
    <div class="q-mb-lg">
      <div class="text-h6">
        Список заявок на ремонт ({{ materialData?.data_11?.[0]?.request_cnt || 0 }})
      </div>
      <div v-if="materialData?.data_1?.length" class="q-gutter-sm">
        <q-btn
          v-for="request in materialData.data_1"
          :key="request.client_request_id"
          outline
          color="white"
          text-color="primary"
          :label="request.client_request_id"
          @click="openInNewTab(`/requests/${request.client_request_id}`, '_blank')"
        />
      </div>
      <div v-else class="text-grey">Заявок на ремонт не найдено</div>
    </div>

    <!-- Active Repairs Section -->
    <div class="q-mb-lg">
      <div class="text-h6">
        Список активных ремонтов ({{ materialData?.data_11?.[0]?.remont_cnt || 0 }})
      </div>
      <div v-if="materialData?.data_2?.length" class="q-gutter-sm">
        <q-btn
          v-for="remont in materialData.data_2"
          :key="remont.remont_id"
          outline
          color="white"
          text-color="primary"
          :label="remont.remont_id"
          @click="openInNewTab(`/${PAGES_HEADS.remont}/${remont.remont_id}?tab=requisites`, '_blank')"
        />
      </div>
      <div v-else class="text-grey">Активных ремонтов не найдено</div>
    </div>

    <!-- Presets Table -->
    <div class="q-mb-lg">
      <div class="text-h6">
        В каких пресетах в каких комнатах и ворк сетах находится материал
      </div>
      <div v-if="materialData?.data_3?.length">
        <CustomTable
          :rowsData="materialData.data_3"
          :columns="presetColumns"
          :loading="loading"
          :custom-body="true"
          :sticky-header="true"
        >
          <template #customBody="props">
            <q-tr>
              <custom-table-cell>{{ props.row.preset_name }}</custom-table-cell>
              <custom-table-cell>{{ props.row.preset_material_id }}</custom-table-cell>
              <custom-table-cell>{{ props.row.is_active_preset_material ? "Да" : "Нет" }}</custom-table-cell>
              <custom-table-cell>{{ props.row.city_name }}</custom-table-cell>
              <custom-table-cell>{{ props.row.room_name }}</custom-table-cell>
              <custom-table-cell>{{ props.row.work_set_name }}</custom-table-cell>
              <custom-table-cell>{{ props.row.is_active_work_set ? "Да" : "Нет" }}</custom-table-cell>
            </q-tr>
          </template>
        </CustomTable>
      </div>
      <div v-else class="text-grey">Материал не используется в пресетах</div>
    </div>

    <!-- Provider Requests Table -->
    <div class="q-mb-lg">
      <div class="text-h6">
        Когда этот материал и на какой ремонт заказывался через заявку поставщиков
      </div>
      <div v-if="materialData?.data_4?.length">
        <CustomTable
          :rowsData="materialData.data_4"
          :columns="providerRequestColumns"
          :loading="loading"
          :custom-body="true"
          :sticky-header="true"
        >
          <template #customBody="props">
            <q-tr>
              <custom-table-cell>{{ props.row.remont_id }}</custom-table-cell>
              <custom-table-cell>{{ props.row.date_send }}</custom-table-cell>
            </q-tr>
          </template>
        </CustomTable>
      </div>
      <div v-else class="text-grey">История заказов отсутствует</div>
    </div>

    <!-- Warehouse Stock Table -->
    <div class="q-mb-lg">
      <div class="text-h6">Остаток этого материала на складе</div>
      <div v-if="materialData?.data_5?.length">
        <CustomTable
          :rowsData="materialData.data_5"
          :columns="warehouseStockColumns"
          :loading="loading"
          :custom-body="true"
          :sticky-header="true"
        >
          <template #customBody="props">
            <q-tr>
              <custom-table-cell>{{ props.row.warehouse_name }}</custom-table-cell>
              <custom-table-cell>{{ props.row.avail_cnt }}</custom-table-cell>
            </q-tr>
          </template>
        </CustomTable>
      </div>
      <div v-else class="text-grey">Данные по остаткам отсутствуют</div>
    </div>

    <!-- Last Shipping Table -->
    <div class="q-mb-lg">
      <div class="text-h6">Когда последний раз отгрузили его со склада</div>
      <div v-if="materialData?.data_6?.length">
        <CustomTable
          :rowsData="materialData.data_6"
          :columns="lastShippingColumns"
          :loading="loading"
          :custom-body="true"
          :sticky-header="true"
        >
          <template #customBody="props">
            <q-tr>
              <custom-table-cell>{{ props.row.warehouse_name }}</custom-table-cell>
              <custom-table-cell>{{ props.row.date_shipping }}</custom-table-cell>
            </q-tr>
          </template>
        </CustomTable>
      </div>
      <div v-else class="text-grey">История отгрузок отсутствует</div>
    </div>

    <!-- Total Orders Count -->
    <div class="q-mb-lg">
      <div class="text-h6">
        Сколько раз вообще заказывался этот материал через заявку поставщиков
      </div>
      <div v-if="materialData?.data_7?.[0]?.cnt">
        <q-btn 
          outline
          color="white"
          text-color="primary"
          :label="materialData.data_7[0].cnt" 
          disabled
        />
      </div>
      <div v-else class="text-grey">Заказов не было</div>
    </div>

    <!-- Duplicates Table -->
    <div class="q-mb-lg">
      <div class="text-h6">Дубликаты</div>
      <div v-if="materialData?.data_9?.length">
        <CustomTable
          :rowsData="materialData.data_9"
          :columns="duplicatesColumns"
          :loading="loading"
          :custom-body="true"
          :sticky-header="true"
        >
          <template #customBody="props">
            <q-tr>
              <custom-table-cell>{{ props.row.material_name }}</custom-table-cell>
              <custom-table-cell>{{ props.row.material_type_name }}</custom-table-cell>
              <custom-table-cell>{{ props.row.similarity_percent }}</custom-table-cell>
            </q-tr>
          </template>
        </CustomTable>
      </div>
      <div v-else class="text-grey">Дубликатов не найдено</div>
    </div>

    <!-- Material Sets Count -->
    <div class="q-mb-lg">
      <div class="text-h6">Список наборов в которых присутствует материал</div>
      <div v-if="materialData?.data_10?.length" class="q-gutter-sm">
        <q-btn
          v-for="set in materialData.data_10"
          :key="set.material_set_id"
          outline
          color="white"
          text-color="primary"
          :label="set.material_set_id"
        />
      </div>
      <div v-else class="text-grey">Материал не используется в наборах</div>
    </div>

    <CustomModalFooter :btn-loading="loading" :hideSubmitBtn="true" />
  </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import { getMaterialInfo } from "@/views/Materials/services";
import {
  presetColumns,
  providerRequestColumns,
  warehouseStockColumns,
  lastShippingColumns,
  duplicatesColumns,
} from "@/components/modal/modals/materials-info/services";
import {PAGES_HEADS} from "@/constants";

export default {
  name: "materials-info",
  components: { CustomModalFooter, CustomTable, CustomTableCell },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      PAGES_HEADS,
      materialData: {},
      material_id: null,
      loading: false,
      presetColumns,
      providerRequestColumns,
      warehouseStockColumns,
      lastShippingColumns,
      duplicatesColumns,
    };
  },
  methods: {
    async getData() {
      if (!this.material_id) return;
      this.loading = true;
      const res = await getMaterialInfo(this.material_id);
      if (!res) return;
      this.materialData = res;
      this.loading = false;
    },
    openInNewTab(url) {
      window.open(url, '_blank');
    },
  },
  watch: {
    "data.body.material_id": {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.material_id = newVal;
      this.getData();
    }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  padding: 20px 20px 0 20px;
  gap: 20px;
}
</style>
