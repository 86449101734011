<template>
  <div class="actions__right">
    <q-btn @click="() => this.handleAddWorkClick(this.client_request_id, this.ds_id)" class="actions__addWork"
      label="Добавить работу" icon="add" size="sm" />
    <q-btn size="sm"
      @click="() => this.handleAddMaterialClick(this.client_request_id, this.client_material_id, this.ds_id, this.material_id, this.work_set_id, this.material_set_id, this.room_id)"
      class="actions__addMaterial" label="Добавить материал" icon="edit" />
  </div>
  <div v-if="loading">
    <q-linear-progress indeterminate />
  </div>
  <div v-else>
    <CustomTable :hideTopTitle="true" :rowsData="this.rowsWithIndex" :columns="textConstructorColumns"
      :loading="this.isTKFetching" :custom-body="true" :sticky-header="true">
      <template #customBody="props">
        <q-tr :class="getRowClass(props.row.action_type, this.hasAnyChanges(props.row.index))">
          <q-tooltip v-if="props.row.action_type">
            {{ actionTypeMap[props.row.action_type] }}
          </q-tooltip>
          <custom-table-cell align="center">
            {{ props.row.client_material_id }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.room_name }}
          </custom-table-cell>
          <custom-table-cell align="left">
            <q-icon v-if="props.row.is_optional" class="textConstructor__star" name="star"
              title="Опциональный материал" />
            <span v-html="`${props.row.material_name}`"> </span>
            <br>
            <i>— {{ props.row.work_set_name }}</i>
          </custom-table-cell>
          <custom-table-cell align="right">
            <div v-if="props.row.is_material_cnt_input && props.row.action_type !== 1">
              <div v-if="props.row.is_atom_measure === 1">
                Новое кол-во: {{ props.row.material_new_cnt }}
                <div class="hr hr-0"></div>
              </div>
              <CustomInput :value="this.localChanges[props.row.index]?.material_cnt" :controls="true"
                :style="`background-color: ${hasAnyChanges(props.row.index, true) ? '#d0ffc3' : 'unset'};`"
                @change_input="(value) => handleChange(props.row.index, value)" type="number" />
            </div>
            <span v-else>{{ props.row.material_cnt }}</span>
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.unit_name }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ !!props.row.set_name ? `${props.row.material_set_id} / ${props.row.set_name}` : '' }}
            <div v-if="!!props.row.items_json">
              <q-btn @click="() => this.toggleCollapse(props.row.index)" color="primary" size="sm" class="collapseBtn">
                Материалы в наборе ({{ getItemsCount(props.row.items_json) }})</q-btn>
              <CollapseBlock :onChange="() => this.toggleCollapse(props.row.index)"
                :collapsedProps="!this.collapsedItems[props.row.index]" :controlMode="true" hideExpand>
                <CustomTable :columns="textConstructorMaterialsColumns" :rowsData="props.row.items_json" :small="true"
                  :custom-body="true" :dense="true" flat :hideTopTitle="true" :striped="false">
                  <template #customBody="innerProps">
                    <q-tr>
                      <custom-table-cell align="center">
                        {{ innerProps.row.material_id }}
                      </custom-table-cell>
                      <custom-table-cell align="left">
                        {{ innerProps.row.material_name }}
                      </custom-table-cell>
                      <custom-table-cell align="center">
                        <CustomImg class="textConstructor__photo" :src="innerProps.row.photo_url"
                          alt="material_photo" />
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        <div v-if="props.row.is_material_cnt_input && props.row.action_type !== 1">
                          <div v-if="props.row.is_atom_measure === 1">
                            Новое кол-во: {{ innerProps.row.material_cnt_new }}
                            <div class="hr hr-0"></div>
                          </div>
                          <CustomInput :value="this.localChanges[props.row.index]?.items[innerProps.row.material_id]"
                            :controls="true"
                            @change_input="(value) => handleInnerChange(props.row.index, innerProps.row.material_id, value)"
                            :style="`background-color: ${this.hasAnyChanges(props.row.index, false, innerProps.row.material_id) ? '#d0ffc3' : 'unset'};`"
                            type="number" />
                        </div>
                        <div v-else>{{ innerProps.row.material_cnt }}</div>
                      </custom-table-cell>
                      <custom-table-cell align="left">
                        {{ innerProps.row.unit_name }}
                      </custom-table-cell>
                    </q-tr>
                  </template>
                </CustomTable>
              </CollapseBlock>
            </div>

          </custom-table-cell>
          <custom-table-cell align="center">
            <CustomImg class="textConstructor__photo" :src="props.row.material_photo_url" alt="material_photo" />
          </custom-table-cell>
          <custom-table-cell align="center">
            <div class="tableBtns">
              <q-btn
                @click="() => this.handleAddMaterialClick(this.client_request_id, props.row.client_material_id, this.ds_id, props.row.material_id, props.row.work_set_id, props.row.material_set_id, props.row.room_id)"
                class="actions__addMaterial" size="sm" dense>
                <i :class="`material-icons`">edit</i>
                <div class="title">&nbsp;Изменить</div>
              </q-btn>
              <!-- <q-btn v-if="props.row.action_type === 3" class="actions__undo" size="sm" dense>
                <i :class="`material-icons`">cancel</i>
                <div class="title">&nbsp;Отменить изменение</div>
                <PopupConfirm
                  :approve="() => this.modifyCancel(this.client_request_id, props.row.client_material_id)" />
              </q-btn> -->
              <q-btn v-if="props.row.action_type !== 1" color="negative" size="sm" dense>
                <i class="material-icons">delete</i>
                <div class="title">&nbsp;Удаление</div>
                <PopupConfirm
                  :approve="() => this.materialDelete(this.client_request_id, this.ds_id, props.row.client_material_id, props.row.tk_change_id)" />
              </q-btn>
              <q-btn v-if="props.row.action_type === 1" class="actions__undo" size="sm" dense>
                <i :class="`material-icons`">cancel</i>
                <div class="title">&nbsp;Отменить удаление</div>
                <PopupConfirm
                  :approve="() => this.deleteCancel(this.client_request_id, this.ds_id, props.row.tk_change_id)" />
              </q-btn>
              <q-btn v-if="hasAnyChanges(props.row.index)"
                @click="saveChanges(this.client_request_id, this.ds_id, props.row.client_material_id, props.row.material_set_id, props.row.action_type, props.row.tk_change_id, props.row.material_id, props.row.index)"
                size="sm" class="save-btn">
                {{ countChanges(props.row.index) }} из {{ getItemsCount(props.row.items_json) +
                  1 }} <br>
                Сохранить
              </q-btn>
              <q-btn v-if="hasAnyChanges(props.row.index)" color="primary" @click="resetChanges(props.row.index)"
                size="sm">

                <div class="title"><i :class="`material-icons`">loop</i>&nbsp;Вернуть старые значения</div>
              </q-btn>
            </div>
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>
<script>
import { textConstructorColumns, textConstructorMaterialsColumns } from "@/views/ClientRequests/remontDS/dsCreationTemplates/changeTextConstructor/services";

import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomImg from "@/components/common/CustomImg";
import CustomPopup from "@/components/common/CustomPopup";
import CustomInput from "@/components/form/CustomInput";
import PopupConfirm from "@/components/common/PopupConfirm";
import CollapseBlock from "@/components/common/CollapseBlock";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";

import { appActions } from "@/services/store/modules/app_module/app_actions";
import { addTKWork, addMCWork, modifyCancel, materialDelete, deleteCancel, saveMaterialCnt, getRowClass } from '@/views/ClientRequests/remontDS/dsCreationTemplates/changeTextConstructor/services'
import { MODAL_NAMES } from "@/constants";
import { count } from "d3";

export default {
  name: "TextConstructorChangeBlock",
  components: { CustomImg, CustomTable, CustomTableCell, CustomPopup, PopupConfirm, CollapseBlock, CustomInput },
  props: ['data', 'client_request_id', 'ds_id', 'textConstructorData', 'materialCntData', 'refreshTextConstructor', 'localChanges'],
  data() {
    return {
      loading: false,
      textConstructorColumns,
      textConstructorMaterialsColumns,
      textConstructorData: this.textConstructorData || [],
      collapsedItems: {},
      localChanges: this.localChanges || {},
      materialCntData: this.materialCntData || {},
      rowsWithIndex: '',
      data: null,
      isTKFetching: true,
      title: 'Добавить материал',
      actionTypeMap: {
        1: "Строка удалена из ТК",
        2: "Строка добавлена",
        3: "Строка изменена",
        4: "Изменены кол-ва",
      },
    }
  },
  methods: {
    getRowClass,
    // async getTextConstructor() {
    //   this.isTKFetching = true
    //   const res = await getTextConstructorDsData(this.client_request_id, this.ds_id)
    //   this.isTKFetching = false;
    //   if (!res) return
    //   const { data, materialCntData } = res

    //   this.localChanges = { ...materialCntData }
    //   this.materialCntData = { ...materialCntData }

    //   this.textConstructorData = data;
    // },
    toggleCollapse(index) {
      this.collapsedItems[index] = !this.collapsedItems[index]
    },
    getItemsCount(items) {
      return items?.length || 0;
    },
    async handleAddWorkClick(client_request_id, ds_id) {
      const row = {
        client_request_id,
        ds_id,
      };
      this.$store.commit(appActions.showModal(MODAL_NAMES.TEXT_CONSTRUCTOR_WORKS, {
        onSubmit: (body) => this.onAddWorkSubmit(body),
        title: 'Доп. работы',
        body: row,
      }));
    },
    async handleAddMaterialClick(client_request_id, client_material_id, ds_id, material_id, work_set_id, material_set_id, room_id) {
      const row = {
        client_request_id,
        client_material_id,
        ds_id,
        material_id,
        work_set_id,
        material_set_id,
        room_id
      };
      if (!!client_material_id) { this.title = 'Изменить материал' } else if (!client_material_id) { this.title = 'Добавить материал' }
      this.$store.commit(appActions.showModal(MODAL_NAMES.TEXT_CONSTRUCTOR_MATERIALS, {
        onSubmit: (body) => this.onAddMaterialSubmit(body),
        title: this.title,
        body: row,
      }));
    },
    async onAddWorkSubmit(body) {
      const res = await addTKWork(body)
      if (!res) return
      this.textConstructorData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
      this.refreshTextConstructor()
    },
    async onAddMaterialSubmit(body) {
      const res = await addMCWork(body)
      if (!res) return
      this.textConstructorData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
      this.refreshTextConstructor()
    },
    // async modifyCancel(client_request_id, client_material_id) {
    //   const res = await modifyCancel(client_request_id, client_material_id)
    //   if (!res) return
    //   this.textConstructorData = res
    //   this.refreshTextConstructor()
    //   this.showSucceedSnackbar()
    // },
    async materialDelete(client_request_id, ds_id, client_material_id, tk_change_id) {
      const body = {
        ds_id,
        client_material_id,
        tk_change_id
      }

      const res = await materialDelete(client_request_id, body)
      if (!res) return
      this.textConstructorData = res
      this.refreshTextConstructor()
      this.showSucceedSnackbar()
    },
    async deleteCancel(client_request_id, ds_id, tk_change_id) {
      const body = {
        ds_id,
        tk_change_id
      }
      const res = await deleteCancel(client_request_id, body)
      if (!res) return
      this.textConstructorData = res
      this.refreshTextConstructor()
      this.showSucceedSnackbar()
    },
    hasAnyChanges(rowIndex, parentMode = false, materialId = null) {
      const changes = this.localChanges[rowIndex];

      if (!changes) return false;

      const originalCnt = this.materialCntData[rowIndex]?.material_cnt;
      if (+changes.material_cnt !== originalCnt && !materialId) {
        return true;
      }
      if (parentMode) return false
      const originalItems = this.materialCntData[rowIndex]?.items || {};
      const currentItems = changes.items || {};

      if (!!materialId)
        return +currentItems[materialId] !== originalItems[materialId]
      return Object.keys(currentItems).some((key) => +currentItems[key] !== originalItems[key]);
    },
    handleInnerChange(rowIndex, material_id, newValue) {
      if (!this.localChanges[rowIndex] || !this.localChanges[rowIndex].items) return
      this.localChanges[rowIndex] = { ...this.localChanges[rowIndex], items: { ...this.localChanges[rowIndex].items, [material_id]: newValue } };
    },
    handleChange(rowIndex, newValue) {
      if (!this.localChanges[rowIndex]) return
      this.localChanges[rowIndex] = { ...this.localChanges[rowIndex], material_cnt: newValue }
    },
    async saveChanges(client_request_id, ds_id, client_material_id, material_set_id, action_type, tk_change_id, material_id, rowIndex) {
      const changes = this.localChanges[rowIndex];
      if (!changes || !changes.items) return;
      const materialIds = Object.keys(changes.items).map(Number);
      const materialCounts = Object.values(changes.items).map(Number);

      const body = {
        ds_id,
        client_material_id,
        cnt_material_cnt: changes.material_cnt,
        cnt_material_set_id: material_set_id,
        cnt_action_type: action_type,
        cnt_tk_change_id: tk_change_id,
        cnt_material_id_arr: materialIds,
        cnt_material_cnt_arr: materialCounts,
      };

      const res = await saveMaterialCnt(client_request_id, body);
      if (!res) {
        this.showErrorSnackbar();
        return
      };
      this.refreshTextConstructor();
      await this.rowsWithIndexMethod(res);
      if (this.localChanges[rowIndex].items) {
        this.resetChanges(rowIndex);
      }
      this.showSucceedSnackbar();
    },
    resetChanges(rowIndex) {
      if (this.localChanges[rowIndex]) {
        this.localChanges[rowIndex] = { ...this.materialCntData[rowIndex] };
      }
    },
    countChanges(rowIndex) {
      const originalData = this.materialCntData[rowIndex];
      const currentData = this.localChanges[rowIndex];

      if (!originalData || !currentData) return 0;

      let changesCount = 0;

      if (originalData.material_cnt !== currentData.material_cnt) {
        changesCount++;
      }
      if (originalData.items && currentData.items) {
        Object.keys(currentData.items).forEach((material_id) => {
          if (originalData.items[material_id] !== currentData.items[material_id]) {
            changesCount++;
          }
        });
      }
      return changesCount;
    },
    async rowsWithIndexMethod(response) {
      if (!response) {
        this.rowsWithIndex = this.textConstructorData.map((row, index) => ({ ...row, index }));
      } else {
        this.rowsWithIndex = response.map((row, index) => ({ ...row, index }));
      }
      if (this.rowsWithIndex.length) {
        this.isTKFetching = false;
        this.localChanges = this.materialCntData;
        return this.rowsWithIndex;
      }
    },
  },
  setup() {
    const { showSucceedSnackbar, showErrorSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar, showErrorSnackbar };
  },
  watch: {
    textConstructorData(newVal) {
      if (newVal && newVal.length) {
        this.rowsWithIndexMethod(newVal);
      }
    }
  },
  mounted() {
    this.rowsWithIndexMethod()
  },
  activated() {
    this.rowsWithIndexMethod()
  }
}
</script>

<style scoped lang="scss">
.form {
  &__header {
    background-color: #f5f5f5;
    padding: 15px;

    &_inputs {
      display: grid;
      grid-template-columns: 3fr 2fr 7fr;
      gap: 20px;

      @media (max-width: 768px) {
        grid-template-columns: 12fr;
      }
    }

    &_info {
      margin-top: 20px;

      &>p {
        font-weight: 500;
        margin: 0 0 5px;
      }
    }
  }

  &__content {
    padding: 0 15px;
    margin-top: 10px;

    margin-bottom: 10px;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 3fr 6fr 3fr;
    gap: 20px;

    @media (max-width: 768px) {
      grid-template-columns: 12fr;
    }
  }
}

.manualDsSum {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 3fr 6fr 3fr;
  gap: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: 3fr 9fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 6fr 6fr;
  }
}

.textConstructor {
  &__header {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-bottom: 20px;
  }

  &__photo {
    max-width: 90px;
    max-height: 90px;
  }

  &__sets {
    margin-top: 3px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &_item {
      padding: 5px 8px;
    }

    &_title {
      font-weight: 500;
    }

    &_label {
      color: #3c69b2;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__star {
    font-size: 20px;
    color: #69AA46;
    margin-right: 2px;
  }
}

.customTabs {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}

.actions {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;

  &__right {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  @media (max-width: 350px) {
    &__right {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }

  &__addWork {
    background: gray !important;
    color: #fff;
  }

  &__addMaterial {
    background: green !important;
    color: #fff;
  }

  &__undo {
    background: orange !important;
    color: #fff;
  }

}

.tableBtns {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;
  width: 150px;
  text-align: center;
}

.row-red {
  background-color: rgba(255, 0, 0, 0.3) !important;
}

.custom-table tbody tr.row-red {
  background-color: rgba(255, 0, 0, 0.3) !important;
}

.row-green {
  background-color: rgba(0, 128, 0, 0.2) !important;
}

.custom-table tbody tr.row-green {
  background-color: rgba(0, 128, 0, 0.2) !important;
}

.row-changed {
  background-color: rgba(148, 226, 203, 0.2) !important;
}

.custom-table tbody tr.row-changed {
  background-color: rgba(148, 226, 203, 0.2) !important;
}

.row-orange {
  background-color: rgba(255, 166, 0, 0.5) !important;
}

.custom-table tbody tr.row-orange {
  background-color: rgba(255, 166, 0, 0.5) !important;
}

.row-blue {
  background-color: lightblue !important;
}

.custom-table tbody tr.row-blue {
  background-color: lightblue !important;
}

.highlight-input {
  color: lightblue !important;
  font-weight: 900;
}

.save-btn {
  background-color: green;
  color: white;
}

.reset-btn {
  background-color: orange;
  color: white;
}

.hr.hr-0 {
  margin: 3px 0 !important;
}

.hr {
  display: block;
  height: 0;
  overflow: hidden;
  font-size: 0;
  border-width: 1px 0 0;
  border-bottom: 1px dotted rgba(0, 0, 0, .11);
  margin: 12px 0;
}

.collapseBtn {
  margin: 10px 0;
}
</style>