import { providerRequestsFiltersAPI } from "@/views/ProviderRequests/ProviderRequestsFilters/api";

export const getFiltersMetaData = async () => {
  return await Promise.all([
    providerRequestsFiltersAPI.getMaterialTypes(),
    providerRequestsFiltersAPI.getProviders(),
  ]).catch(() => {
    console.error('filtersMetadata error');    
    return null;
  });
};