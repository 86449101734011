<template>
  <div v-if="rooms.length > 0" class="room-selector">
    <p>Выберите кабинет:</p>
    <div class="room-buttons">
      <q-btn 
        v-for="room in rooms" 
        :key="room.remarket_room_id" 
        :label="room.room_name" 
        :color="selectedRoom === room.remarket_room_id ? 'primary' : 'grey'"
        @click="selectRoom(room.remarket_room_id)"
        :loading="timesLoadingInner === room.remarket_room_id"
      />
    </div>
  </div>
</template>

<script>
import { ref, defineProps, defineEmits } from "vue";

export default {
  name: "RoomSelector",
  props: {
    rooms: {
      type: Array,
      required: true,
    },
    timesLoadingInner: {
      required: true,
    },
  },
  setup(props, { emit }) {
    const selectedRoom = ref(null);
    
    const selectRoom = (roomId) => {
      selectedRoom.value = roomId;
      emit("room-selected", roomId);
    };

    return {
      selectedRoom,
      selectRoom,
    };
  },
};
</script>

<style scoped>

.room-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
</style>