<template>
  <div class="date-selector">
    <div class="header">
      <span color="white" outline class="month-dropdown" @click="toggleExpanded">
        <span class="month-label">{{ displayMonth }} {{ displayYear }}</span>
        <i class="fa-solid fa-angle-up" :class="{ rotated: !expanded }"></i>
      </span>
      <div class="nav-buttons">
        <q-btn icon="chevron_left" flat dense @click="prevPeriod" :disable="!canGoBack" />
        <q-btn icon="chevron_right" flat dense @click="nextPeriod" />
      </div>
    </div>

    <div v-if="expanded" class="calendar-grid">
      <div class="weekdays">
        <span v-for="day in weekdays" :key="day">{{ day }}</span>
      </div>
      <div class="month-days">
        <div v-for="day in monthDays" :key="day.formatted || day.number" class="day-item"
          :class="{ selected: day.formatted === date_selected, disabled: day.disabled || !day.number }"
          @click="day.number && !day.disabled && selectDate(day.formatted)">
          {{ day.number }}
        </div>
      </div>
    </div>

    <div v-if="!expanded" class="week-view">
      <div class="weekdays">
        <span v-for="date in weekDates" :key="date.formatted" class="week-item" :class="{ disabled: date.disabled }"
          @click="!date.disabled && selectDate(date.formatted)">
          <span class="weekday">{{ date.weekday }}</span>
          <span class="day" :class="{ selected: date.formatted === date_selected }">{{ date.day }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DateSelector",
  props: ["selectedDate"],
  data() {
    const today = new Date();
    return {
      date_selected: this.selectedDate || this.getFormattedDate(today),
      expanded: false,
      currentMonth: today.getMonth(),
      currentYear: today.getFullYear(),
      minAllowedDate: this.getMinAllowedDate(), // Store min allowed date
      today: this.getFormattedDate(today),
      months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
      weekdays: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
      weekDates: [],
      canGoBack: false,
    };
  },
  computed: {
    displayMonth() {
      if (this.expanded) {
        return this.months[this.currentMonth]; // Show month from expanded view
      } else {
        if (!this.date_selected) return "";
        const monthIndex = parseInt(this.date_selected.split(".")[1], 10) - 1; // Extract month (1-based index)
        return this.months[monthIndex]; // Show month from selected date
      }
    },
    displayYear() {
      if (this.expanded) {
        return this.currentYear; // Show year from expanded view
      } else {
        if (!this.date_selected) return "";
        return this.date_selected.split(".")[2]; // Show year from selected date
      }
    },
    monthDays() {
      const firstDay = new Date(this.currentYear, this.currentMonth, 1).getDay();
      const totalDays = new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
      const daysArray = [];
      const startOffset = firstDay === 0 ? 6 : firstDay - 1;
      const today = new Date();
      today.setDate(today.getDate() - 1);

      for (let i = 0; i < startOffset; i++) {
        daysArray.push({ number: "", formatted: "" });
      }

      for (let i = 1; i <= totalDays; i++) {
        const date = new Date(this.currentYear, this.currentMonth, i);
        const formatted = this.getFormattedDate(date);
        daysArray.push({
          number: i,
          formatted,
          disabled: date < today, // Disable past dates
        });
      }
      return daysArray;
    },
  },
  watch: {
    date_selected(newDate) {
      this.updateWeekDates(newDate);
      this.updateMonthFromDate(new Date(newDate.split('.').reverse().join('-')));
      this.$emit("filter_change", "date_selected", newDate);
    }
  },
  methods: {
    getFormattedDate(date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },
    getMinAllowedDate() {
      const today = new Date();
      today.setDate(today.getDate() - 1); // Allow today
      return today;
    },
    selectDate(value) {
      this.date_selected = value;
      this.expanded = false;
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
    prevPeriod() {
      if (this.expanded) {
        this.prevMonth();
      } else {
        this.prevWeek();
      }
    },
    nextPeriod() {
      if (this.expanded) {
        this.nextMonth();
      } else {
        this.nextWeek();
      }
    },
    prevMonth() {
      if (this.currentYear === this.minAllowedDate.getFullYear() && this.currentMonth <= this.minAllowedDate.getMonth()) {
        return; // Prevent navigating to past months
      }
      this.currentMonth--;
      if (this.currentMonth < 0) {
        this.currentMonth = 11;
        this.currentYear--;
      }
      this.canGoBack = true; // Enable going back after moving forward
    },
    nextMonth() {
      this.currentMonth++;
      if (this.currentMonth > 11) {
        this.currentMonth = 0;
        this.currentYear++;
      }
      this.canGoBack = true; // Allow going back after navigating forward
    },
    prevWeek() {
      const date = new Date(this.date_selected.split('.').reverse().join('-'));
      if (date <= this.minAllowedDate) return; // Prevent past week navigation
      date.setDate(date.getDate() - 7);
      this.date_selected = this.getFormattedDate(date);
      this.updateWeekDates(this.date_selected);
      this.updateMonthFromDate(date);
      this.canGoBack = true;
    },
    nextWeek() {
      const date = new Date(this.date_selected.split('.').reverse().join('-'));
      date.setDate(date.getDate() + 7);
      this.date_selected = this.getFormattedDate(date);
      this.updateWeekDates(this.date_selected);
      this.updateMonthFromDate(date);
      this.canGoBack = true;
    },
    updateWeekDates(selectedDate) {
      const daysOfWeek = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
      const date = new Date(selectedDate.split('.').reverse().join('-'));
      const startOfWeek = new Date(date);
      startOfWeek.setDate(date.getDate() - startOfWeek.getDay() + (startOfWeek.getDay() === 0 ? -6 : 1));

      this.weekDates = Array.from({ length: 7 }, (_, i) => {
        const newDate = new Date(startOfWeek);
        newDate.setDate(startOfWeek.getDate() + i);
        return {
          formatted: this.getFormattedDate(newDate),
          weekday: daysOfWeek[newDate.getDay()],
          day: newDate.getDate(),
          disabled: newDate <= this.minAllowedDate, // Disable past dates but allow today
        };
      });
      this.canGoBack = this.weekDates.some(date => date.formatted > this.getFormattedDate(this.minAllowedDate));
    },
    updateMonthFromDate(date) {
      this.currentMonth = date.getMonth();
      this.currentYear = date.getFullYear();
      this.canGoBack = date > this.minAllowedDate;
    }
  },
  mounted() {
    this.updateWeekDates(this.date_selected);
  }
};
</script>

<style scoped>
.date-selector {
  text-align: center;
  padding: 10px 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.month-dropdown {
  min-width: 100px;
}

.month-dropdown i.rotated {
  transform: rotate(180deg);
  padding-top: 2px
}

.nav-buttons {
  display: flex;
  gap: 5px;
}

.calendar-grid {
  overflow: hidden;
  padding-top: 10px;
}

.weekdays {
  display: flex;
  justify-content: space-around;
  padding-bottom: 5px;
}

.month-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  justify-items: anchor-center;
}

.day-item {
  padding: 8px;
  text-align: center;
  cursor: pointer;
  width: 35px;
  /* Ensure width and height are the same */
  height: 35px;
  border-radius: 50%;
  /* Ensures a perfect circle */
  transition: background 0.3s ease;
}

.day-item:hover {
  background: #e0e0e0;
}

.day-item.selected {
  background: #c0c0c0;
  color: white;
}

.week-view {
  padding-top: 10px;
}

.week-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.week-item .day {
  width: 35px;
  /* Ensure width and height are the same */
  height: 35px;
  border-radius: 50%;
  /* Ensures a perfect circle */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease;
  padding: 0;
  /* Remove padding if necessary */
}

.week-item .day.selected {
  background: #c0c0c0;
  color: white;
}

.week-item:hover .day {
  background: #e0e0e0;
}

/* Expand transition */
.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.3s ease-in-out;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
}

.month-label {
  font-size: 16px;
  font-weight: bold;
  padding-right: 5px;
}

.day-item.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.week-item .day.selected {
  background: #c0c0c0;
  color: white;
}

.week-item.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
