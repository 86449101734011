import instance from "@/services/api";

export const materialsAPI = {
  async getMaterialsList(params) {
    return await instance()
      .get("/material/read/", { params })
      .then((res) => res?.data);
  },
  async getMaterialZakupData(material_id) {
    return await instance()
      .get(`/material/zakup/${material_id}/`)
      .then((res) => res?.data);
  },
  async getMaterialSmetniyData(material_id) {
    return await instance()
      .get(`/material/smetniy/${material_id}/`)
      .then((res) => res?.data);
  },
  async getMaterialConstructorData(material_id) {
    return await instance()
      .get(`/material/constructor/${material_id}/`)
      .then((res) => res?.data);
  },
  async getMaterialZakupList(material_id) {
    return await instance()
      .get(`/material/provider_name/${material_id}/`)
      .then((res) => res?.data);
  },
  async getMaterialDoorHeights() {
    return await instance()
      .get("/material/additional/")
      .then((res) => res?.data);
  },
  async getMaterialCollections(material_type_id) {
    return await instance()
      .get(`/material/collection/${material_type_id}/`)
      .then((res) => res?.data);
  },
  async getMaterialUnits() {
    return await instance()
      .get("/material/unit/")
      .then((res) => res?.data);
  },
  async updateZakupMaterial(body) {
    return await instance()
      .post("/material/zakup/upd/", body)
      .then((res) => res?.data);
  },
  async updateSmetniyMaterial(body) {
    return await instance()
      .post("/material/smetniy/upd/", body)
      .then((res) => res?.data);
  },
  async updateMaterialThreshold(body) {
    return await instance()
      .post("/material/smetniy/threshold/", body)
      .then((res) => res?.data);
  },
  async updateConstructorMaterial(body) {
    return await instance()
      .post("/material/constructor/upd/", body)
      .then((res) => res?.data);
  },
  async getImages(material_id) {
    return await instance()
      .get(`/material/constructor/${material_id}/picture/`)
      .then((res) => res?.data);
  },
  async getKitchenSpecs(material_id) {
    return await instance()
      .get(`/material/${material_id}/kitchen/`)
      .then((res) => res?.data);
  },
  async uploadKitchenSpecPhoto(body) {
    return await instance()
      .post("/material/material_kitchen_schem_upd/", body)
      .then((res) => res?.data);
  },
  async updKitchenDesc(body) {
    return await instance()
      .post("/material/material_kitchen_desc_upd/", body)
      .then((res) => res?.data);
  },
  async getKitchenDesc(material_kitchen_desc_upd) {
    return await instance()
      .get(`/material/${material_kitchen_desc_upd}/material_kitchen_desc_get/`)
      .then((res) => res?.data);
  },
  async onDeleteKitchenDesc(material_kitchen_desc_id) {
    return await instance()
      .delete(`/material/${material_kitchen_desc_id}/material_kitchen_desc_del`)
      .then((res) => res?.data);
  },
  async getAdditionalImgs(material_id) {
    return await instance()
      .get(`/material/constructor/${material_id}/photo/`)
      .then((res) => res?.data);
  },
  async getAdditionalPhoto(material_photo_id) {
    return await instance()
      .get(`/material/constructor/${material_photo_id}/get_material_photo/`)
      .then((res) => res?.data);
  },
  async updAddImage(body) {
    return await instance()
      .post(`/material/constructor/upd_material_photo/`, body)
      .then((res) => res?.data);
  },
  async onDeleteAdditionalPhoto(material_photo_id) {
    return await instance()
      .delete(`/material/constructor/${material_photo_id}/del_material_photo/`)
      .then((res) => res?.data);
  },
  async getPresetsOptions() {
    return await instance()
      .get("/common/constructor/preset/")
      .then((res) => res?.data);
  },
  async getRoomPictures(room_picture_id) {
    return await instance()
      .get(`/material/${room_picture_id}/get_room_picture/`)
      .then((res) => res?.data);
  },
  async getRoomsOptions() {
    return await instance()
      .get("/common/constructor/room/")
      .then((res) => res?.data);
  },
  async getRakursOptions(body) {
    return await instance()
      .post("/material/read_room_base_photo/", body)
      .then((res) => res?.data);
  },
  async getMaterialSets(material_id) {
    return await instance()
      .get(`/material/${material_id}/read_material_set/`)
      .then((res) => res?.data);
  },
  async getWorkSetsForPic(params) {
    return await instance()
      .get("/material/work_set_for_pic_read/", { params })
      .then((res) => res?.data);
  },
  async getParentMaterial(body) {
    return await instance()
      .post("/material/material_for_pic_read/", body)
      .then((res) => res?.data);
  },
  async getParentMaterialSets(body) {
    return await instance()
      .post("/material/material_set_for_pic_read/", body)
      .then((res) => res?.data);
  },
  async updRoomPicture(body) {
    return await instance()
      .post("/material/upd_room_picture/", body)
      .then((res) => res?.data);
  },
  async udpateIndex(body) {
    return await instance()
      .post("/material/set_index_arr/", body)
      .then((res) => res?.data);
  },
  async onDeleteRoomPicture(room_picture_id) {
    return await instance()
      .delete(`/material/${room_picture_id}/del_room_picture/`)
      .then((res) => res?.data);
  },

  async getMaterialProperties(material_id) {
    return await instance()
      .get(`/material/constructor/${material_id}/material_prop__read/`)
      .then((res) => res?.data);
  },
  async getMaterialCategories() {
    return await instance()
      .get("/material/constructor/material_category_fs/")
      .then((res) => res?.data);
  },
  async updateMaterialCategory(body) {
    return await instance()
      .post("/material/constructor/material_prop__upd_category/", body)
      .then((res) => res?.data);
  },
  async getPropertyValue(material_prop_value_id) {
    return await instance()
      .get(
        `/material/constructor/${material_prop_value_id}/material_prop__get/`
      )
      .then((res) => res?.data);
  },
  async getPropertiesByCategory(material_id) {
    return await instance()
      .get(
        `/material/constructor/${material_id}/material_prop__get_prop_list_by_category/`
      )
      .then((res) => res?.data);
  },
  async readPropertyDictionary(body) {
    return await instance()
      .post("/material/constructor/material_prop__read_prop_dict/", body)
      .then((res) => res?.data);
  },
  async updatePropertyValue(body) {
    return await instance()
      .post("/material/constructor/material_prop__upd_prop_value/", body)
      .then((res) => res?.data);
  },
  async deletePropertyValue(material_prop_value_id) {
    return await instance()
      .delete(
        `/material/constructor/${material_prop_value_id}/material_prop__del_prop_value/`
      )
      .then((res) => res?.data);
  },
  async getMaterialInfo(material_id) {
    return await instance()
      .get(`/material/${material_id}/info/`)
      .then((res) => res?.data);
  },
};
