<!-- <fieldset class="filters__invoice">
              <legend>Накладные</legend> -->

<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">
      <!-- FF Маржа -->
      <div class="form__item full-width">
        <q-banner class="info-banner">
          <strong>Маржа FF (%):</strong> {{ material_marzha }}
        </q-banner>
      </div>

      <!-- Блок цен -->
      <div class="prices-container">
        <fieldset class="prices-box">
          <legend>Цены в местной валюте</legend>
          <CustomInput
            label="Цена (розничная)"
            type="number"
            :value="formData.price"
            @change="(value) => onChange('price', value)"
          />
          <CustomInput
            label="Цена (оптовая)"
            type="number"
            :value="formData.price_whole"
            @change="(value) => onChange('price_whole', value)"
          />
          <CustomInput
            label="Цена (накладная)"
            type="number"
            :value="formData.invoice_price"
            @change="(value) => onChange('invoice_price', value)"
          />
        </fieldset>

        <fieldset class="prices-box">
          <legend>Цены в валюте</legend>
          <CustomInput
            label="Цена (розничная)"
            type="number"
            :value="formData.currency_price"
            @change="(value) => onChange('currency_price', value)"
          />
          <CustomInput
            label="Цена (оптовая)"
            type="number"
            :value="formData.currency_price_whole"
            @change="(value) => onChange('currency_price_whole', value)"
          />
          <CustomInput
            label="Цена (накладная)"
            type="number"
            :value="formData.currency_invoice_price"
            @change="(value) => onChange('currency_invoice_price', value)"
          />
        </fieldset>
      </div>
      <!-- Даты -->
      <div class="dates-container">
        <span class="date">
          <legend>Дата с</legend>
          <CustomDate
            label="Дата с"
            required
            :value="formData.date_begin"
            @change_date="(value) => onChange('date_begin', value)"
          />
        </span>
        <span class="date">
          <legend>Дата по</legend>
          <CustomDate
            label="Дата по"
            required
            :value="formData.date_end"
            @change_date="(value) => onChange('date_end', value)"
          />
        </span>
      </div>

      <!-- Комментарии -->
      <CustomInput
        label="Комментарии"
        type="textarea"
        :value="formData.price_comment"
        @change="(value) => onChange('price_comment', value)"
      />

      <!-- Кнопка сохранить -->
      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomDate from "@/components/form/CustomDate";
import CustomInput from "@/components/form/CustomInput";

export default {
  name: "material-list-add-price",
  components: { CustomModalFooter, CustomDate, CustomInput },
  props: ["data"],
  data() {
    return {
      loading: false,
      material_marzha: this.data.body.material_marzha || "",
      formData: {
        material_provider_id: this.data.body?.material_provider_id || "",
        // material_provider_id_arr_:
        // this.data.body?.material_provider_id_arr_ || "",
        price: "",
        price_whole: "",
        invoice_price: "",
        currency_price: "",
        currency_price_whole: "",
        currency_invoice_price: "",
        date_begin: new Date().toLocaleDateString("ru"),
        date_end: "",
        price_comment: "",
      },
    };
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value;
    },
    async onSubmit() {
      this.loading = true;
      await this.data?.onSubmit(this.formData);
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 600px;
}

/* Инфо-баннер */
.info-banner {
  background-color: #e3f2fd;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
}

/* Контейнер цен */
.prices-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.prices-box {
  flex: 1;
  min-width: 250px;
  border: 1px solid #000;
  padding: 10px;
  border-radius: 5px;
}

/* Даты */
.dates-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.date {
  flex: 1;
  min-width: 250px;
  padding: 10px;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .prices-container {
    flex-direction: column;
  }
}
</style>
