<template>
  <MaterialEdit
    v-if="this.isEdit"
    @close="this.handleCloseClick"
    :materialId="this.temp_material_id"
    :materialTypes="materialTypes"
  />
  <div v-if="!this.isEdit">
    <CustomTable
      title="Список материалов"
      :rowsData="this.data"
      :columns="this.materialsCommonColumns"
      :loading="isFetching"
      :show-search="true"
      :custom-body="true"
      :sticky-header="true"
      :showHeaderAdd="computedHeaderAdd"
      :on-add-click="this.handleUpdateClick"
    >
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="center">
            {{ props.row.material_id }}
          </custom-table-cell>
          <custom-table-cell align="left">
            <Clickable
              @click="() => this.handleUpdateClick(props.row.material_id)"
            >
              {{ props.row.material_name }}
            </Clickable>
            <br />
            <br />
            <span style="color: #666666" v-if="props.row.material_name_extra">
              У поставщика: <br /><span
                v-html="`${props.row.material_name_extra}`"
              ></span>
            </span>
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.material_type_name }}
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.sell_unit_name }}
            <i>(k = {{ props.row.koef_sell_to_delivery }})</i>
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.consume_unit_name }}
            <i>(k = {{ props.row.koef_consume_to_delivery }})</i>
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.delivery_unit_name }}
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.atom_unit_name }}
            <i>(k = {{ props.row.koef_atom_to_delivery }})</i>
          </custom-table-cell>
          <custom-table-cell align="center">
            <CheckOrClose :value="props.row.is_koef_round" />
          </custom-table-cell>
          <custom-table-cell align="center">
            <CheckOrClose :value="props.row.is_active" />
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.details }}
            <!-- {{ props.row.info_block }} -->
            <template v-if="props.row.is_contractor_measure === 1">
              <span class="label label-success label-white middle">
                Требуется внести замер дизайнером
              </span>
            </template>
            <template v-if="props.row.is_design_measure === 1">
              <span class="label label-success label-white middle">
                Корректировка подрядчиком
              </span>
            </template>
            <template v-if="props.row.photo_url_orig">
              <span class="label label-success label-white middle">
                Фотография загружена
              </span>
            </template>
          </custom-table-cell>
          <custom-table-cell align="center">
            <CustomIcon
                icon="info"
                :hoverMode="true"
                green
                @click="() => this.handleInfoClick(props.row.material_id)"
              />
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import BlockItem from "@/components/common/BlockItem";
import CustomIcon from "@/components/common/CustomIcon";
import Clickable from "@/components/common/Clickable";
import CheckOrClose from "@/components/common/CheckOrClose";

import { appActions } from "@/services/store/modules/app_module/app_actions";
import { MODAL_NAMES } from "@/constants";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";
import {
  materialsCommonColumns,
} from "@/views/Materials/services";

import MaterialEdit from "@/views/Materials/MaterialsList/MaterialEdit";

export default {
  name: "MaterialsList",
  props: {
    data: { type: Array, default: () => [] },
    materialTypes: { type: Array, default: () => [] },
    whichTab: { type: String, default: "zakup" },
    tabLabel: { type: String, default: "Отдел закупа" },
    getTableData: { type: Function, default: () => {} },
  },
  components: {
    CustomTable,
    CustomTableCell,
    BlockItem,
    CustomIcon,
    Clickable,
    CheckOrClose,
    MaterialEdit,
  },
  data() {
    return {
      materialsCommonColumns,
      data: this.data,
      isFetching: false,
      loading: false,
      isEdit: false,
      temp_material_id: 0,
      whichTab: this.whichTab,
      tabLabel: this.tabLabel,
    };
  },
  methods: {
    async handleUpdateClick(material_id) {
      this.temp_material_id = material_id;
      this.isEdit = true;
      this.$emit("toggle-update", true);
    },
    async handleCloseClick() {
      this.isEdit = false;
      this.$emit("toggle-update", false);
      this.getTableData();
    },
    async handleInfoClick(material_id) {
      this.$store.commit(
        appActions.showModal(
          MODAL_NAMES.MATERIALS_INFO,
          {
            title: "Полная информация по материалу",
            body: {
              material_id: material_id,
            },
          }
        )
      );
    },
  },
  computed: {
    computedColumns() {
      switch (this.whichTab) {
        case "zakup":
          return materialsZakupColumns;
        case "smetniy":
          return materialsSmetnyiColumns;
        case "constructor":
          return materialsConstructorColumns;
        case "baseOp":
          return []; // Add when baseOp columns are available
        default:
          return materialsZakupColumns;
      }
    },
    computedHeaderAdd() {
      if (this.whichTab === "zakup") {
        return true;
      } else {
        return false;
      }
    },
  },
  setup() {
    const { showSucceedSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar };
  },
};
</script>

<style>
.label {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  background-color: #e8f5e9;
  color: #2e7d32;
  border: 1px solid #c8e6c9;
}

.label-success {
  background-color: #e8f5e9;
  color: #2e7d32;
  border: 1px solid #c8e6c9;
}

.label-white {
  color: #2e7d32;
}

.label.middle {
  vertical-align: middle;
}
</style>
