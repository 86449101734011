<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__content">
        <div class="uploader-header">Доп. фото материала:</div>
        <CustomUploader
          :value="this.formData.photo"
          :on-change="(value) => onChange('photo', value)"
          label="Выберите файл"
        />
      </div>
      <div class="form__content">
        <CustomInput
          type="number"
          :controls="true"
          :showClearIcon="false"
          :required="true"
          label="Порядковый номер"
          :value="this.formData.order_num"
          @change_input="(value) => onChange('order_num', value)"
          :style="{ width: '320px' }"
        />
      </div>
      <CustomModalFooter :btn-loading="this.btnLoading" :disable-submit="!this.formData.material_photo_id && !this.formData.photo" :title="computedTitle" />
    </q-form>
  </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomInput from "@/components/form/CustomInput";
import CustomUploader from "@/components/form/CustomUploader";
import Clickable from "@/components/common/Clickable";

import { getAdditionalPhoto } from "@/views/Materials/MaterialsList/MaterialEdit/services";

export default {
  name: "materials-constructor-upd-additional-imgs",
  components: { CustomModalFooter, CustomInput, CustomUploader, Clickable },
  props: ["data"],
  data() {
    return {
      btnLoading: false,
      formData: {
        material_photo_id: this.data?.body.material_photo_id || "",
        photo: "",
        order_num: this.data?.body.order_num || "",
        material_id: this.data?.body.material_id || "",
      },
    };
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value;
    },
    async getData() {
      this.btnLoading = true;
      const res = await getAdditionalPhoto(this.formData.material_photo_id);
      this.btnLoading = false;
      if (!res) return;
      this.formData = res;
    },
    async onSubmit() {
      this.btnLoading = true;
      if (!this.formData.material_photo_id && !this.formData.photo) {
        this.$q.notify({
          message: "Загрузите фото",
          color: "warning",
        });
        this.btnLoading = false;
        return;
      }
      const body = {
        order_num: this.formData.order_num,
        material_photo_id: this.formData.material_photo_id,
        material_id: this.formData.material_id,
        photo: this.formData.photo || "",
      };
      await this.data?.onSubmit(body);
      this.btnLoading = false;
    },
  },
  mounted() {
    if (this.data?.body.material_photo_id) {
      this.getData();
    }
  },
  computed: {
    computedTitle() {
      if (this.formData.material_photo_id) {
        return "";
      }
      return !this.formData.photo ? "Загрузите фото перед сохранением" : "";
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  display: grid;
  min-width: 350px;
  grid-template-columns: 12fr;
  gap: 20px;

  @media (max-width: 700px) {
    min-width: 50%;
    max-width: 100%;
    grid-template-columns: 12fr;
  }

  &__content {
    padding: 0 15px;
    margin-top: 10px;

    &_item {
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}
.uploader-header {
  margin-bottom: 12px;
}
</style>
