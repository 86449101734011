<template>
  <CustomTable
    :title="`Фотографии материала ${
      !!getSelectedLength() ? ` (Выбрано: ${getSelectedLength()})` : ''
    }`"
    :rowsData="this.data"
    :columns="constructorImagesColumns"
    :loading="loading"
    :custom-body="true"
    :sticky-header="true"
    :showSearch="
      Object.keys(selectedItems).length === 0 ||
      Object.values(selectedItems).some((value) => value === false)
    "
    checkbox="true"
    :checkbox-value="this.allSelected"
    :on-all-check-click="onAllCheckClick"
    :showHeaderAdd="
      Object.keys(selectedItems).length === 0 ||
      Object.values(selectedItems).some((value) => value === false)
    "
    :on-add-click="this.handleUpdate"
  >
    <template
      #top-right
      v-if="Object.values(selectedItems).some((value) => value === true)"
    >
      <div class="actions_left">
        <CustomInput
          :value="temp_index"
          label="Индекс"
          :clearable="false"
          type="number"
          :controls="true"
          :showClearIcon="false"
          @change_input="(value) => onChangeIndex('temp_index', value)"
          style="width: 100px"
        />
        <q-btn color="secondary" size="sm" @click="this.submitIndex()">
          <span>Сохранить</span>
        </q-btn>
      </div>
    </template>
    <template #customBody="props">
      <q-tr>
        <custom-table-cell>
          <CustomCheckbox
            :value="!!this.selectedItems[props.row.room_picture_id]"
            @change="
              (value) => onCheckboxChange(props.row.room_picture_id, value)
            "
          />
        </custom-table-cell>
        <template v-for="column in constructorImagesColumns" :key="column.name">
          <custom-table-cell :align="column.alignTable || 'left'">
            <template v-if="column.name === 'room_name'">
              <Clickable @click="() => this.handleUpdate(props.row)">
                {{ props.row[column.field] }}
              </Clickable>
            </template>
            <template v-else-if="column.name === 'image_url'">
              <Clickable :href="props.row[column.field]" link>
                Посмотреть
              </Clickable>
            </template>
            <template v-else-if="column.name === 'dlt'">
              <CustomIcon
                icon="trash"
                :approve-mode="true"
                :loading="this.delBtnLoading[props.row?.room_picture_id]"
                :approve="() => this.onDelete(props.row.room_picture_id)"
              />
            </template>
            <template v-else>
              {{ props.row[column.field] }}
            </template>
          </custom-table-cell>
        </template>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import BlockItem from "@/components/common/BlockItem";
import CustomIcon from "@/components/common/CustomIcon";
import Clickable from "@/components/common/Clickable";
import CustomInput from "@/components/form/CustomInput";
import CustomCheckbox from "@/components/form/CustomCheckbox";

import { appActions } from "@/services/store/modules/app_module/app_actions";
import { MODAL_NAMES } from "@/constants";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";
import {
  constructorImagesColumns,
  getImages,
  updRoomPicture,
  udpateIndex,
  onDeleteRoomPicture,
} from "@/views/Materials/MaterialsList/MaterialEdit/services";

export default {
  name: "ImageList",
  props: {
    materialId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    CustomTable,
    CustomTableCell,
    BlockItem,
    CustomIcon,
    Clickable,
    CustomInput,
    CustomCheckbox,
  },
  data() {
    return {
      constructorImagesColumns,
      data: [],
      material_id: this.materialId,
      loading: false,
      delBtnLoading: {},
      temp_material_id: 0,
      temp_index: "",
      searchTerm: "",
      allSelected: false,
      selectedItems: {},
    };
  },
  watch: {
    selectedItems(newValue) {
      const checkedLength = this.getSelectedLength(newValue);
      const isAllChecked = this?.data.length === checkedLength;
      if (isAllChecked && !!checkedLength) return (this.allSelected = true);
      if (checkedLength > 0) return (this.allSelected = "");
      this.allSelected = false;
    },
  },
  methods: {
    async getData() {
      this.loading = true;
      const res = await getImages(this.material_id);
      this.loading = false;
      if (!res) return;
      this.data = res;
    },
    async handleUpdate(row) {
      let data = {
        material_id: this.material_id,
      };
      if (row) {
        data = {
          room_picture_id: row.room_picture_id,
          material_id: this.material_id,
          preset_id: row.preset_id,
          room_id: row.room_id,
          rakurs_id: row.rakurs_id,
          material_set_id: row.material_set_id,
          work_set_id: row.work_set_id,
          material_pid: row.material_pid,
          material_set_pid: row.material_set_pid,
          order_index: Number(row.order_index),
        };
      }
      this.$store.commit(
        appActions.showModal(MODAL_NAMES.MATERIALS_CONSTRUCTOR_UPD_IMAGES, {
          onSubmit: (body) => this.handleUpdateSubmit(body),
          title: "Фотографии по комнате",
          body: data,
        })
      );
    },
    async handleUpdateSubmit(body) {
      const res = await updRoomPicture(body);
      if (!res) return;
      this.data = res;
      this.showSucceedSnackbar();
      this.$store.commit(appActions.closeModal());
    },
    async submitIndex() {
      const payload = {
        room_picture_id_arr: Object.keys(this.selectedItems).map((key) =>
          Number(key)
        ),
        order_index: Number(this.temp_index),
      };
      const res = await udpateIndex(payload);
      if (!res) return;
      // this.data = res;
      this.showSucceedSnackbar();
    },
    async handleCloseClick() {
      this.$emit("toggle-update", false);
    },
    onChangeIndex(field, value) {
      this[field] = value;
    },
    onAllCheckClick(value) {
      this.allSelected = value;
      this.selectedItems = this.data.reduce((acc, item) => {
        acc[item.room_picture_id] = value;
        return acc;
      }, {});
    },
    getSelectedLength(selectedItems) {
      if (selectedItems)
        return Object.keys(selectedItems).filter(
          (item) => !!selectedItems[item]
        ).length;
      return Object.keys(this.selectedItems).filter(
        (item) => !!this.selectedItems[item]
      ).length;
    },
    onCheckboxChange(room_picture_id, value) {
      this.selectedItems = {
        ...this.selectedItems,
        [room_picture_id]: value,
      };
    },
    resetSelectedItems() {
      this.selectedItems = {};
    },
    async onDelete(room_picture_id) {
      this.delBtnLoading = {
        ...this.delBtnLoading,
        [room_picture_id]: true,
      };
      const res = await onDeleteRoomPicture(room_picture_id);
      this.delBtnLoading = {
        ...this.delBtnLoading,
        [room_picture_id]: false,
      };
      if (!res) return;
      this.data = this.data.filter(
        (item) => item.room_picture_id !== room_picture_id
      );
      this.showSucceedSnackbar();
    },
  },
  async mounted() {
    await this.getData();
  },
  computed: {
    computedHeaderAdd() {
      if (this.whichTab === "zakup") {
        return true;
      } else {
        return false;
      }
    },
  },
  setup() {
    const { showSucceedSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar };
  },
};
</script>

<style scoped lang="scss">
.actions_left {
  display: flex;
  gap: 10px;
  align-items: center;
}
</style>
