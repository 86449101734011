<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">

      <div class="form__content">
        <CustomSelect :options="warehouseOptions" label="Склад" :required="true" optionValueKey="warehouse_id"
          optionLabelKey="warehouse_name" :fullWidth="true" :value="this.formData.warehouse_id"
          @change="(value) => onChange('warehouse_id', value)" />
      </div>

      <CustomModalFooter :btn-loading="this.btnLoading" />
    </q-form>
  </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import { getWarehousesForRelease } from "@/views/ProviderRequests/services";

export default {
  name: "material-list-set-requirement",
  components: { CustomModalFooter, CustomSelect },
  props: ['data'],
  data() {
    return {
      loading: false,
      btnLoading: false,
      formData: {
        material_provider_id_arr: this.data?.body.material_provider_id_arr || '',
        is_required_create: 1,
        warehouse_id: '',
      },
      warehouseOptions: [],
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value;
    },
    async onSubmit() {
      this.btnLoading = true
      await this.data?.onSubmit(this.formData)
      this.btnLoading = false
    },
    async fetchWarehouses() {
      this.warehouseOptions = await getWarehousesForRelease();
    },
  },
  mounted() {
    this.fetchWarehouses();
  }
}
</script>

<style scoped lang="scss">
.form {
  display: grid;
  min-width: 550px;
  grid-template-columns: 12fr;
  gap: 20px;

  @media (max-width: 991px) {
    min-width: 50%;
    max-width: 100%;
    grid-template-columns: 12fr;
  }

  &__content {
    padding: 0 15px;
    margin-top: 10px;

    &_item {
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}

</style>