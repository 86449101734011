<template>
  <CollapseBlock>
    <form @submit.prevent="this.onSearch">
      <div class="filters">
        <!-- Голубой блок -->
        <div class="filters__block filters__block--blue">
          <FiltersWrapper>
            <CustomDate
              placeholder="План отгрузки с"
              :value="this.values.date_shipping_plan_begin || ''"
              @change_date="(value) => onChange('date_shipping_plan_begin', value)"
            />
          </FiltersWrapper>
          <FiltersWrapper>
            <CustomDate
              placeholder="План отгрузки по"
              :value="this.values.date_shipping_plan_end || ''"
              @change_date="(value) => onChange('date_shipping_plan_end', value)"
            />
          </FiltersWrapper>
          <div class="filters__item">
            <CustomSelect
              :options="this.providers"
              label="Поставщик"
              optionValueKey="provider_id"
              optionLabelKey="provider_label"
              :value="this.values.provider_id"
              @change="(value) => onChange('provider_id', value)"
            />
          </div>
          <div class="filters__item">
            <CustomSelect
              :options="this.residents"
              label="ЖК"
              optionValueKey="resident_id"
              optionLabelKey="resident_name"
              :value="this.values.resident_id"
              @change="(value) => onChange('resident_id', value)"
            />
          </div>
          <div class="filters__item">
            <CustomSelect
              :options="this.remontStatuses"
              label="Статус ремонта"
              optionValueKey="value"
              optionLabelKey="label"
              :value="this.values.remont_status_id"
              @change="(value) => onChange('remont_status_id', value)"
              :clearable="false"
            />
          </div>
        </div>

        <!-- Синий блок -->
        <div class="filters__block filters__block--dark-blue">
          <div class="filters__item">
            <CustomSelect
              :options="this.materialsOptions"
              label="Материал"
              optionValueKey="material_id"
              optionLabelKey="material_name"
              :value="this.values.material_id"
              @change="(value) => onChange('material_id', value)"
              :noOptionText="this.noOptionText"
            />
          </div>
          <div class="filters__item">
            <CustomSelect
              :options="this.materialTypes"
              label="Тип материала"
              optionValueKey="material_type_id"
              optionLabelKey="material_type_name"
              :value="this.values.material_type_id"
              @change="(value) => onChange('material_type_id', value)"
            />
          </div>
          <div class="filters__item">
            <CustomSelect
              :options="this.isDirty"
              label="Признак материала"
              optionValueKey="value"
              optionLabelKey="label"
              :value="this.values.is_dirty"
              @change="(value) => onChange('is_dirty', value)"
              :clearable="false"
            />
          </div>
          <div class="filters__item">
            <CustomSelect
              :options="this.requestType"
              label="Откуда отгрузка"
              optionValueKey="value"
              optionLabelKey="label"
              :value="this.values.request_type"
              @change="(value) => onChange('request_type', value)"
              :clearable="false"
            />
          </div>
        </div>

        <!-- Зеленый блок -->
        <div class="filters__block filters__block--green">
          <div class="filters__item">
            <CustomInput
              type="number"
              :controls="true"
              label="ID заявки"
              :value="this.values.provider_request_id_search"
              @clear_input="onChange('provider_request_id_search', '')"
              @change="(value) => onChange('provider_request_id_search', value)"
            />
          </div>
          <div class="filters__item">
            <CustomInput
              type="number"
              :controls="true"
              label="ID ремонта"
              :value="this.values.remont_id"
              @clear_input="onChange('remont_id', '')"
              @change="(value) => onChange('remont_id', value)"
            />
          </div>
          <div class="filters__item">
            <CustomSelect
              :multiple="true"
              :options="this.statuses"
              label="Статусы"
              optionValueKey="provider_request_status_id"
              optionLabelKey="provider_request_status_name"
              :value="this.values.provider_request_status_id_arr"
              @change="(value) => onChange('provider_request_status_id_arr', value)"
            />
          </div>
          <div class="filters__item">
            <CustomSelect
              :options="this.providerRequestTypes"
              label="Тип заявки поставщику"
              optionValueKey="provider_request_type_id"
              optionLabelKey="provider_request_type_name"
              :value="this.values.provider_request_type_id"
              @change="(value) => onChange('provider_request_type_id', value)"
            />
          </div>
        </div>

        <!-- Желтый блок -->
        <div class="filters__block filters__block--yellow">
          <div class="filters__item">
            <CustomSelect
              :options="this.invoiceType"
              label="Тип инвойса"
              optionValueKey="value"
              optionLabelKey="label"
              :value="this.values.invoice_receive_org_type"
              @change="(value) => onChange('invoice_receive_org_type', value)"
              :clearable="false"
            />
          </div>
          <div class="filters__item">
            <CustomSelect
              :options="this.isInvoiceExists"
              label="Наличие накладной"
              optionValueKey="value"
              optionLabelKey="label"
              :value="this.values.is_exist_invoice"
              @change="(value) => onChange('is_exist_invoice', value)"
              :clearable="false"
            />
          </div>
          <div class="filters__item">
            <CustomSelect
              :options="this.isInvoiceCarried"
              label="Проведен"
              optionValueKey="value"
              optionLabelKey="label"
              :value="this.values.is_invoice_carried"
              @change="(value) => onChange('is_invoice_carried', value)"
              :clearable="false"
            />
          </div>
          <div class="filters__item">
            <fieldset class="filters__invoice">
              <legend>Накладные</legend>
              <div style="width: 150px;">
                <CustomSelect
                  :options="this.daysCnt"
                  label="Кол-во дней"
                  optionValueKey="days_cnt"
                  optionLabelKey="days_cnt"
                  :value="this.values.days_cnt"
                  @change="(value) => onChange('days_cnt', value)"
                />
              </div>
              <CustomCheckbox
                :value="this.values.is_no_invoice"
                label="Нет накладных"
                :binary="true"
                @change="(value) => onChange('is_no_invoice', value)"
              />
            </fieldset>
          </div>
        </div>
      </div>

      <q-btn
        type="submit"
        :loading="this.isFetching"
        size="md"
        no-wrap
        color="primary"
        class="q-btn"
        label="Поиск"
        icon="search"
      />

      <q-btn
        @click="this.onPrint"
        label="Печать"
        icon="print"
        size="md"
        color="primary"
        class="print"
      />
    </form>
  </CollapseBlock>
</template>

<script>
import { getFiltersMetaData, remontStatuses, isDirty, requestType, invoiceType, isInvoiceExists, isInvoiceCarried } from "./services";
import CollapseBlock from "@/components/common/CollapseBlock";
import CustomSelect from "@/components/form/CustomSelect";
import CustomInput from "@/components/form/CustomInput";
import CustomDate from "@/components/form/CustomDate";
import FiltersWrapper from "@/components/common/FiltersWrapper";
import CustomCheckbox from "@/components/form/CustomCheckbox";


export default {
  name: "RemontRealizationsFilters",
  components: { CustomSelect, CollapseBlock, CustomInput, CustomDate, FiltersWrapper, CustomCheckbox },
  props: ['values', 'isFetching', 'getData', 'setMaterialsOptions', 'materialsOptions', 'noOptionText', 'onPrintProvReq'],
  data() {
    return {
      providers: [],
      residents: [],
      noOptionText: this.noOptionText || 'Не найдено',
      materialTypes: [],
      providerRequestTypes: [],
      statuses: [],
      daysCnt: [],
      remontStatuses, isDirty, requestType, invoiceType, isInvoiceExists, isInvoiceCarried,
    }
  },
  methods: {
    onChange(type, value) {
      this.$emit('filter_change', type, value);
    },
    onSearch() {
      this.getData()
    },
    onPrint() {
      this.onPrintProvReq()
    }
  },

  async mounted() {
    const res = await getFiltersMetaData();
    const [
      providersResponse,
      residentsResponse,
      materialTypesResponse,
      requestTypesResponse,
      statusesResponse,
      daysCntResponse,
    ] = res;
    this.providers = providersResponse.data.map(item => ({
      provider_id: item.id, // Или другой ключ, который представляет уникальный идентификатор
      provider_name: item.provider_name,
      provider_label: `${item.id} / ${item.provider_name}`,
    }));
    this.residents = residentsResponse.data
    this.materialTypes = materialTypesResponse.data
    this.providerRequestTypes = requestTypesResponse.data
    this.statuses = statusesResponse.data
    this.daysCnt = daysCntResponse.data
    if (!res) return;
  },
}
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__invoice {
    display: flex;
    padding: 10px;
  }

  fieldset {
    border: 0.1px solid black;
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 15px;
    border-radius: 8px;

    &--blue {
      background-color: #E3F2FD;
    }

    &--dark-blue {
      background-color: #BBDEFB;
    }

    &--green {
      background-color: #C8E6C9;
    }

    &--yellow {
      background-color: #FFF9C4;
    }
  }

  &__item {
    flex: 1 1 220px;
  }
}

.q-btn {
  margin-top: 10px;
  align-self: flex-start;
}

.print {
  margin-left: 10px;
}
</style>
