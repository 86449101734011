<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__content">
        <CustomSelect
          :options="presetsOptions"
          label="Пресет"
          :required="true"
          optionValueKey="preset_id"
          optionLabelKey="preset_and_city"
          :fullWidth="true"
          :value="this.formData.preset_id"
          @change="(value) => onChange('preset_id', value)"
        />
      </div>
      <div class="form__content">
        <CustomSelect
          :options="roomsOptions"
          label="Комната"
          :required="true"
          optionValueKey="room_id"
          optionLabelKey="room_name"
          :fullWidth="true"
          :value="this.formData.room_id"
          @change="(value) => onChange('room_id', value)"
        />
      </div>
      <div class="form__content">
        <CustomSelect
          :options="rakursOptions"
          label="Ракурс"
          optionValueKey="rakurs_id"
          optionLabelKey="rakurs_name"
          :fullWidth="true"
          :value="this.formData.rakurs_id"
          @change="(value) => onChange('rakurs_id', value)"
        />
      </div>

      <div class="form__content">
        <CustomSelect
          :options="materialSetsOptions"
          label="Набор материалов"
          optionValueKey="material_set_id"
          optionLabelKey="material_set_name"
          :fullWidth="true"
          :value="this.formData.material_set_id"
          @change="(value) => onChange('material_set_id', value)"
          />
        </div>
        <div class="form__content">
          <CustomUploader 
          :value="formData.room_photo"
          :on-change="(value) => onChange('room_photo', value)"
          label="Фотография"
          :fullWidth="true"
        />
      </div>
      <div class="form__content">
        <CustomInput
          :value="this.formData.order_index"
          type="number"
          :controls="true"
          :showClearIcon="false"
          label="Индекс"
          @change_input="(value) => onChange('order_index', value)"
          :fullWidth="true"
        />
      </div>
      <div class="form__content">
        <CustomSelect
          :options="workSetsOptions"
          label="Ворксет"
          optionValueKey="work_set_id"
          optionLabelKey="work_set_name"
          :fullWidth="true"
          :value="this.formData.work_set_id"
          @change="(value) => onChangeWorkSet('work_set_id', value)"
        />
      </div>

      <div class="form__content">
        <CustomSelect
          :options="parentMaterialOptions"
          label="Материал (Родитель)"
          optionValueKey="material_id"
          optionLabelKey="material_name"
          :fullWidth="true"
          :value="this.formData.material_pid"
          @change="(value) => onChangeWorkSet('material_pid', value)"
        />
      </div>
      <div class="form__content">
        <CustomSelect
          :options="parentMaterialSetsOptions"
          label="Набор материала (Родитель)"
          optionValueKey="material_set_id"
          optionLabelKey="set_name"
          :fullWidth="true"
          :value="this.formData.material_set_pid"
          @change="(value) => onChange('material_set_pid', value)"
        />
      </div>

      <CustomModalFooter :btn-loading="this.btnLoading" />
    </q-form>
  </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import CustomInput from "@/components/form/CustomInput";
import CustomUploader from "@/components/form/CustomUploader";
import {
  getRoomPictures,
  getPresetsOptions,
  getRoomsOptions,
  getRakursOptions,
  getMaterialSets,
  getWorkSetsForPic,
  getParentMaterial,
  getParentMaterialSets,
} from "@/views/Materials/MaterialsList/MaterialEdit/services";

export default {
  name: "materials-constructor-upd-images",
  components: { CustomModalFooter, CustomSelect, CustomInput, CustomUploader },
  props: ["data"],
  data() {
    return {
      loading: false,
      btnLoading: false,
      room_picture_id: this.data?.body.room_picture_id || "",
      material_id: this.data?.body.material_id || "",
      formData: {
        material_id: this.data?.body.material_id || "",
        material_set_id: this.data?.body.material_set_id || "",
        order_index: this.data?.body.order_index || 100,
        room_photo: this.data?.body.room_photo || "",
        preset_id: this.data?.body.preset_id || "",
        room_id: this.data?.body.room_id || "",
        rakurs_id: this.data?.body.rakurs_id || "",
        work_set_id: this.data?.body.work_set_id || "",
        material_pid: this.data?.body.material_pid || "",
        material_set_pid: this.data?.body.material_set_pid || "",
      },
      presetsOptions: [],
      roomsOptions: [],
      rakursOptions: [],
      materialSetsOptions: [],
      workSetsOptions: [],
      parentMaterialOptions: [],
      parentMaterialSetsOptions: [],
    };
  },
  watch: {
    "formData.preset_id"(newValue) {
      if (newValue && this.formData.room_id) {
        this.fetchWorkSets();
      }
    },
    "formData.room_id"(newValue) {
      if (newValue && this.formData.preset_id) {
        this.fetchWorkSets(); 
      }
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value;
      if (key === "preset_id" || key === "room_id") {
        this.rakursOptions = [];
        this.formData.rakurs_id = "";
        if (!!this.formData.preset_id && !!this.formData.room_id) {
          this.fetchRakurs();
        }
      }
    },
    onChangeWorkSet(key, value) {
      this.formData[key] = value;
      if (key === "work_set_id") {
        this.fetchParentMaterial();
        this.parentMaterialSetsOptions = [];
        this.formData.material_pid = "";
      }
      if (key === "material_pid") {
        this.fetchParentMaterialSets();
      }
    },
    async onSubmit() {
      this.btnLoading = true;
      await this.data?.onSubmit(this.formData);
      this.btnLoading = false;
    },
    async fetchRoomPictures() {
      if (!this.room_picture_id) return;
      const res = await getRoomPictures(this.room_picture_id);
      if (!res) return;
      this.formData.room_picture_id = res.room_picture_id;
      this.formData.room_picture_photo_id = res.room_picture_photo_id;
    },
    async fetchPresets() {
      this.presetsOptions = await getPresetsOptions();
    },
    async fetchRooms() {
      this.roomsOptions = await getRoomsOptions();
    },
    async fetchRakurs() {
      if (!this.formData.preset_id || !this.formData.room_id) return;
      const body = {
        preset_id: this.formData.preset_id,
        room_id: this.formData.room_id,
      };
      this.rakursOptions = await getRakursOptions(body);
      if (this.rakursOptions?.length) {
        this.formData.rakurs_id = this.rakursOptions[0].rakurs_id;
      }
    },
    async fetchMaterialSets() {
      if (!this.material_id) return;
      this.materialSetsOptions = await getMaterialSets(this.material_id);
    },
    async fetchWorkSets() {
      const params = {
        preset_id: this.formData.preset_id,
        room_id: this.formData.room_id,
      }
      this.workSetsOptions = await getWorkSetsForPic(params);
    },
    async fetchParentMaterial() {
      if (!this.material_id) return;
      const body = {
        room_picture_id: this.room_picture_id,
        work_set_id: this.formData.work_set_id,
      };
      this.parentMaterialOptions = await getParentMaterial(body);
    },
    async fetchParentMaterialSets() {
      if (!this.material_id) return;
      const body = {
        room_picture_id: this.room_picture_id,
        work_set_id: this.formData.work_set_id,
        material_pid: this.formData.material_pid,
      };
      this.parentMaterialSetsOptions = await getParentMaterialSets(body);
    },
  },
  mounted() {
    this.fetchRoomPictures();
    this.fetchPresets();
    this.fetchRooms();
    this.fetchRakurs();
    this.fetchMaterialSets();
    this.fetchParentMaterial();
    this.fetchParentMaterialSets();
    if (this.room_picture_id) {
      this.fetchWorkSets();
    }
  },
  computed: {
    computedLabel() {
      return (option) => `${option.material_set_id} / ${option.set_name}`;
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  display: grid;
  min-width: 650px;
  grid-template-columns: 12fr;
  gap: 20px;

  @media (max-width: 700px) {
    min-width: 50%;
    max-width: 100%;
    grid-template-columns: 12fr;
  }

  &__content {
    padding: 0 15px;
    margin-top: 10px;

    &_item {
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}
.uploader-header {
  margin-bottom: 12px;
}
</style>
