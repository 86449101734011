<template>
  <q-form @submit.prevent="onSubmit">
    <div class="form-group">
      <div class="uploader-header">Фото поверхности (оригинал обложки):</div>
      <CustomUploader
        :value="formData.material_kitchen_schem"
        :on-change="(value) => onChange('material_kitchen_schem', value)"
        label="Фото поверхности (оригинал обложки)"
      />
    </div>
    <q-btn
      label="Сохранить"
      color="secondary"
      type="submit"
      size="sm"
      :loading="submitLoading"
      class="form-actions"
    />
  </q-form>
  <CustomTable
    title="СПЕЦИФИКАЦИЯ КУХОННОЙ ГАРНИТУРЫ"
    :rowsData="this.data"
    :columns="constructorKitchenColumns"
    :loading="isFetching"
    :custom-body="true"
    :sticky-header="true"
    :search-term="searchTerm"
    :showHeaderAdd="true"
    :on-add-click="this.handleAddClick"
  >
    <template #customBody="props">
      <q-tr>
        <template
          v-for="column in constructorKitchenColumns"
          :key="column.name"
        >
          <custom-table-cell :align="column.alignTable || 'left'">
            <template v-if="column.name === 'edit'">
              <a @click="handleUpdateClick(props.row)">
                <CustomIcon icon="edit" />
              </a>
            </template>
            <template v-else-if="column.name === 'dlt'">
              <CustomIcon
                icon="trash"
                :approve-mode="true"
                :loading="
                  this.delBtnLoading[props.row?.material_kitchen_desc_id]
                "
                :approve="
                  () => this.onDelete(props.row.material_kitchen_desc_id)
                "
              />
            </template>
            <template v-else>
              {{ props.row[column.field] }}
            </template>
          </custom-table-cell>
        </template>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomUploader from "@/components/form/CustomUploader";
import BlockItem from "@/components/common/BlockItem";
import CustomIcon from "@/components/common/CustomIcon";
import Clickable from "@/components/common/Clickable";
import CustomInput from "@/components/form/CustomInput";

import { appActions } from "@/services/store/modules/app_module/app_actions";
import { MODAL_NAMES } from "@/constants";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";
import {
  constructorKitchenColumns,
  getKitchenSpecs,
  uploadKitchenPhoto,
  updKitchenDesc,
  onDeleteKitchenDesc,
} from "@/views/Materials/MaterialsList/MaterialEdit/services";

export default {
  name: "KitchenDesc",
  props: {
    materialId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    CustomTable,
    CustomTableCell,
    CustomUploader,
    BlockItem,
    CustomIcon,
    Clickable,
    CustomInput,
  },
  data() {
    return {
      constructorKitchenColumns,
      data: [],
      isFetching: false,
      loading: false,
      submitLoading: false,
      delBtnLoading: {},
      temp_material_id: 0,
      formData: {
        material_id: this.materialId,
        material_kitchen_schem: "",
      },
    };
  },
  methods: {
    async getData() {
      this.loading = true;
      this.isFetching = true;
      const res = await getKitchenSpecs(this.formData.material_id);
      this.loading = false;
      this.isFetching = false;
      if (!res) return;
      this.data = res;
    },
    onChange(key, value) {
      this.formData[key] = value;
    },
    async onSubmit() {
      this.submitLoading = true;
      const res = await uploadKitchenPhoto(this.formData);
      this.submitLoading = false;
      if (!res) return;
      this.data = res;
      this.showSucceedSnackbar();
    },
    async handleAddClick() {
      this.$store.commit(
        appActions.showModal(
          MODAL_NAMES.MATERIALS_CONSTRUCTOR_UPD_KITCHEN_DESC,
          {
            onSubmit: this.onAddSubmit,
            title: "Добавить",
            body: {
              material_id: this.materialId,
            },
          }
        )
      );
    },
    async handleUpdateClick(row) {
      this.$store.commit(
        appActions.showModal(
          MODAL_NAMES.MATERIALS_CONSTRUCTOR_UPD_KITCHEN_DESC,
          {
            onSubmit: (body) => this.onUpdateSubmit(body),
            title: "Редактировать",
            body: {
              material_id: this.materialId,
              material_kitchen_desc_id: row.material_kitchen_desc_id,
              name: row.name,
              description: row.description,
            },
          }
        )
      );
    },
    async onAddSubmit(body) {
      const res = await updKitchenDesc(body);
      if (!res) return;
      this.data = res;
      this.showSucceedSnackbar();
      this.$store.commit(appActions.closeModal());
    },
    async onUpdateSubmit(body) {
      const res = await updKitchenDesc(body);
      if (!res) return;
      this.data = res;
      this.showSucceedSnackbar();
      this.$store.commit(appActions.closeModal());
    },
    async onDelete(material_kitchen_desc_id) {
      this.delBtnLoading = {
        ...this.delBtnLoading,
        [material_kitchen_desc_id]: true,
      };
      const res = await onDeleteKitchenDesc(material_kitchen_desc_id);
      this.delBtnLoading = {
        ...this.delBtnLoading,
        [material_kitchen_desc_id]: false,
      };
      if (!res) return;
      this.data = this.data.filter(
        (item) => item.material_kitchen_desc_id !== material_kitchen_desc_id
      );
      this.showSucceedSnackbar();
    },
  },
  async mounted() {
    await this.getData();
  },
  setup() {
    const { showSucceedSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar };
  },
};
</script>

<style scoped lang="scss">
.form-group {
  margin-bottom: 15px;
  text-align: left;
}
.uploader-header {
  margin-bottom: 12px;
}
.form-actions {
  margin-bottom: 20px;
  text-align: left;
}
</style>
