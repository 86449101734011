import instance from "@/services/api";

export const materialProviderAPI = {
  async getList(params) {
    return await instance()
      .get("/handbook/material_provider/read/", {params})
      .then((res) => res?.data);
  },
  async getPriceHistory(material_provider_id) {
    return await instance()
      .get(`/handbook/material_provider/${material_provider_id}/read_price/`,)
      .then((res) => res?.data);
  },
  async getAgreementsList(provider_id) {
    return await instance()
      .get(`/handbook/material_provider/${provider_id}/read_agreement/`,)
      .then((res) => res?.data);
  },
  async changeRequirementFlag(payload) {
    return await instance()
      .post("/handbook/material_provider/set_required/", payload)
      .then((res) => res?.data);
  },
  async changeMainPriceFlag(payload) {
    return await instance()
      .post("/handbook/material_provider/set_main/", payload)
      .then((res) => res?.data);
  },
  async changeAvailability(payload) {
    return await instance()
      .post("/handbook/material_provider/set_avail/", payload)
      .then((res) => res?.data);
  },
  async changeAdditionalStockRequestFlag(payload) {
    return await instance()
      .post("/handbook/material_provider/set_additional/", payload)
      .then((res) => res?.data);
  },
  async attachAgreement(payload) {
    return await instance()
      .post("/handbook/material_provider/upd_agreement/", payload)
      .then((res) => res?.data);
  },
  async onAddNewPrices(payload) {
    return await instance()
      .post("/handbook/material_provider/price_upd/", payload)
      .then((res) => res?.data);
  },
  async updateMaterialAddChange(payload) {
    return await instance()
      .post("/handbook/material_provider/upd/", payload)
      .then((res) => res?.data);
  },
  async updateMaterialNameOfProvider(payload) {
    return await instance()
      .post("/handbook/material_provider/upd_name/", payload)
      .then((res) => res?.data);
  },
  async updateMaterialNettoPrice(payload) {
    return await instance()
      .post("/handbook/material_provider/upd_netto/", payload)
      .then((res) => res?.data);
  },
  async onDeleteMaterialProvider(material_provider_id) {
    return await instance()
      .delete(`/handbook/material_provider/${material_provider_id}/delete/`)
      .then((res) => res?.data);
  },
}