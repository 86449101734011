import {handbookProvidersAgreementAPI} from "@/views/handbook/ProvidersAgreement/api";

export const getHandbookProvidersAgreement = async (params) => {
  try {
    const res = await handbookProvidersAgreementAPI.getList(params)
    return res?.data
  } catch (e) {
  }
}
export const updateHandbookProviderAgreement = async (contractor_agreement_list_id, body = {}, params) => {
  try {
    const res = await handbookProvidersAgreementAPI.updateProvidersAgreement(contractor_agreement_list_id, body, params)
    return res?.data
  } catch (e) {
  }
}
export const addHandbookProviderAgreement = async (body = {}, params) => {
  try {
    const res = await handbookProvidersAgreementAPI.addProvidersAgreement(body, params)
    return res?.data
  } catch (e) {
  }
}
export const deleteHandbookProviderAgreement = async (contractor_agreement_list_id, params) => {
  try {
    const res = await handbookProvidersAgreementAPI.deleteProvidersAgreement(contractor_agreement_list_id, params)
    return res?.data
  } catch (e) {
  }
}

export const providersAgreementHandbookColumns = [
  {
    name: "contractor_agreement_list_id",
    field: "contractor_agreement_list_id",
    label: "ID",
    align: "center",
  },
  {
    name: "provider_name",
    field: "provider_name",
    label: "Название",
    align: "left",
  },
  {
    name: "document_date",
    field: "document_date",
    label: "Дата договора",
    align: "center",
  },
  {
    name: "agreement_num",
    field: "agreement_num",
    label: "Номер договора",
    align: "left",
  },
  {
    name: "guid",
    field: "guid",
    label: "ГУИД в 1С",
    align: "left",
  },
  {
    name: "is_sent_to_1c",
    field: "is_sent_to_1c",
    label: "Статус отправки в 1С",
    align: "left",
  },
  {
    name: "is_create_provider_payment_from_invoice",
    field: "is_create_provider_payment_from_invoice",
    label: "Автосоздание платежа",
    headerStyle: "width: 80px",
    style: "width: 80px",
    align: "center",
  },
  {
    name: "fio",
    field: "fio",
    label: "Кто создал",
    align: "left",
  },
  {
    name: "date_create",
    field: "date_create",
    label: "Дата создания",
    align: "center",
  },
]