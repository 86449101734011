<template>
  <!-- Page Header -->
  <div class="page-header">
    <div class="pull-left text-xl">
      <div
        @click="onClose"
        class="btn btn-primary btn-mini pull-left margin-right-10 pointer"
      >
        <i class="ace-icon fa fa-arrow-left" aria-hidden="true"></i>
        <q-tooltip>Назад</q-tooltip>
      </div>
      <span class="pull-left" v-if="material_id > 0">
        Редактирование материала
      </span>
      <span class="pull-left" v-else> Добавление материала</span>
    </div>
  </div>

  <div v-if="error" class="alert alert-danger">
    {{ error }}
  </div>

  <CustomTabs
    :data="constructorTabs"
    :tab="whichConstTab"
    @change_tab="onTabChange"
    :hidden="!this.material_id"
  />

  <MaterialUpdate
    v-if="whichConstTab === 'zakup' || whichConstTab === 'smetniy'"
    :materialTypes="materialTypes"
    :materialId="this.material_id"
    :tabLabel="this.tabLabel"
    :whichTab="
      this.constructorTabs.find((tab) => tab.name === whichConstTab)?.name
    "
  />
  <Common
    :materialId="this.material_id"
    v-if="whichConstTab === 'constructorCommon'"
  />
  <ImagesList :materialId="this.material_id" v-if="whichConstTab === 'imgs'" />
  <KitchenDesc
    :materialId="this.material_id"
    v-if="whichConstTab === 'kitchenDesc'"
  />
  <AdditionalImgs
    :materialId="this.material_id"
    v-if="whichConstTab === 'additionalImgs'"
  />
</template>

<script>
import MaterialUpdate from "@/views/Materials/MaterialsList/MaterialEdit/materialUpdate";
import Common from "@/views/Materials/MaterialsList/MaterialEdit/Common";
import ImagesList from "@/views/Materials/MaterialsList/MaterialEdit/ImagesList";
import KitchenDesc from "@/views/Materials/MaterialsList/MaterialEdit/KitchenDesc";
import AdditionalImgs from "@/views/Materials/MaterialsList/MaterialEdit/AdditionalImgs";

import CustomTabs from "@/components/common/CustomTabs";

import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "MaterialEdit",
  components: {
    CustomTabs,
    MaterialUpdate,
    Common,
    ImagesList,
    KitchenDesc,
    AdditionalImgs,
  },
  props: {
    materialId: {
      type: Number,
      default: 0,
    },
    materialTypes: { type: Array, default: () => [] },
  },
  data() {
    return {
      data: [],
      loading: false,
      isFetching: false,
      whichConstTab: "constructorCommon",
      tabLabel: "Конструктор",
      material_id: this.materialId || 0,
      providersOptions: [],
      materialTypes: [],
      isFetchingFilters: false,
      constructorTabs: [
        { name: "zakup", label: "Отдел закупа" },
        { name: "smetniy", label: "Сметный отдел" },
        { name: "constructorCommon", label: "Конструктор" },
        { name: "imgs", label: "Фотографии по комнатам" },
        { name: "kitchenDesc", label: "Описание для кухни" },
        { name: "additionalImgs", label: "Доп. картинки" },
      ],
    };
  },
  methods: {
    onTabChange(newTab) {
      this.whichConstTab = newTab;
      this.tabLabel =
        this.constructorTabs.find((tab) => tab.name === newTab)?.label ||
        "Конструктор";
      this.data = [];
    },
    onClose() {
      this.$emit("close");
    },
  },
  setup() {
    const { showSucceedSnackbar, showErrorSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar, showErrorSnackbar };
  },
  mounted() {
    if (this.material_id === 0) {
      this.whichConstTab = "zakup";
    }
  },
};
</script>

<style scoped lang="scss">
/* Заголовок */
.page-header {
  margin-bottom: 15px;
  .pull-left {
    display: inline-block;
    vertical-align: middle;
    font-size: 32px;
  }
  .pointer {
    cursor: pointer;
  }
  .pointer:hover {
    color: red;
  }
  .margin-right-10 {
    margin-right: 10px;
  }
}

/* Оповещение */
.alert {
  margin-bottom: 15px;
}
</style>
