import instance from "@/services/api";

export const BalancesAPI = {
  async getList() {
    return await instance()
      .get("/finance/balance/read/")
      .then((res) => res?.data);
  },
  async getBalance(balance_id) {
    return await instance()
      .get(`/finance/balance/${balance_id}/get/`)
      .then((res) => res?.data);
  },
  async updateBalance(body) {
    return await instance()
      .post("/finance/balance/upd/", body)
      .then((res) => res?.data);
  },
  async deleteBalance(balance_id) {
    return await instance()
      .delete(`/finance/balance/${balance_id}/del/`)
      .then((res) => res?.data);
  },
};
