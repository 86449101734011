<template>
  <div :class="[{purpose: this.data?.body?.purpose}]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomSelect
          :options="this.providers"
          label="Поставщик"
          :required="true"
          optionValueKey="provider_id"
          optionLabelKey="provider_name"
          :value="this.formData.provider_id || ''"
          @change="(value) => onChange('provider_id', value)"
        />
      </div>
      <div class="form__item">
        <CustomDate
          :fullWidth="true"
          :required="true"
          placeholder="Дата договора"
          :value="this.formData.document_date"
          @change_date="(value) => onChange('document_date', value)"
        />
      </div>
      <div class="form__item">
        <CustomInput
          label="Номер договора"
          :required="true"
          type="text"
          :value="this.formData.agreement_num"
          @change_input="(value) => onChange('agreement_num', value)"
        />
      </div>
      <div class="form__item">
        <CustomCheckbox label="Автосоздание платежа" :value="this.formData.is_create_provider_payment_from_invoice"
          @change="(value) => onChange('is_create_provider_payment_from_invoice', value)" />
      </div>
      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomDate from "@/components/form/CustomDate";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import {appActionTypes} from "@/services/store/modules/app_module/app_mutation_types";

export default {
  name: "add-providers-agreement",
  components: {CustomSelect, CustomDate, CustomInput, CustomModalFooter, CustomCheckbox},
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        is_sent_to_1c: this.data?.body?.is_sent_to_1c || 0,
        provider_id: this.data?.body?.provider_id || '',
        document_date: this.data?.body?.document_date || '',
        agreement_num: this.data?.body?.agreement_num || '',
        is_create_provider_payment_from_invoice: this.data?.body?.is_create_provider_payment_from_invoice || false,
      }
    }
  },
  methods: {
    onChange(key, value, key2, value2) {
      this.formData[key] = value
      if(!key2) return
      this.formData[key2] = value2
    },
    async onSubmit() {
      let contractor_id
      if(this.formData.remont_costs_type_code === 'IS_TO_CONTRACTOR' && this.formData.contragent && !this.formData.contractor_id)
        contractor_id = this.contractors?.find(item => item.legal_name === this.formData.contragent)?.contractor_id
      if(contractor_id)
        this.formData.contractor_id = contractor_id
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
  },
  computed: {
    providers() {
      return this.$store.state.app.providers;
    },
  },
  mounted() {
    this.$store.dispatch(appActionTypes.getCommonProviders)
  }
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
