<template>
  <div class="form-container">
    <q-form @submit.prevent="onSubmit">
      <div class="form-columns">
        <div class="form-group">
          <CustomInput
            label="Название материала"
            :value="formData.material_name"
            :disable="true"
            :showClearIcon="false"
          />
        </div>
        <div class="form-group">
          <CustomInput
            label="Тип материала"
            :value="formData.material_type_name"
            :disable="true"
            :showClearIcon="false"
          />
        </div>
        <div class="form-group">
          <CustomInput
            label="Название материала на казахском"
            :value="formData.material_name_kz"
            @change_input="(value) => onChange('material_name_kz', value)"
          />
        </div>
        <!-- material_name_for_client -->
        <div class="form-group">
          <CustomInput
            label="Название материала для клиента"
            :value="formData.material_name_for_client"
            @change_input="
              (value) => onChange('material_name_for_client', value)
            "
          />
        </div>

        <!-- material_name_for_ddu -->
        <div class="form-group">
          <CustomInput
            label="Название материала для договоров ДДУ"
            :value="formData.material_name_for_ddu"
            @change_input="(value) => onChange('material_name_for_ddu', value)"
          />
        </div>

        <!-- material_name_for_ddu_kz -->
        <div class="form-group">
          <CustomInput
            label="Название материала для договоров ДДУ на казахском"
            :value="formData.material_name_for_ddu_kz"
            @change_input="
              (value) => onChange('material_name_for_ddu_kz', value)
            "
          />
        </div>

        <!-- is_design_measure -->
        <div class="form-group">
          <CustomCheckbox
            :value="formData.is_design_measure"
            label="Требуется внести замер дизайнером"
            :binary="true"
            @change="(value) => onChange('is_design_measure', value)"
          />
        </div>

        <!-- description -->
        <div class="form-group">
          <div class="description__header">Описание:</div>
          <div class="description__editor">
            <quill-editor
              v-model:content="formData.description"
              content-type="html"
              toolbar="full"
            />
          </div>
        </div>

        <!-- characteristic_json -->
        <div class="form-group">
          <CustomInput
            type="textarea"
            label="Характеристики (JSON)"
            :value="formData.characteristic_json"
            @change_input="(value) => onChange('characteristic_json', value)"
          />
        </div>

        <!-- is_contractor_measure -->
        <div class="form-group">
          <CustomCheckbox
            :value="formData.is_contractor_measure"
            label="Корректировка подрядчиком"
            :binary="true"
            @change="(value) => onChange('is_contractor_measure', value)"
          />
        </div>

        <!-- photo_url_orig (загрузчик) -->
        <div class="form-group">
          <div class="description__header">
            Фото поверхности (оригинал обложки):
          </div>
          <CustomUploader
            :value="formData.photo_url_orig"
            :on-change="(value) => onChange('photo_url_orig', value)"
            label="Фото поверхности (оригинал обложки)"
          />
          <a
            download
            target="_blank"
            rel="noopener noreferrer"
            v-if="!!this.formData.photo_url_orig"
            :href="`${FILE_URL}${formData.photo_url_orig}`"
            link
          >
            <q-icon name="fa-solid fa-download" />&nbsp;Скачать оригинал
          </a>
        </div>
        <CustomImg
          v-if="!!this.formData.photo_url"
          style="max-height: 100px; object-fit: contain"
          :src="formData.photo_url"
          :showDefaultImg="false"
        />
      </div>
      <q-btn
        label="Сохранить"
        color="secondary"
        icon="save"
        type="submit"
        :loading="submitLoading"
        class="form-actions"
      />
    </q-form>

    <div>
      <PropForm
        :material_id="materialId"
        :material_category_id="this.formData.material_category_id"
      />
    </div>
  </div>
</template>

<script>
import PropForm from "@/views/Materials/MaterialsList/MaterialEdit/PropForm";

import CustomInput from "@/components/form/CustomInput";
import CustomSelect from "@/components/form/CustomSelect";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomUploader from "@/components/form/CustomUploader";
import Clickable from "@/components/common/Clickable";
import CustomImg from "@/components/common/CustomImg";

import { FILE_URL } from "@/constants";
import { QuillEditor } from "@vueup/vue-quill";

import {
  getMaterialData,
  updateConstructorMaterial,
} from "@/views/Materials/services";

export default {
  name: "Common",
  components: {
    CustomInput,
    CustomSelect,
    QuillEditor,
    CustomCheckbox,
    CustomUploader,
    Clickable,
    CustomImg,
    PropForm,
  },
  props: {
    materialId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      submitLoading: false,
      formData: {
        material_id: this.materialId || 0,
        material_name: "",
        material_type_name: "",
        material_name_kz: "",
        material_name_for_client: "",
        material_name_for_ddu: "",
        material_name_for_ddu_kz: "",
        is_design_measure: 0,
        description: "",
        characteristic_json: "",
        is_contractor_measure: 0,
        photo_url_orig: "",
        photo_url: "",
        material_category_id: 0,
      },
    };
  },
  methods: {
    async getData() {
      this.submitLoading = true;
      if (!this.materialId) {
        this.submitLoading = false;
        return;
      }
      const res = await getMaterialData(
        this.formData.material_id,
        "constructor"
      );
      this.submitLoading = false;
      if (!res) {
        this.showErrorSnackbar();
        return;
      }
      this.formData = res;
    },
    async onSubmit() {
      this.submitLoading = true;
      const payload = this.getCleanFormData();
      const res = await updateConstructorMaterial(payload);
      this.submitLoading = false;
      if (!res) return;
      this.getData();
      this.$emit("close");
      this.showSucceedSnackbar();
    },
    onChange(field, value) {
      this.formData[field] = value;
    },
    getCleanFormData() {
      const {
        material_id,
        material_name,
        material_type_name,
        material_name_kz,
        material_name_for_client,
        material_name_for_ddu,
        material_name_for_ddu_kz,
        is_design_measure,
        description,
        characteristic_json,
        is_contractor_measure,
        photo_url_orig,
        photo_url,
      } = this.formData;

      return {
        material_id,
        material_name,
        material_type_name,
        material_name_kz,
        material_name_for_client,
        material_name_for_ddu,
        material_name_for_ddu_kz,
        is_design_measure,
        description,
        characteristic_json,
        is_contractor_measure,
        photo_url_orig,
        photo_url,
      };
    },
  },
  async mounted() {
    await this.getData();
  },
};
</script>

<style scoped lang="scss">
.form-container {
  display: flex;
  gap: 20px;
}

.form-columns {
  display: grid;
  gap: 10px;
  width: 500px;
  max-width: 500px;
  background: #f7f7f7;
  padding: 15px;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.description {
  &__header {
    margin-bottom: 12px;
  }

  &__editor {
    max-width: 520px;
    overflow: auto;

    :deep(.ql-container) {
      height: 240px; // внутреннее поле для текста
    }
  }
}
.form-actions {
  margin-top: 20px;
  text-align: left;
}
</style>
