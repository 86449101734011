<template>
  <CustomTable
    title="Дополнительные картинки материала"
    :rowsData="this.data"
    :columns="addImgsColumns"
    :loading="loading"
    :custom-body="true"
    :sticky-header="true"
    :search-term="searchTerm"
    :showHeaderAdd="true"
    :on-add-click="this.handleAddClick"
  >
    <template #customBody="props">
      <q-tr>
        <template v-for="column in addImgsColumns" :key="column.name">
          <custom-table-cell :align="column.alignTable || 'center'">
            <template v-if="column.name === 'photo_url'">
              <CustomImg :src="props.row.photo_url" alt="material_photo" />
              <br />
              <Clickable :href="props.row.photo_url_orig" link>
                Скачать
              </Clickable>
            </template>
            <template v-else-if="column.name === 'rowversion'">
              {{ formattedDate(props.row.rowversion) }}
            </template>
            <template v-else-if="column.name === 'edit'">
              <a @click="handleUpdateClick(props.row)">
                <CustomIcon icon="edit" />
              </a>
            </template>
            <template v-else-if="column.name === 'dlt'">
              <CustomIcon
                icon="trash"
                :approve-mode="true"
                :loading="this.delBtnLoading[props.row?.material_photo_id]"
                :approve="() => this.onDelete(props.row.material_photo_id)"
              />
            </template>
            <template v-else>
              {{ props.row[column.field] }}
            </template>
          </custom-table-cell>
        </template>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import BlockItem from "@/components/common/BlockItem";
import CustomIcon from "@/components/common/CustomIcon";
import Clickable from "@/components/common/Clickable";
import CustomInput from "@/components/form/CustomInput";
import CustomImg from "@/components/common/CustomImg";

import { appActions } from "@/services/store/modules/app_module/app_actions";
import { MODAL_NAMES } from "@/constants";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";
import {
  addImgsColumns,
  getAdditionalImgs,
  updAddImage,
  onDeleteAdditionalPhoto,
} from "@/views/Materials/MaterialsList/MaterialEdit/services";

export default {
  name: "AdditionalImgs",
  props: {
    materialId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    CustomTable,
    CustomTableCell,
    BlockItem,
    CustomIcon,
    Clickable,
    CustomInput,
    CustomImg,
  },
  data() {
    return {
      addImgsColumns,
      data: [],
      material_id: this.materialId,
      loading: false,
      delBtnLoading: {},
      delBtnLoading: {},
      temp_material_id: 0,
      showUploader: false,
    };
  },
  methods: {
    async getData() {
      this.loading = true;
      const res = await getAdditionalImgs(this.material_id);
      this.loading = false;
      if (!res) return;
      this.data = res;
    },
    formattedDate(rowDate) {
      const date = new Date(rowDate);
      const pad = (n) => n.toString().padStart(2, "0");
      return `${pad(date.getDate())}.${pad(
        date.getMonth() + 1
      )}.${date.getFullYear()} ${pad(date.getHours())}:${pad(
        date.getMinutes()
      )}:${pad(date.getSeconds())}`;
    },
    async handleAddClick() {
      this.$store.commit(
        appActions.showModal(
          MODAL_NAMES.MATERIALS_CONSTRUCTOR_UPD_ADDITIONAL_IMGS,
          {
            onSubmit: this.onAddSubmit,
            title: "Добавить дополнительную картинку",
            body: {
              material_id: this.materialId,
            },
          }
        )
      );
    },
    async handleUpdateClick(row) {
      this.$store.commit(
        appActions.showModal(
          MODAL_NAMES.MATERIALS_CONSTRUCTOR_UPD_ADDITIONAL_IMGS,
          {
            onSubmit: (body) => this.onUpdateSubmit(body),
            title: "Редактировать дополнительную картинку",
            body: {
              material_id: this.materialId,
              material_photo_id: row.material_photo_id,
              order_num: Number(row.order_num),
            },
          }
        )
      );
    },
    async onAddSubmit(body) {
      const res = await updAddImage(body);
      if (!res) return;
      this.data = res;
      this.showSucceedSnackbar();
      this.$store.commit(appActions.closeModal());
    },
    async onUpdateSubmit(body) {
      const res = await updAddImage(body);
      if (!res) return;
      this.data = res;
      this.showSucceedSnackbar();
      this.$store.commit(appActions.closeModal());
    },
    async onDelete(material_photo_id) {
      this.delBtnLoading = {
        ...this.delBtnLoading,
        [material_photo_id]: true,
      };
      const res = await onDeleteAdditionalPhoto(material_photo_id);
      this.delBtnLoading = {
        ...this.delBtnLoading,
        [material_photo_id]: false,
      };
      if (!res) return;
      this.data = this.data.filter(
        (item) => item.material_photo_id !== material_photo_id
      );
      this.showSucceedSnackbar();
    },
  },
  async mounted() {
    await this.getData();
  },
  computed: {
    computedHeaderAdd() {
      if (this.whichTab === "zakup") {
        return true;
      } else {
        return false;
      }
    },
  },
  setup() {
    const { showSucceedSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar };
  },
};
</script>

<style scoped lang="scss">
.form-group {
  margin-bottom: 15px;
  text-align: left;
}
.form-actions {
  margin-bottom: 20px;
  text-align: left;
}

.collapseBtn i {
  transition: transform 0.2s ease;
}
.collapseBtn i.rotated {
  transform: rotate(180deg);
}
</style>
