<template>
  <CollapseBlock style="margin-bottom: 20px">
    <form @submit.prevent="onSearch">
      <div class="filters">
        <div class="filters__item">
          <CustomInput
            label="Материал"
            :value="values.material_provider_search"
            @change="(val) => onChange('material_provider_search', val)"
            @clear_input="onChange('material_provider_search', '')"
          />
        </div>

        <div class="filters__item">
          <CustomSelect
            :options="materialTypes"
            label="Тип материала"
            optionValueKey="material_type_id"
            optionLabelKey="material_type_name"
            :value="this.values.material_type_id"
            @change="(value) => onChange('material_type_id', value)"
          />
        </div>

        <div class="filters__item">
          <CustomSelect
            :options="providersOptions"
            label="Поставщик"
            optionValueKey="provider_id"
            optionLabelKey="provider_name"
            :value="values.provider_id"
            @change="(val) => onChange('provider_id', val)"
          />
        </div>

        <div class="filters__item">
          <CustomSelect
            :options="materialActivity"
            label="Активность материалов"
            optionValueKey="value"
            optionLabelKey="label"
            :value="values.material_active"
            @change="(val) => onChange('material_active', val)"
            :clearable="false"
          />
        </div>

        <div class="filters__item">
          <CustomSelect
            :options="availabilityOptions"
            label="Наличие"
            optionValueKey="value"
            optionLabelKey="label"
            :value="values.is_avail"
            @change="(val) => onChange('is_avail', val)"
            :clearable="false"
          />
        </div>

        <div class="filters__item">
          <CustomSelect
            :options="citiesOptions"
            label="Город"
            optionValueKey="city_id"
            optionLabelKey="city_name"
            :value="values.city_id"
            @change="(val) => onChange('city_id', val)"
            required="true"
            :clearable="false"
          />
        </div>

        <div class="filters__item">
          <CustomSelect
            :options="shippedFromWarehouse"
            label="Отгружаемые со склада"
            optionValueKey="value"
            optionLabelKey="label"
            :value="values.from_wh"
            @change="(val) => onChange('from_wh', val)"
            :clearable="false"
          />
        </div>

        <div class="filters__item materials-wide">
          <CustomSelect
            :options="materialsOptions"
            label="Материал (список)"
            optionValueKey="material_id"
            optionLabelKey="material_name"
            :value="values.material_id"
            @change="(val) => onChange('material_id', val)"
            :noOptionText="noOptionText"
          />
        </div>

        <q-btn
          type="submit"
          :loading="isFetching"
          size="md"
          no-wrap
          color="primary"
          class="q-btn"
          label="Поиск"
          icon="search"
        />
      </div>
    </form>
  </CollapseBlock>
</template>

<script>
import CustomInput from "@/components/form/CustomInput";
import CustomSelect from "@/components/form/CustomSelect";
import CollapseBlock from "@/components/common/CollapseBlock";
import {
  shippedFromWarehouse,
  materialActivity,
  availabilityOptions,
} from "@/views/MaterialProviderList/MaterialProviderListFilters/services";

export default {
  name: "SupplierMaterialsFilters",
  components: { CustomInput, CustomSelect, CollapseBlock },
  props: {
    values: { type: Object, default: () => ({}) },
    isFetching: { type: Boolean, default: false },
    noOptionText: { type: String, default: "Не найдено" },
    getData: { type: Function, default: null },
    providersOptions: { type: Array, default: () => [] },
    materialTypes: { type: Array, default: () => [] },
    materialsOptions: { type: Array, default: () => [] },
    citiesOptions: { type: Array, default: () => [] },
  },
  data() {
    return {
      shippedFromWarehouse,
      materialActivity,
      availabilityOptions,
    };
  },
  methods: {
    onChange(type, value) {
      this.$emit("filter_change", type, value);
    },
    onSearch() {
      if (this.getData) {
        this.getData();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 15px;

  &__item {
    flex: 1 1 220px;
  }

  .materials-wide {
    flex: 3 1 55%;
  }
}

.q-btn {
  margin-top: 10px;
  align-self: flex-start;
}
</style>
