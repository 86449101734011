<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">

      <div class="form__content">
        <CustomSelect :options="agreementOptions" label="Договор" :required="true" optionValueKey="contractor_agreement_list_id"
          optionLabelKey="agreement_name" :fullWidth="true" :value="this.formData.contractor_agreement_list_id"
          @change="(value) => onChange('contractor_agreement_list_id', value)" />
      </div>

      <CustomModalFooter :btn-loading="this.btnLoading" />
    </q-form>
  </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import { getAgreementsList } from "@/views/MaterialProviderList/services";

export default {
  name: "material-list-attach-agreement",
  components: { CustomModalFooter, CustomSelect },
  props: ['data'],
  data() {
    return {
      loading: false,
      btnLoading: false,
      formData: {
        material_provider_id_arr: this.data?.body.material_provider_id_arr || '',
        contractor_agreement_list_id: '',
      },
      agreementOptions: [],
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value;
    },
    async onSubmit() {
      this.btnLoading = true
      await this.data?.onSubmit(this.formData)
      this.btnLoading = false
    },
    async fetchAgreements() {
      this.agreementOptions = await getAgreementsList(this.data?.body.provider_id);
    },
  },
  mounted() {
    this.fetchAgreements();
  }
}
</script>

<style scoped lang="scss">
.form {
  display: grid;
  min-width: 550px;
  grid-template-columns: 12fr;
  gap: 20px;

  @media (max-width: 991px) {
    min-width: 50%;
    max-width: 100%;
    grid-template-columns: 12fr;
  }

  &__content {
    padding: 0 15px;
    margin-top: 10px;

    &_item {
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}

</style>