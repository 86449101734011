<template>
  <div class="material-zakup-edit">

    <!-- Main Form -->
    <div :class="{ 'form-grid': whichTab === 'smetniy' }">
      <q-form @submit.prevent="onSubmit">
        <!-- Left Column -->
        <div class="form-columns">
          <div class="col">
            <div class="well">
              <!-- If editing an existing material, show providers table -->
              <div
                v-if="material_id > 0 && whichTab === 'zakup'"
                class="table-responsive"
              >
                <CustomTable
                  :rowsData="rowsData"
                  :columns="providerTableColumns"
                  class="provider-table"
                />
                <br />
              </div>

              <!-- Material Name -->
              <div class="form-group" v-if="whichTab === 'zakup'">
                <CustomInput
                  label="Название материала"
                  :value="formData.material_name"
                  :required="true"
                  @change_input="(value) => onChange('material_name', value)"
                />
              </div>
              <div class="form-group" v-if="whichTab === 'smetniy'">
                <CustomInput
                  label="Название материала"
                  type="textarea"
                  :rows="formData.material_name?.length < 38 ? 1 : Math.ceil(formData.material_name?.length / 38)"
                  :value="formData.material_name"
                  :required="true"
                  @change_input="(value) => onChange('material_name', value)"
                  :disable="true"
                  :showClearIcon="false"
                />
              </div>            

              <!-- Material Type -->
              <div class="form-group">
                <CustomSelect
                  :options="materialTypes"
                  label="Тип материала"
                  optionValueKey="material_type_id"
                  optionLabelKey="material_type_name"
                  :value="formData.material_type_id"
                  :required="true"
                  @change="(value) => onChange('material_type_id', value)"
                  :disable="computedTrueOnSmetniy"
                  :clearable="computedFalseOnSmetniy"
                />
              </div>

              <!-- Vendor Code -->
              <div class="form-group" v-if="whichTab === 'zakup'">
                <CustomInput
                  label="Артикул"
                  :value="formData.vendor_code"
                  @change_input="(value) => onChange('vendor_code', value)"
                />
              </div>

              <div class="form-group" v-if="whichTab === 'smetniy'">
                <CustomSelect
                  :value="formData.delivery_unit_name"
                  label="Единица отпуска из магазина"
                  optionValueKey="delivery_unit_name"
                  optionLabelKey="delivery_unit_name"
                  :disable="computedTrueOnSmetniy"
                  :clearable="computedFalseOnSmetniy"
                />
              </div>

              <div class="form-group" v-if="whichTab === 'smetniy'">
                <CustomSelect
                  :value="formData.consume_unit_id"
                  :options="unitOptions"
                  label="Единица расхода"
                  :required="true"
                  optionValueKey="unit_id"
                  optionLabelKey="unit_name"
                  @change="(value) => onChange('consume_unit_id', value)"
                />
              </div>

              <div class="form-group" v-if="whichTab === 'smetniy'">
                <label for="koef_consume_to_delivery">
                  Кол-во единиц расхода в одной ед. отпуска
                </label>
                <CustomInput
                  type="number"
                  :value="formData.koef_consume_to_delivery"
                  label="Расход"
                  :required="true"
                  :controls="true"
                  @change_input="
                    (value) => onChange('koef_consume_to_delivery', value)
                  "
                />
              </div>

              <!-- Currency -->
              <div class="form-group" v-if="whichTab === 'zakup'">
                <CustomSelect
                  :value="formData.currency"
                  :options="this.currencyOptions"
                  optionValueKey="value"
                  optionLabelKey="label"
                  label="Валюта страны происхождения..."
                  @change="(value) => onChange('currency', value)"
                />
              </div>

              <!-- Checkboxes (Left Column) -->
              <span v-if="whichTab === 'zakup'">
                <div class="form-group">
                  <CustomCheckbox
                    :value="formData.is_dirty"
                    label="Черновой материал"
                    @change="(value) => onChange('is_dirty', value)"
                  />
                </div>
                <div class="form-group">
                  <CustomCheckbox
                    :value="formData.is_draft_partner"
                    label="Для партнера*"
                    title="Можно ставить если уже проставлена галочка на черновой материал"
                    :binary="true"
                    :disable="!this.formData.is_dirty"
                    @change="(value) => onChange('is_draft_partner', value)"
                  />
                </div>
                <div class="form-group">
                  <CustomCheckbox
                    :value="formData.is_external_sale"
                    label="Внешняя продажа"
                    :binary="true"
                    @change="(value) => onChange('is_external_sale', value)"
                  />
                </div>
                <div class="form-group">
                  <CustomCheckbox
                    :value="formData.is_active"
                    label="Активный"
                    :binary="true"
                    @change="(value) => onChange('is_active', value)"
                  />
                </div>
                <div class="form-group">
                  <CustomCheckbox
                    :value="formData.is_koef_round"
                    label="Округление"
                    :binary="true"
                    @change="(value) => onChange('is_koef_round', value)"
                  />
                </div>
              </span>
            </div>
          </div>

          <!-- Middle Column for zakup -->
          <div class="col" v-if="whichTab === 'zakup'">
            <div class="well">
              <!-- Additional Settings (Door Height and Type Collection) -->
              <div
                v-if="this.formData.material_type_id === 3"
                class="form-group door-height"
              >
                <label for="door_height">Высота двери</label>
                <CustomSelect
                  :value="formData.door_height"
                  :options="doorHeightOptions"
                  label="Выберите из списка..."
                  :required="true"
                  optionValueKey="material_additional_setting_id"
                  optionLabelKey="material_additional_setting_value"
                  @change="(value) => onChange('door_height', value)"
                />
              </div>
              <!-- Hidden fields if needed -->
              <label for="material_type_collection_id">Коллекция</label>
              <CustomSelect
                :value="formData.material_type_collection_id"
                :options="collectionOptions"
                label="Выберите коллекцию"
                optionValueKey="material_type_collection_id"
                optionLabelKey="material_type_collection_name"
                @change="
                  (value) => onChange('material_type_collection_id', value)
                "
              />
              <br />

              <!-- More Checkboxes -->
              <div class="form-group">
                <CustomCheckbox
                  :value="formData.is_required_create"
                  label="Создавать потребность"
                  :binary="true"
                  @change="(value) => onChange('is_required_create', value)"
                />
              </div>
              <div class="form-group">
                <CustomCheckbox
                  :value="formData.is_service"
                  label="Услуга"
                  :binary="true"
                  @change="(value) => onChange('is_service', value)"
                />
              </div>
              <div class="form-group">
                <CustomCheckbox
                  :value="formData.is_service_buh"
                  label="Услуга в 1С"
                  :binary="true"
                  @change="(value) => onChange('is_service_buh', value)"
                />
              </div>

              <hr />

              <!-- Additional Checkboxes -->
              <div class="form-group">
                <CustomCheckbox
                  :value="formData.is_top_10"
                  label="Топ 10"
                  @change="(value) => onChange('is_top_10', value)"
                />
              </div>
              <div class="form-group">
                <CustomCheckbox
                  :value="formData.is_owned_material"
                  label="Наш материал"
                  @change="(value) => onChange('is_owned_material', value)"
                />
              </div>

              <hr v-if="formData.qr_link" />

              <!-- QR Code Link -->
              <div class="form-group" v-if="formData.qr_link">
                <label for="qrLink">Ссылка для QR кода:</label>
                <div class="input-group">
                  <span class="input-group-addon">
                    <a :href="formData.qr_link" target="_blank" id="qrLink" style="text-decoration: underline; color: blue;">{{
                      formData.qr_link
                    }}</a>
                  </span>
                  <span class="input-group-btn">
                    <q-btn outlined label="Скопировать" @click="copyQrLink" class="mt-1" style="margin: 10px auto 0; display: block;" />
                  </span>
                </div>
              </div>
              <div class="form-group" v-if="formData.qr_link">
                <CustomCheckbox
                  :value="formData.is_qr_generated"
                  label="QR код сформирован"
                  :binary="true"
                  @change="(value) => onChange('is_qr_generated', value)"
                />
              </div>
            </div>
          </div>

          <!-- Right Column: Only for new material (material_id == 0) -->
          <div v-if="material_id == 0" class="col">
            <div class="well">
              <blockquote class="example-block">
                <i style="font-size: 15px">
                  <b>Пример для ламината:</b><br />
                  Единица отпуска из магазина: Упаковка<br />
                  Минимальная единица: Шт.<br />
                  Кол-во минимальной единицы в одной ед. отпуска: 8<br />
                  Единица измерения продажи: м2<br />
                  Кол-во единиц продаж в одной ед. отпуска: 2.131<br />
                </i>
              </blockquote>
              <div class="form-group">
                <label for="delivery_unit_id"
                  >Единица отпуска из магазина</label
                >
                <CustomSelect
                  :value="formData.delivery_unit_id"
                  :options="unitOptions"
                  label="Выберите единицу..."
                  :required="true"
                  optionValueKey="unit_id"
                  optionLabelKey="unit_name"
                  @change="(value) => onChange('delivery_unit_id', value)"
                />
              </div>
              <div class="form-group">
                <label for="atom_unit_id">Минимальная единица</label>
                <CustomSelect
                  :value="formData.atom_unit_id"
                  :options="unitOptions"
                  label="Выберите единицу..."
                  :required="true"
                  optionValueKey="unit_id"
                  optionLabelKey="unit_name"
                  @change="(value) => onChange('atom_unit_id', value)"
                />
              </div>
              <div class="form-group">
                <label for="koef_atom_to_delivery">
                  Кол-во минимальной единицы в одной ед. отпуска
                </label>
                <CustomInput
                  type="number"
                  :value="formData.koef_atom_to_delivery"
                  label="Расход"
                  :required="true"
                  @change_input="
                    (value) => onChange('koef_atom_to_delivery', value)
                  "
                />
              </div>
              <div class="form-group">
                <label for="sell_unit_id">Единица измерения продажи</label>
                <CustomSelect
                  :value="formData.sell_unit_id"
                  :options="unitOptions"
                  label="Выберите единицу..."
                  :required="true"
                  optionValueKey="unit_id"
                  optionLabelKey="unit_name"
                  @change="(value) => onChange('sell_unit_id', value)"
                />
              </div>
              <div class="form-group">
                <label for="koef_sell_to_delivery">
                  Кол-во единиц продаж в одной ед. отпуса
                </label>
                <CustomInput
                  type="number"
                  :value="formData.koef_sell_to_delivery"
                  label="Расход"
                  :required="true"
                  @change_input="
                    (value) => onChange('koef_sell_to_delivery', value)
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Form Actions -->
        <div class="form-actions">
          <q-btn
            v-if="whichTab === 'zakup'"
            class="footer__btn footer__btn_cancel"
            label="Назад"
            color="red"
            icon="close"
            @click="onClose"
            :disable="submitLoading"
            style="margin-right: 10px"
          />
          <q-btn
            label="Сохранить"
            color="secondary"
            icon="save"
            type="submit"
            :loading="submitLoading"
            class="form-actions_btn"
          />
        </div>
      </q-form>
      <q-form @submit.prevent="onSubmitThreshold" v-if="whichTab === 'smetniy'">
        <!-- Middle Column for smetniy -->
        <div class="col-smetniy">
          <div class="well">
            <div class="form-group">
              <CustomCheckbox
                :value="formData.is_atom_measure"
                label="Замеры в мин. ед."
                :binary="true"
                @change="(value) => onChange('is_atom_measure', value)"
              />
            </div>
            <div class="form-group">
              <CustomInput
                type="number"
                :value="formData.lower_threshold"
                label="Нижний порог в замерах"
                :controls="true"
                @change_input="(value) => onChange('lower_threshold', value)"
              />
            </div>
            <div class="form-group">
              <CustomInput
                type="number"
                :value="formData.upper_threshold"
                label="Верхний порог в замерах"
                :controls="true"
                @change_input="(value) => onChange('upper_threshold', value)"
              />
            </div>
          </div>
          <q-btn
            label="Сохранить замеры"
            color="secondary"
            type="submit"
            :loading="submitLoadingThreshold"
            class="submit-threshold"
          />
        </div>
      </q-form>
    </div>
  </div>
</template>

<script>
import CustomInput from "@/components/form/CustomInput";
import CustomSelect from "@/components/form/CustomSelect";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomTable from "@/components/common/CustomTable";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";
import {
  getMaterialData,
  updateZakupMaterial,
  updateSmetniyMaterial,
  updateMaterialThreshold,
  currencyOptions,
  getDoorHeights,
  getMaterialCollections,
  getMaterialUnits,
} from "@/views/Materials/services";

export default {
  name: "MaterialUpdate",
  components: {
    CustomInput,
    CustomSelect,
    CustomCheckbox,
    CustomTable,
  },
  props: {
    materialId: {
      type: Number,
      default: 0,
    },
    materialProviderList: {
      type: Array,
      default: () => [],
    },
    materialTypes: {
      type: Array,
      default: () => [],
    },
    whichTab: { type: String, default: "zakup" },
    tabLabel: { type: String, default: "Отдел закупа" },
  },
  data() {
    return {
      submitLoading: false,
      submitLoadingThreshold: false,
      material_id: this.materialId || 0,
      tabLabel: this.tabLabel,
      formData: {
        material_name: "",
        material_type_id: "",
        vendor_code: "",
        currency: "",
        is_dirty: false,
        is_draft_partner: 0,
        is_external_sale: 0,
        is_active: true,
        is_koef_round: 0,
        is_required_create: 0,
        is_service: 0,
        is_service_buh: 0,
        is_top_10: false,
        is_owned_material: false,
        qr_link: "",
        is_qr_generated: 0,
        delivery_unit_id: "",
        delivery_unit_name: "",
        atom_unit_id: "",
        koef_atom_to_delivery: "",
        sell_unit_id: "",
        koef_sell_to_delivery: "",
        consume_unit_id: "",
        koef_consume_to_delivery: "",
        consume_unit_id: "",
        koef_consume_to_delivery: "",
        is_atom_measure: "",
        lower_threshold: "",
        upper_threshold: "",
      },
      rowsData: "",
      currencyOptions,
      collectionOptions: [],
      doorHeightOptions: [],
      unitOptions: [],
      providerTableColumns: [
        {
          name: "provider_name",
          label: "Поставщик",
          field: "provider_name",
          align: "left",
        },
        {
          name: "provider_material_name",
          label: "Название материала у поставщика",
          field: "provider_material_name",
          align: "left",
        },
      ],
    };
  },
  methods: {
    async getData() {
      this.submitLoading = true;
      this.submitLoadingThreshold = true;
      if (!this.materialId) {
        this.submitLoading = false;
        this.submitLoadingThreshold = false;
        return;
      }
      const res = await getMaterialData(this.material_id, this.whichTab);
      this.submitLoading = false;
      this.submitLoadingThreshold = false;
      if (!res) {
        this.showErrorSnackbar();
        return;
      }

      if (this.whichTab === "zakup") {
        this.rowsData = res.list;
        this.formData = res.data;
      } else {
        this.formData = res;
      }
    },
    onClose() {
      this.submitLoading = false;
      this.submitLoadingThreshold = false;
      this.$emit("close");
    },
    async onSubmit() {
      this.submitLoading = true;
      if (this.whichTab === "zakup") {
        const cleanedFormData = {};
        for (const key in this.formData) {
          if (this.formData[key] !== "" && this.formData[key] != null) {
            cleanedFormData[key] = this.formData[key];
          }
        }
        const res = await updateZakupMaterial(cleanedFormData);
        this.submitLoading = false;
        if (!res) return;
        this.getData();
        this.$emit("close");
        this.showSucceedSnackbar();
      } else if (this.whichTab === "smetniy") {
        const payload = {
          material_id: this.materialId,
          consume_unit_id: this.formData.consume_unit_id,
          koef_consume_to_delivery: Number(
            this.formData.koef_consume_to_delivery
          ),
        };
        const res = await updateSmetniyMaterial(payload);
        this.submitLoading = false;
        if (!res) return;
        this.getData();
        this.showSucceedSnackbar();
      }
    },
    async onSubmitThreshold() {
      this.submitLoadingThreshold = true;
      const payload = {
        material_id: this.materialId,
        is_atom_measure: this.formData.is_atom_measure,
        lower_threshold: this.formData.lower_threshold,
        upper_threshold: this.formData.upper_threshold,
      };
      const res = await updateMaterialThreshold(payload);
      this.submitLoadingThreshold = false;
      if (!res) return;
      this.getData();
      this.$emit("close");
      this.showSucceedSnackbar();
    },
    onChange(field, value) {
      this.formData[field] = value;
    },
    copyQrLink() {
      if (!this.formData.qr_link) return;
      navigator.clipboard.writeText(this.formData.qr_link).then(() => {
        this.$q.notify({
          message: "QR ссылка скопирована",
          color: "secondary",
        });
      });
    },
    async loadMaterialCollections() {
      if (!this.formData.material_type_id) return;
      this.collectionOptions = await getMaterialCollections(
        this.formData.material_type_id
      );
    },
    async loadDoorHeights() {
      if (this.formData.material_type_id === 3) {
        this.doorHeightOptions = await getDoorHeights();
      }
    },
  },
  async mounted() {
    await this.getData();
    await this.loadDoorHeights();
    await this.loadMaterialCollections();
    this.unitOptions = await getMaterialUnits();
  },
  watch: {
    "formData.material_type_id": {
      handler(newVal) {
        if (newVal) {
          this.loadMaterialCollections();
          this.loadDoorHeights();
        }
      },
    },
    whichTab: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          await this.getData();
          await this.loadDoorHeights();
          await this.loadMaterialCollections();
          this.unitOptions = await getMaterialUnits();
          this.computedTrueOnSmetniy();
          this.computedFalseOnSmetniy();
        }
      }
    },
  },
  computed: {
      computedTrueOnSmetniy() {
    return this.whichTab === "smetniy";
  },
  computedFalseOnSmetniy() {
    return this.whichTab !== "smetniy";
  }
  },
  setup() {
    const { showSucceedSnackbar, showErrorSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar, showErrorSnackbar };
  },
};
</script>

<style scoped lang="scss">
.material-zakup-edit {
  padding: 15px;
}

/* Заголовок */
.page-header {
  margin-bottom: 15px;
  .pull-left {
    display: inline-block;
    vertical-align: middle;
    font-size: 32px;
  }
  .pointer {
    cursor: pointer;
  }
  .pointer:hover {
    color: red;
  }
  .margin-right-10 {
    margin-right: 10px;
  }
}

/* Оповещение */
.alert {
  margin-bottom: 15px;
}

// Внешний грид для формы (две формы при zakup + smetniy)
.form-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

// Внутренний грид — колонки внутри одной формы
.form-columns {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.col {
  max-width: 350px;
  width: 100%;
}

.col-smetniy {
  width: 316px;
}

.well {
  background: #f7f7f7;
  padding: 15px;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.input-group {
  display: block;
}

/* Кнопки сохранения */
.form-actions {
  margin-top: 20px;
  text-align: left;
  &_btn {
    margin-right: 10px;
  }
}

/* Пример стилизации blockquote */
blockquote.example-block {
  margin: 0 0 15px 0;
  padding: 10px 20px;
  border-left: 4px solid #ccc;
  background-color: #fafafa;
}

.submit-threshold {
  margin-top: 20px;
  text-align: left;
}
</style>
