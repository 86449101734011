<template>
    <form @submit.prevent="onSearch" style="margin-bottom: 20px">
      <div class="filters">
        <div class="filters__item">
          <CustomDate
              placeholder="Дата с"
              :value="this.values.date_begin || ''"
              @change_date="(value) => onChange('date_begin', value)"
            />
        </div>
        <div class="filters__item">
          <CustomDate
              placeholder="Дата по"
              :value="this.values.date_end || ''"
              @change_date="(value) => onChange('date_end', value)"
            />
        </div>

        <div class="filters__item">
          <CustomSelect
            :options="acceptanceOptions"
            label="Утвержден"
            optionValueKey="value"
            optionLabelKey="label"
            :value="values.is_accept"
            @change="(val) => onChange('is_accept', val)"
            :clearable="false"
          />
        </div>

        <q-btn
          type="submit"
          :loading="isFetching"
          size="md"
          no-wrap
          color="primary"
          class="q-btn"
          label="Поиск"
          icon="search"
        />
      </div>
    </form>
</template>

<script>
import CustomSelect from "@/components/form/CustomSelect";
import CustomDate from "@/components/form/CustomDate";
import {
  acceptanceOptions,
} from "@/views/MaterialProvPriceChanges/PriceChangesFilters/services";

export default {
  name: "SupplierMaterialsFilters",
  components: { CustomSelect, CustomDate },
  props: {
    values: { type: Object, default: () => ({}) },
    isFetching: { type: Boolean, default: false },
    getData: { type: Function, default: null },
  },
  data() {
    return {
      acceptanceOptions,
    };
  },
  methods: {
    onChange(type, value) {
      this.$emit("filter_change", type, value);
    },
    onSearch() {
      if (this.getData) {
        this.getData();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 15px;

  &__item {
    flex: 0 1 220px;
  }
}

.q-btn {
  margin-top: 10px;
  align-self: flex-start;
}
</style>
