<template>
  <div :class="[{purpose: this.data?.body?.purpose}]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomDate
          :fullWidth="true"
          :required="true"
          placeholder="Дата баланса"
          :value="this.formData.balance_date"
          @change_date="(value) => onChange('balance_date', value)"
        />
      </div>
      <div class="form__item">
        <CustomInput
          label="contractor_agreement_list_id"
          :required="true"
          type="number"
          :value="this.formData.contractor_agreement_list_id"
          @change_input="(value) => onChange('contractor_agreement_list_id', value)"
        />
      </div>
      <div class="form__item">
        <CustomInput
          label="Сумма баланса"
          :required="true"
          type="number"
          :allowNegative="true"
          :value="this.formData.balance_sum"
          @change_input="(value) => onChange('balance_sum', value)"
        />
      </div>
      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomDate from "@/components/form/CustomDate";
import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";

export default {
  name: "update-balances",
  components: {CustomDate, CustomInput, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        balance_id: this.data?.body?.balance_id || '',
        balance_date: this.data?.body?.balance_date ||'',
        contractor_agreement_list_id: this.data?.body?.contractor_agreement_list_id || '',
        balance_sum: this.data?.body?.balance_sum || '',
      }
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
      return
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
