<template>
  <div>
    <b>Свойства материала:</b>
    <div class="col-lg-4">
      <div class="form-group" style="min-width: 300px">
        <CustomSelect
          :value="this.selectedCategoryId"
          label="Категория"
          :options="categories"
          :clearable="false"
          optionLabelKey="material_category_name"
          optionValueKey="material_category_id"
          @change="(value) => onChangeCategory(value)"
        />
      </div>
      <br />
      <q-btn v-if="selectedCategoryId !== material_category_id" color="primary" size="sm" @click="updateCategory">
        <i class="fa fa-save"></i> &nbsp;Сохранить
      </q-btn>
    </div>
    <div class="col-lg-12" v-if="showTable">
      <CustomTable
        title=""
        :rowsData="properties"
        :columns="propertyColumns"
        :loading="loading"
        :custom-body="true"
        :sticky-header="true"
        :addBtn="true"
        :on-add-click="this.addProperty"
        showPagination="false"
      >
        <template #customBody="props">
          <q-tr>
            <custom-table-cell>{{ props.row.prop_name }}</custom-table-cell>
            <custom-table-cell>{{ props.row.prop_value }}</custom-table-cell>
            <custom-table-cell align="center">
              <q-btn
                flat
                dense
                round
                color="primary"
                icon="edit"
                size="sm"
                @click="editProperty(props.row)"
              />
              <br />
              <CustomIcon
                icon="trash"
                :approve-mode="true"
                :loading="this.delBtnLoading[props.row.material_prop_value_id]"
                :approve="
                  () => this.deleteProperty(props.row.material_prop_value_id)
                "
                small
              />
            </custom-table-cell>
          </q-tr>
        </template>
      </CustomTable>
    </div>
  </div>
</template>

<script>
import CustomSelect from "@/components/form/CustomSelect";
import CustomTable from "@/components/common/CustomTable";
import CustomIcon from "@/components/common/CustomIcon";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {
  getMaterialCategories,
  updateMaterialCategory,
  getMaterialProperties,
  deletePropertyValue,
  updatePropertyValue,
} from "@/views/Materials/MaterialsList/MaterialEdit/services";

import { appActions } from "@/services/store/modules/app_module/app_actions";
import { MODAL_NAMES } from "@/constants";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "PropForm",
  components: { CustomSelect, CustomTable, CustomTableCell, CustomIcon },
  props: {
    material_id: {
      type: Number,
      required: true,
    },
    material_category_id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      categories: [],
      properties: [],
      selectedCategoryId: null,
      showTable: false,
      delBtnLoading: {},
      propertyColumns: [
        {
          name: "prop_name",
          field: "prop_name",
          label: "Название",
          align: "left",
          sortable: true,
        },
        {
          name: "prop_value",
          field: "prop_value",
          label: "Значение",
          align: "left",
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    this.selectedCategoryId = this.material_category_id;
    if (this.material_category_id) {
      this.showTable = true;
    }
    this.fetchData();
  },
  watch: {
    material_category_id: {
      handler() {
        this.selectedCategoryId = this.material_category_id;
        this.showTable = true;
      },
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const [categoriesRes, propertiesRes] = await Promise.all([
          getMaterialCategories(),
          getMaterialProperties(this.material_id),
        ]);

        this.categories = categoriesRes;
        this.properties = propertiesRes;
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    onChangeCategory(value) {
      this.selectedCategoryId = value;
    },
    async updateCategory() {
      try {
        const res = await updateMaterialCategory({
          material_id: this.material_id,
          material_category_id: this.selectedCategoryId,
        });
        if (!res) {
          this.selectedCategoryId = this.material_category_id;
          return;
        }
        this.showTable = true;
        this.fetchData();
      } catch (error) {}
    },
    async addProperty() {
      this.$store.commit(
        appActions.showModal(
          MODAL_NAMES.MATERIALS_CONSTRUCTOR_UPD_PROP,
          {
            onSubmit: this.onAddSubmit,
            title: "Добавить свойство",
            body: {
              material_id: this.material_id,
            },
          }
        )
      );
    },
    async editProperty(row) {
      this.$store.commit(
        appActions.showModal(
          MODAL_NAMES.MATERIALS_CONSTRUCTOR_UPD_PROP,
          {
            onSubmit: (body) => this.onUpdateSubmit(body),
            title: "Редактировать свойство",
            body: {
              material_id: row.material_id,
              material_prop_value_id: row.material_prop_value_id,
              prop_id: row.prop_id,
              prop_dict_id: row.prop_dict_id,
            },
          }
        )
      );
    },
    async onAddSubmit(body) {
      const res = await updatePropertyValue(body);
      if (!res) return;
      this.fetchData();
      this.showSucceedSnackbar();
      this.$store.commit(appActions.closeModal());
    },
    async onUpdateSubmit(body) {
      const res = await updatePropertyValue(body);
      if (!res) return;
      this.fetchData();
      this.showSucceedSnackbar();
      this.$store.commit(appActions.closeModal());
    },
    async deleteProperty(propertyId) {
      try {
        this.delBtnLoading[propertyId] = true;
        await deletePropertyValue(propertyId);
        this.fetchData();
      } catch (error) {
        console.error("Ошибка удаления свойства", error);
      } finally {
        this.delBtnLoading[propertyId] = false;
      }
    },
  },
  setup() {
    const { showSucceedSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar };
  },
};
</script>

<style scoped lang="scss"></style>
