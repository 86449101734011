import { priceChangesAPI } from "@/views/MaterialProvPriceChanges/api";

export const getPriceChangesList = async (filters) => {
  try {
    const res = await priceChangesAPI.getList(filters);
    return res?.data;
  } catch (e) {}
};
export const priceChangeApproval = async (body) => {
  try {
    const res = await priceChangesAPI.priceChangeApproval(body);
    return res?.data;
  } catch (e) {}
};
export const onDelete = async (mp_buffer_price_id) => {
  try {    
    const res = await priceChangesAPI.onDeletePriceChange(mp_buffer_price_id);
    return res?.data;
  } catch (e) {}
};

export const PriceChangesColumns = [
  { name: "mp_buffer_price_id", label: "ID", field: "mp_buffer_price_id", sortable: true, headerStyle: "width: 80px", style: "width: 100px", align: "center" },
  { name: "material_id", label: "ID материала", field: "material_id", sortable: true, headerStyle: "width: 80px", style: "width: 100px", align: "center" },
  { name: "material_type_name", label: "Тип материала", field: "material_type_name", sortable: true, align: "center" },
  { name: "material_name", label: "Название материала", field: "material_name", sortable: true, align: "center" },
  { name: "price", label: "Новая цена (розничная)", field: "price", sortable: true, align: "center" },
  { name: "price_whole", label: "Новая цена (оптовая)", field: "price_whole", sortable: true, align: "center" },
  { name: "invoice_price", label: "Новая цена (накладная)", field: "invoice_price", sortable: true, align: "center" },
  { name: "date_price", label: "Период действия (Новый)", field: "date_price", sortable: true, align: "center" },
  { name: "provider_name", label: "Название поставщика", field: "provider_name", sortable: true, align: "center" },
  { name: "fio", label: "Инициатор", field: "fio", sortable: true, align: "center" },
  { name: "accept_fio", label: "Исполнитель", field: "accept_fio", sortable: true, align: "center" },
  { name: "status_text", label: "Статус", field: "status_text", sortable: true, align: "center" },
  { name: "price_old", label: "Старая цена (розничная)", field: "price_old", sortable: true, align: "center" },
  { name: "price_whole_old", label: "Старая цена (оптовая)", field: "price_whole_old", sortable: true, align: "center" },
  { name: "invoice_price_old", label: "Старая цена (накладная)", field: "invoice_price_old", sortable: true, align: "center" },
  { name: "date_price_old", label: "Период действия (Старый)", field: "date_price_old", sortable: true, align: "center" },
  { name: "dlt", label: "", field: "dlt", align: "center" },
];
