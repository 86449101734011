export const presetColumns = [
  { name: "preset_name", field: "preset_name", label: "Пресет", align: "left", sortable: true },
  { name: "preset_material_id", field: "preset_material_id", label: "ID материала", align: "left", sortable: true },
  { name: "is_active_preset_material", field: "is_active_preset_material", label: "Активный", align: "left", sortable: true },
  { name: "city_name", field: "city_name", label: "Город", align: "left", sortable: true },
  { name: "room_name", field: "room_name", label: "Комната", align: "left", sortable: true },
  { name: "work_set_name", field: "work_set_name", label: "Конструктив", align: "left", sortable: true },
  { name: "is_active_work_set", field: "is_active_work_set", label: "Конструктив активный", align: "left", sortable: true },
];
export const providerRequestColumns = [
  { name: "remont_id", field: "remont_id", label: "ID ремонта", align: "left", sortable: true },
  { name: "date_send", field: "date_send", label: "Дата создания заявки", align: "left", sortable: true },
];
export const warehouseStockColumns = [
  { name: "warehouse_name", field: "warehouse_name", label: "Склад", align: "left", sortable: true },
  { name: "avail_cnt", field: "avail_cnt", label: "Остаток", align: "left", sortable: true },
];
export const lastShippingColumns = [
  { name: "warehouse_name", field: "warehouse_name", label: "Склад", align: "left", sortable: true },
  { name: "date_shipping", field: "date_shipping", label: "Дата отгрузки", align: "left", sortable: true },
];
export const duplicatesColumns = [
  { name: "material_name", field: "material_name", label: "Материал", align: "left", sortable: true },
  { name: "material_type_name", field: "material_type_name", label: "Тип", align: "left", sortable: true },
  { name: "similarity_percent", field: "similarity_percent", label: "Коэфф.схожести", align: "left", sortable: true },
];
