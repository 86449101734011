<template>
  <div>
    <CustomTable
        title="Балансы"
        :rowsData="this.rowsData"
        :columns="this.balancesColumns"
        :loading="isFetching"
        :small="true"
        :show-search="true"
        :custom-body="true"
        :sticky-header="true"
        :add-btn="true"
        :on-add-click="this.handleAddClick"
        :update-data="this.getData">
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="center">
            <Clickable @click="() => this.handleUpdateClick(props.row)">
              {{ props.row.balance_id }}
            </Clickable>
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.balance_date }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.contractor_agreement_list_id }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.balance_sum }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.fio }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.rowversion }}
          </custom-table-cell>
          <custom-table-cell align="center" vertical-align="center">
            <CustomIcon icon="trash" :approve-mode="true"
                        :approve="() => this.onDeleteSubmit(props.row.balance_id)"
                        :loading="this.deleteLoading[props.row.balance_id]"/>
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import CustomIcon from "@/components/common/CustomIcon";
import {MODAL_NAMES} from "@/constants";
import Clickable from "@/components/common/Clickable";
import CheckOrClose from "@/components/common/CheckOrClose";
import {
  balancesColumns,
  getAllBalances,
  updateBalance,
  deleteBalance,
} from "@/views/Balances/services";

export default {
  name: "Balances",
  components: {CheckOrClose, Clickable, CustomIcon, CustomTableCell, CustomTable},
  data() {
    return {
      balancesColumns,
      rowsData: [],
      isFetching: false,
      deleteLoading: {},
      loading: false,
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getAllBalances()
      this.isFetching = false
      if (!res) return
      this.rowsData = res
    },
    async handleAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.UPDATE_BALANCES, {
        onSubmit: this.onAddSubmit,
        title: 'Добавить баланс',
      }));
    },
    async handleUpdateClick(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.UPDATE_BALANCES, {
        onSubmit: (body) => this.onUpdateSubmit(body),
        title: 'Изменить баланс',
        body: row
      }));
    },
    async onAddSubmit(body) {
      const res = await updateBalance(body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onUpdateSubmit(body) {
      const res = await updateBalance(body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onDeleteSubmit(balance_id) {
      this.deleteLoading[balance_id] = true
      const res = await deleteBalance(balance_id)
      this.deleteLoading[balance_id] = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
    },
  },
  mounted() {
    this.getData()
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>